import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { historyCategoryAction } from './historyCategorySlice';

// import * as photoApiLib from '../lib/photoApi';
// import * as photoGroupApiLib from '../lib/photoGroupApi';
import * as historyCategoryApi from '../lib/historyCategoryApi';

function* list({ payload }) {
  try {
    const categoryList = yield call(historyCategoryApi.list, payload);

    yield put(historyCategoryAction.listSuccess({ categoryList }));
  } catch (error) {
    yield put(historyCategoryAction.listFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    const categoryId = payload.categoryId;
    const placeId = payload.placeId;
    const formValue = payload.formValue;

    yield call(historyCategoryApi.add, { ...formValue, categoryId, placeId });

    const categoryList = yield call(historyCategoryApi.list, payload);

    yield put(historyCategoryAction.addSuccess({ categoryList }));
  } catch (error) {
    yield put(historyCategoryAction.addFailure({ error }));
  }
}

function* update({ payload }) {
  try {
    const categoryId = payload.categoryId;
    const categoryNm = payload.categoryNm;
    // const placeId = payload.placeId;

    yield call(historyCategoryApi.update, { categoryId, categoryNm });
    const categoryList = yield call(historyCategoryApi.list, payload);

    yield put(historyCategoryAction.updateSuccess({ categoryList }));
  } catch (error) {
    yield put(historyCategoryAction.updateFailure({ error }));
  }
}
function* remove({ payload }) {
  try {
    const placeId = payload.placeId;
    const removeCategoryList = payload.removeCategoryList;

    let categoryIdList = [];
    for (const category of removeCategoryList) {
      // yield call(photoApiLib.unLinkPhotoGroup, { groupId: payload.groupId, relationPhotoIdList: payload.relationPhotoIdList });
      categoryIdList.push(category.categoryId);
    }

    yield call(historyCategoryApi.remove, { categoryIdList });

    const categoryList = yield call(historyCategoryApi.list, { placeId });

    yield put(historyCategoryAction.removeSuccess({ categoryList }));
  } catch (error) {
    yield put(historyCategoryAction.removeFailure({ error }));
  }
}

export function* watchList() {
  yield takeLatest(historyCategoryAction.list, list);
}

export function* watchAdd() {
  yield takeLatest(historyCategoryAction.add, add);
}

export function* watchUpdate() {
  yield takeLatest(historyCategoryAction.update, update);
}

export function* watchRemove() {
  yield takeLatest(historyCategoryAction.remove, remove);
}

function* rootSaga() {
  yield all([fork(watchList), fork(watchAdd), fork(watchUpdate), fork(watchRemove)]);
}

export default rootSaga;
