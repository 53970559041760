import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { placeAction } from './placeSlice';

import * as placeApiLib from '../lib/placeApi';

function* list({ payload }) {
  try {
    const placeList = yield call(placeApiLib.list, payload);

    yield put(placeAction.listSuccess({ placeList }));
  } catch (err) {
    yield put(placeAction.listFailure('List Error'));
  }
}

function* aliasList({ payload }) {
  try {
    const placeAliasList = yield call(placeApiLib.aliasList, payload);

    yield put(placeAction.aliasListSuccess({ placeAliasList }));
  } catch (err) {
    yield put(placeAction.aliasListFailure('List Error'));
  }
}

function* detail({ payload }) {
  try {
    const placeInfo = yield call(placeApiLib.detail, payload);

    yield put(placeAction.detailSuccess({ placeInfo }));
  } catch (err) {
    yield put(placeAction.detailFailure('Detail Error'));
  }
}

function* add({ payload }) {
  try {
    yield call(placeApiLib.add, payload);

    yield put(placeAction.addSuccess());
  } catch (err) {
    yield put(placeAction.addFailure('Add Error'));
  }
}

function* edit({ payload }) {
  try {
    yield call(placeApiLib.edit, payload);

    yield put(placeAction.editSuccess(payload));
  } catch (err) {
    yield put(placeAction.editFailure('Edit Error'));
  }
}

function* remove({ payload }) {
  try {
    yield call(placeApiLib.remove, payload);

    yield put(placeAction.removeSuccess());
  } catch (err) {
    yield put(placeAction.removeFailure('Remove Error'));
  }
}

function* imageUpload({ payload }) {
  try {
    const placeInfo = yield call(placeApiLib.upload, payload);

    yield put(placeAction.imageUploadSuccess({ placeInfo }));
  } catch (err) {
    yield put(placeAction.imageUploadFailure('Image Upload Error'));
  }
}

function* agentInfo() {
  try {
    const agentInfo = yield call(placeApiLib.agent);

    yield put(placeAction.agentInfoSuccess({ agentInfo }));
  } catch (err) {
    yield put(placeAction.agentInfoFailure('Agent Info Error'));
  }
}

export function* watchList() {
  yield takeLatest(placeAction.list, list);
}

export function* watchAliasList() {
  yield takeLatest(placeAction.aliasList, aliasList);
}

export function* watchInfo() {
  yield takeLatest(placeAction.detail, detail);
}

export function* watchAdd() {
  yield takeLatest(placeAction.add, add);
}

export function* watchEdit() {
  yield takeLatest(placeAction.edit, edit);
}

export function* watchRemove() {
  yield takeLatest(placeAction.remove, remove);
}

export function* watchImageUpload() {
  yield takeLatest(placeAction.imageUpload, imageUpload);
}

export function* watchAgentInfo() {
  yield takeLatest(placeAction.agentInfo, agentInfo);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchInfo),
    fork(watchAliasList),
    fork(watchAdd),
    fork(watchEdit),
    fork(watchRemove),
    fork(watchImageUpload),
    fork(watchAgentInfo),
  ]);
}

export default rootSaga;
