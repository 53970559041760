import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface SpacePayload {
  spaceId: string;
  spaceNm: string;
  accountId: string;
  licenseSeat: string;
}

interface spaceInfo {
  spaceId: string;
  spaceNm: string;
}

interface spaceState {
  spaceList: Array<spaceInfo>;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const spaceInitialState: spaceState = {
  spaceList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  list: (state: spaceState, { payload }: PayloadAction<SpacePayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  listSuccess: (
    state: spaceState,
    { payload }: PayloadAction<spaceState>
  ) => {
    state.spaceList = payload.spaceList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  listFailure: (state: spaceState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'space',
  initialState: spaceInitialState,
  reducers: reducers,
});

const selectspaceList = createDraftSafeSelector(
  (state: spaceState) => state.spaceList,
  (spaceList) => spaceList
);

const selectStatus = createDraftSafeSelector(
  (state: spaceState) => state.actionResult,
  (state: spaceState) => state.isLoading,
  (state: spaceState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error })
);

export const spaceSelector = {
  spaceList: (state) => selectspaceList(state[SPACE]),
  status: (state) => selectStatus(state[SPACE]),
};

export const SPACE = slice.name;
export const spaceReducer = slice.reducer;
export const spaceAction = slice.actions;
