import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { placeAuthAction } from './placeAuthSlice';

import * as placeAuthApiLib from '../lib/placeAuthApi';

function* list({ payload }) {
  try {
    const placeAuthList = yield call(placeAuthApiLib.list, payload);

    yield put(placeAuthAction.listSuccess({ placeAuthList }));
  } catch (error) {
    yield put(placeAuthAction.listFailure({ error }));
  }
}

function* detail({ payload }) {
  try {
    const placeAuthInfo = yield call(placeAuthApiLib.detail, payload);

    yield put(placeAuthAction.detailSuccess({ placeAuthInfo }));
  } catch (error) {
    yield put(placeAuthAction.detailFailure({ error }));
  }
}

export function* watchList() {
  yield takeLatest(placeAuthAction.list, list);
}

export function* watchDetail() {
  yield takeLatest(placeAuthAction.detail, detail);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchDetail),
  ]);
}

export default rootSaga;
