import * as apiLib from './api';

export const list = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/layer/list', data);

    if (result.resultFlag) {
      return result.layerList;
    } else {
      throw Object.assign(new Error('getLayerList error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = {
      ...payload
    };

    const result = await apiLib.fetchArchiveApi('/layer/add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('addLayerList error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const update = async ({ layerId, layerInfo }) => {
  try {
    const data = {
      layerId,
      layerInfo,
    };

    const result = await apiLib.fetchArchiveApi('/layer/update', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getUpdateLayer error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
