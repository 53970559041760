import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { photoGroupAction } from './photoGroupSlice';

import * as photoApiLib from '../lib/photoApi';
import * as photoGroupApiLib from '../lib/photoGroupApi';

function* list({ payload }) {
  try {
    const photoGroupList = yield call(photoGroupApiLib.list, payload);

    yield put(photoGroupAction.listSuccess({ photoGroupList }));
  } catch (error) {
    yield put(photoGroupAction.listFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    const groupId = payload.groupId;
    const placeId = payload.placeId;
    const formValue = payload.formValue;

    yield call(photoGroupApiLib.add, { ...formValue, groupId, placeId });

    const photoGroupList = yield call(photoGroupApiLib.list, payload);

    yield put(photoGroupAction.addSuccess({ photoGroupList }));
  } catch (error) {
    yield put(photoGroupAction.addFailure({ error }));
  }
}

function* update({ payload }) {
  try {
    const groupId = payload.groupId;
    const groupNm = payload.groupNm;
    // const placeId = payload.placeId;

    yield call(photoGroupApiLib.update, { groupId, groupNm });
    const photoGroupList = yield call(photoGroupApiLib.list, payload);

    yield put(photoGroupAction.updateSuccess({ photoGroupList }));
  } catch (error) {
    yield put(photoGroupAction.updateFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    yield call(photoApiLib.unLinkPhotoGroup, { groupId: payload.groupId, relationPhotoIdList: payload.relationPhotoIdList });

    yield call(photoGroupApiLib.remove, { groupId: payload.groupId });

    const photoGroupList = yield call(photoGroupApiLib.list, { placeId: payload.placeId });

    yield put(photoGroupAction.removeSuccess({ photoGroupList }));
  } catch (error) {
    yield put(photoGroupAction.removeFailure({ error }));
  }
}

export function* watchList() {
  yield takeLatest(photoGroupAction.list, list);
}

export function* watchAdd() {
  yield takeLatest(photoGroupAction.add, add);
}

export function* watchUpdate() {
  yield takeLatest(photoGroupAction.update, update);
}

export function* watchRemove() {
  yield takeLatest(photoGroupAction.remove, remove);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchAdd),
    // fork(watchDetail),
    fork(watchUpdate),
    fork(watchRemove),
  ]);
}

export default rootSaga;
