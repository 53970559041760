import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IframeInfo {
  frameId: string;
}

interface frameState {
  frameList: IframeInfo[];
  frameInfo: IframeInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: frameState = {
  frameList: [],
  frameInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: frameState, { payload }: PayloadAction<frameState>) => {
    state.frameList = [];
    state.frameInfo = {};
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },

  listSuccess: (state: frameState, { payload }: PayloadAction<frameState>) => {
    state.frameList = payload.frameList;
    state.isLoading = false;
    state.actionResult = 'FRAME_LIST_OK';
    state.error = '';
  },
  changeDuration(state: FrameState, { payload: { index, key, value } }: PayloadAction<DurationPayload>) {
    state.frameList[index].frameDuration[key] = value;
  },

  changeAllDuration(state: FrameState, { payload: { index, frameDuration } }: PayloadAction<DurationPayload>) {
    state.frameList[index].frameDuration.hour = frameDuration.hour;
    state.frameList[index].frameDuration.minute = frameDuration.minute;
    state.frameList[index].frameDuration.second = frameDuration.second;
  },
  /*
  list: (state: frameState, { payload }: PayloadAction<frameState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },

  listFailure: (state: frameState, { payload }: PayloadAction<frameState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  detail: (state: frameState, { payload }: PayloadAction<frameState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  detailSuccess: (state: frameState, { payload }: PayloadAction<frameState>) => {
    state.frameInfo = payload.frameInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  detailFailure: (state: frameState, { payload }: PayloadAction<frameState>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = payload.error;
  },
  add: (state: frameState, { payload }: PayloadAction<CsutomerframeState>) => {
    state.isLoading = true;
    state.actionResult = 'ADD_REQ';
    state.error = '';
  },
  addSuccess: (state: frameState, { payload }: PayloadAction<frameState>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_OK';
    state.error = '';
  },
  addFailure: (state: frameState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_ERR';
    state.error = action.payload;
  },
  edit: (state: frameState, { payload }: PayloadAction<CsutomerframeState>) => {
    state.isLoading = true;
    state.actionResult = 'EDIT_REQ';
    state.error = '';
  },
  editSuccess: (state: frameState, { payload }: PayloadAction<frameState>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_OK';
    state.error = '';
  },
  editFailure: (state: frameState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_ERR';
    state.error = action.payload;
  },
  remove: (state: frameState, { payload }: PayloadAction<CsutomerframeState>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: frameState, { payload }: PayloadAction<frameState>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: frameState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = action.payload;
  },
  */

  updateFrameNm(state: FrameState, { payload: { frameId, frameNm } }: PayloadAction<FrameState>) {
    const idx = state.frameList.findIndex(frame => frame.frameId === frameId);
    state.frameList[idx].frameNm = frameNm;
  },
  actionResultClear: (state: frameState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'frame',
  initialState: InitialState,
  reducers: reducers,
});

const selectframeList = createDraftSafeSelector(
  (state: frameState) => state.frameList,
  frameList => frameList,
);

const selectframeInfo = createDraftSafeSelector(
  (state: frameState) => state.frameInfo,
  frameInfo => frameInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: frameState) => state.isLogin,
  (state: frameState) => state.actionResult,
  (state: frameState) => state.isLoading,
  (state: frameState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const frameSelector = {
  frameList: state => selectframeList(state[FRAME]),
  frameInfo: state => selectframeInfo(state[FRAME]),
  status: state => selectStatus(state[FRAME]),
};

export const FRAME = slice.name;
export const frameReducer = slice.reducer;
export const frameAction = slice.actions;
