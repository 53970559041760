import * as apiLib from './api';

export const list = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/user/list', data);
    if (result.resultFlag) {
      return result.userList;
    } else {
      throw Object.assign(new Error('user list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

// export const list = async payload => {
//   try {
//     let userList = [];

//     let searchFlag = true;

//     let data = {
//       placeId: payload.placeId,
//       limit: 100,
//       LastEvaluatedKey: null,
//     };

//     while (searchFlag) {
//       const result = await apiLib.fetchArchiveApi('/user/limit-list', data);
//       userList = userList.concat(result.userList);
//       if (result.LastEvaluatedKey === undefined) {
//         searchFlag = false;
//       }
//       data.LastEvaluatedKey = result.LastEvaluatedKey;
//     }

//     const sortUserList = userList.sort((lhs, rhs) => lhs.regDt - rhs.regDt);

//     return sortUserList;
//   } catch (err) {
//     throw err;
//   }
// };

export const detail = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/user/detail', data);
    if (result.resultFlag) {
      return result.userInfo;
    } else {
      throw Object.assign(new Error('user detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/user/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('user add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/user/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('user edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const pwChange = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/user/password-change', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('password change error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/user/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('user remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
