import { all, fork, put, call, takeLatest } from 'redux-saga/effects';

import { galleryAction } from './gallerySlice';

import * as galleryApiLib from '../lib/galleryApi';

function* list({ payload }) {
  try {
    const galleryList = yield call(galleryApiLib.list, payload);

    yield put(galleryAction.listSuccess({ galleryList }));
  } catch (error) {
    yield put(galleryAction.listFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    let callList = payload.removeList.map(jampicId => {
      const callApi = call(galleryApiLib.remove, { jampicId });
      return callApi;
    });

    yield all([...callList]);
    yield put(galleryAction.removeSuccess());
  } catch (err) {}
}

export function* watchList() {
  yield takeLatest(galleryAction.list, list);
}

export function* watchRemove() {
  yield takeLatest(galleryAction.remove, remove);
}

function* rootSaga() {
  yield all([fork(watchList), fork(watchRemove)]);
}

export default rootSaga;
