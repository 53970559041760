import * as apiLib from './api';

export const list = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/frame/list', data);
    if (result.resultFlag) {
      return result.frameList;
    } else {
      if (result.isNotPlaylist) {
        throw Object.assign(new Error('no playlist.'), { status: 401 });
      } else {
        throw Object.assign(new Error('getFrameList error'), { status: 401 });
      }
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = {
      ...payload
    };

    const result = await apiLib.fetchArchiveApi('/frame/add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('addFrame error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};


export const remove = async playlistId => {
  try {
    const data = {
      playlistId,
    };

    const result = await apiLib.fetchArchiveApi('/frame/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('removeFrame error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};


export const updateList = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/frame/updateList', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('frame Update Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};


