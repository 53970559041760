import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import HashLoader from 'react-spinners/HashLoader';
import { useSelector } from 'react-redux';
import { authSelector } from '../../modules/authSlice';

const Token = React.lazy(() => import('../auth/Token'));
const Header = React.lazy(() => import('../common/Header'));
const Footer = React.lazy(() => import('../common/Footer'));

const loading = () => <div></div>;

const contentLoading = () => (
  <HashLoader
    css={{
      display: 'block',
      width: '100%',
      height: '100%',
      margin: '0 auto',
    }}
    color={'#41a1ea'}
    loading={true}
    size={100}
  />
);

const Horizontal = () => {
  const status = useSelector(authSelector.status);

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  if (!status.isLogin) {
    return (
      <Suspense fallback={loading()}>
        <Token />
      </Suspense>
    );
  }

  return (
    <Container isMobile={isMobile}>
      <Content isMobile={isMobile}>
        <Suspense fallback={loading()}>
          <Header isMobile={isMobile} />
        </Suspense>
        <Main>
          <Suspense fallback={contentLoading()}>
            <Outlet />
          </Suspense>
        </Main>
        <Suspense fallback={loading()}>
          <Footer />
        </Suspense>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
`;

const Main = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  padding-top: ${props => (props.isMobile ? '4rem' : '0')};
  display: flex;
  flex-direction: column;
`;

export default Horizontal;
