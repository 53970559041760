import * as apiLib from './api';

export const list = async payload => {
  try {
    let playlistLogList = [];

    let searchFlag = true;

    let data = {
      limit: 500,
      LastEvaluatedKey: null,
      placeId: payload.placeId,
      searchStartDt: payload.searchStartDt,
      searchEndDt: payload.searchEndDt,
      logType: payload.logType,
    };

    while (searchFlag) {
      const result = await apiLib.fetchArchiveApi('/playlistLog/limit-list', data);
      playlistLogList = playlistLogList.concat(result.playlistLogList);
      if (result.LastEvaluatedKey === undefined) {
        searchFlag = false;
      }
      data.LastEvaluatedKey = result.LastEvaluatedKey;
    }

    /*
    const sortPlaylistLogList = playlistLogList.sort(function (lhs, rhs) {
      if (lhs.regDt && rhs.regDt) {
        return lhs.regDt - rhs.regDt;
      }
      return 0;
    });

    return sortPlaylistLogList;
    */
    return playlistLogList;
  } catch (err) {
    throw err;
  }
};
