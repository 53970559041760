import * as apiLib from './api';

export const list = async payload => {
  try {
    let history = [];

    let searchFlag = true;

    let data = {
      placeId: payload.placeId,
      limit: 100,
      LastEvaluatedKey: null,
    };

    while (searchFlag) {
      const result = await apiLib.fetchArchiveApi('/history/limit-list', data);
      history = history.concat(result.historyList);
      if (result.LastEvaluatedKey === undefined) {
        searchFlag = false;
      }
      data.LastEvaluatedKey = result.LastEvaluatedKey;
    }

    const sortHistoryList = history.sort(function (lhs, rhs) {
      return new Date(rhs.historyDt) - new Date(lhs.historyDt);
    });

    return sortHistoryList;
  } catch (err) {
    throw err;
  }
};


export const detail = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/history/detail', data);
    if (result.resultFlag) {
      return result.historyInfo;
    } else {
      throw Object.assign(new Error('history detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/history/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('history add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/history/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('history edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/history/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('history remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
