import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { historyAction } from './historySlice';

import * as historyApiLib from '../lib/historyApi';
import * as storageApiLib from '../lib/storageApi';
import * as historyCategoryApi from '../lib/historyCategoryApi';

function* list({ payload }) {
  try {
    const historyList = yield call(historyApiLib.list, payload);

    yield put(historyAction.listSuccess({ historyList }));
  } catch (error) {
    yield put(historyAction.listFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    const fileList = payload.fileList;
    const placeId = payload.placeId;
    const handleUploadProgress = payload.handleUploadProgress;
    const historyId = payload.historyId;
    const categoryId = payload.categoryId;

    const resultFileList = yield call(storageApiLib.upload, { fileList, placeId, handleUploadProgress });
    yield call(historyCategoryApi.linkCategory, { relationIdList: [historyId], categoryId: categoryId });
    yield call(historyApiLib.add, { ...payload, fileList: resultFileList });

    const historyList = yield call(historyApiLib.list, { placeId: payload.placeId });

    yield put(historyAction.addSuccess({ historyList }));
  } catch (error) {
    yield put(historyAction.addFailure({ error }));
  }
}

function* detail({ payload }) {
  try {
    const historyInfo = yield call(historyApiLib.detail, payload);

    yield put(historyAction.detailSuccess({ historyInfo }));
  } catch (error) {
    yield put(historyAction.detailFailure({ error }));
  }
}

function* edit({ payload }) {
  try {
    const handleUploadProgress = payload.handleUploadProgress;
    const addFileList = payload.addFileList || [];
    const removeFileList = payload.removeFileList || [];
    const fileList = payload.fileList || [];
    const placeId = payload.placeId;
    const historyId = payload.historyId;
    const linkId = payload.linkId;
    const unLinkId = payload.unLinkId;

    const resultFileList = yield call(storageApiLib.uploadAndRemove, {
      addFileList,
      removeFileList,
      fileList,
      placeId,
      handleUploadProgress,
    });

    if (linkId !== unLinkId) {
      yield call(historyCategoryApi.linkCategory, { relationIdList: [historyId], categoryId: linkId });
      yield call(historyCategoryApi.unLinkCategory, { relationIdList: [historyId], categoryId: unLinkId });
    }

    const callList = payload.updateList.map(update => {
      const updateInfo = { ...update.updateInfo, fileList: resultFileList };
      const callApi = call(historyApiLib.edit, { historyId: update.historyId, updateInfo: updateInfo });
      return callApi;
    });

    yield all([...callList]);

    const historyList = yield call(historyApiLib.list, { placeId });

    yield put(historyAction.editSuccess({ historyList }));
  } catch (error) {
    yield put(historyAction.editFailure({ error }));
  }
}

function* reOrder({ payload }) {
  try {
    const callList = payload.updateList.map(update => {
      const callApi = call(historyApiLib.edit, { ...update });
      return callApi;
    });

    yield all([...callList]);

    const historyList = yield call(historyApiLib.list, { placeId: payload.placeId });

    yield put(historyAction.reOrderSuccess({ historyList }));
  } catch (error) {
    yield put(historyAction.reOrderFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    const removeHistoryList = payload.removeHistoryList;
    let historyIdList = [];
    let unLinkCategoryInfo = {};
    let removeCallList = [];

    for (const history of removeHistoryList) {
      const historyId = history.historyId;
      const categoryId = history.categoryId;

      historyIdList.push(historyId);
      if (categoryId) {
        if (unLinkCategoryInfo[categoryId]) {
          unLinkCategoryInfo[categoryId].push(historyId);
        } else {
          unLinkCategoryInfo[categoryId] = [historyId];
        }
      }
    }

    for (const categoryId in unLinkCategoryInfo) {
      removeCallList.push(
        call(historyCategoryApi.unLinkCategory, { relationIdList: unLinkCategoryInfo[categoryId], categoryId: categoryId }),
      );
    }

    yield all([...removeCallList]);
    yield call(storageApiLib.remove, { filePathList: payload.removeFilePathList });
    yield call(historyApiLib.remove, { historyIdList });

    const historyList = yield call(historyApiLib.list, { placeId: payload.placeId });

    yield put(historyAction.removeSuccess({ historyList }));
  } catch (error) {
    yield put(historyAction.removeFailure({ error }));
  }
}

export function* watchList() {
  yield takeLatest(historyAction.list, list);
}

export function* watchAdd() {
  yield takeLatest(historyAction.add, add);
}

export function* watchDetail() {
  yield takeLatest(historyAction.detail, detail);
}

export function* watchEdit() {
  yield takeLatest(historyAction.edit, edit);
}

export function* watchReOrder() {
  yield takeLatest(historyAction.reOrder, reOrder);
}

export function* watchRemove() {
  yield takeLatest(historyAction.remove, remove);
}

function* rootSaga() {
  yield all([fork(watchList), fork(watchAdd), fork(watchDetail), fork(watchEdit), fork(watchReOrder), fork(watchRemove)]);
}

export default rootSaga;
