import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IlayerInfo {
  layerId: string;
}

interface layerState {
  layerList: IlayerInfo[];
  layerInfo: IlayerInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: layerState = {
  layerList: [],
  layerInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: layerState, { payload }: PayloadAction<layerState>) => {
    state.layerList = [];
    state.layerInfo = {};
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },

  listSuccess: (state: layerState, { payload }: PayloadAction<layerState>) => {
    state.layerList = payload.layerList;
    state.isLoading = false;
    state.actionResult = 'LAYER_LIST_OK';
    state.error = '';
  },


  /*
  list: (state: layerState, { payload }: PayloadAction<layerState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },

  listFailure: (state: layerState, { payload }: PayloadAction<layerState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  detail: (state: layerState, { payload }: PayloadAction<layerState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  detailSuccess: (state: layerState, { payload }: PayloadAction<layerState>) => {
    state.layerInfo = payload.layerInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  detailFailure: (state: layerState, { payload }: PayloadAction<layerState>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = payload.error;
  },
  add: (state: layerState, { payload }: PayloadAction<CsutomerlayerState>) => {
    state.isLoading = true;
    state.actionResult = 'ADD_REQ';
    state.error = '';
  },
  addSuccess: (state: layerState, { payload }: PayloadAction<layerState>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_OK';
    state.error = '';
  },
  addFailure: (state: layerState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_ERR';
    state.error = action.payload;
  },
  edit: (state: layerState, { payload }: PayloadAction<CsutomerlayerState>) => {
    state.isLoading = true;
    state.actionResult = 'EDIT_REQ';
    state.error = '';
  },
  editSuccess: (state: layerState, { payload }: PayloadAction<layerState>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_OK';
    state.error = '';
  },
  editFailure: (state: layerState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_ERR';
    state.error = action.payload;
  },
  remove: (state: layerState, { payload }: PayloadAction<CsutomerlayerState>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: layerState, { payload }: PayloadAction<layerState>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: layerState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = action.payload;
  },
  */
  actionResultClear: (state: layerState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'layer',
  initialState: InitialState,
  reducers: reducers,
});

const selectlayerList = createDraftSafeSelector(
  (state: layerState) => state.layerList,
  (layerList) => layerList
);

const selectlayerInfo = createDraftSafeSelector(
  (state: layerState) => state.layerInfo,
  layerInfo => layerInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: layerState) => state.isLogin,
  (state: layerState) => state.actionResult,
  (state: layerState) => state.isLoading,
  (state: layerState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const layerSelector = {
  layerList: state => selectlayerList(state[LAYER]),
  layerInfo: state => selectlayerInfo(state[LAYER]),
  status: state => selectStatus(state[LAYER]),
};

export const LAYER = slice.name;
export const layerReducer = slice.reducer;
export const layerAction = slice.actions;
