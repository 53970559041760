import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IPhotoInfo {
  photoId: string;
  placeId?: string;
  groupIdList?: any[];
  useYn?: string;
  photoNm?: string;
  photoEngNm?: string;
  authorNm?: string;
  authorEngNm?: string;
  photoDetail?: string;
  photoEngDetail?: string;
  photoFileList?: any[];
  photoFileDetailList?: any[];
  regDt?: string;
  regId?: string;
  modDt?: string;
  modId?: string;
  delYn?: string;
  delId?: string;
}

interface PhotoState {
  photoList: IPhotoInfo[];
  photoInfo: IPhotoInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: PhotoState = {
  photoList: [],
  photoInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: PhotoState, { payload }: PayloadAction<PhotoState>) => {
    state.photoList = [];
    state.photoInfo = {};
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },
  list: (state: PhotoState, { payload }: PayloadAction<PhotoState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: PhotoState, { payload }: PayloadAction<PhotoState>) => {
    state.photoList = payload.photoList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: PhotoState, { payload }: PayloadAction<PhotoState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  detail: (state: PhotoState, { payload }: PayloadAction<PhotoState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  detailSuccess: (state: PhotoState, { payload }: PayloadAction<PhotoState>) => {
    state.photoInfo = payload.photoInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  detailFailure: (state: PhotoState, { payload }: PayloadAction<PhotoState>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = payload.error;
  },
  add: (state: PhotoState, { payload }: PayloadAction<{}>) => {
    state.isLoading = true;
    state.actionResult = 'ADD_REQ';
    state.error = '';
  },
  addSuccess: (state: PhotoState, { payload }: PayloadAction<PhotoState>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_OK';
    state.error = '';
  },
  addFailure: (state: PhotoState, { payload }: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_ERR';
    state.error = payload;
  },
  update: (state: PhotoGroupState, { payload }: PayloadAction<CsutomerPhotoGroupState>) => {
    state.isLoading = true;
    state.actionResult = 'UPDATE_REQ';
    state.error = '';
  },
  updateSuccess: (state: PhotoGroupState, { payload }: PayloadAction<PhotoGroupState>) => {
    state.isLoading = false;
    state.actionResult = 'UPDATE_OK';
    state.error = '';
  },
  updateFailure: (state: PhotoGroupState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'UPDATE_ERR';
    state.error = action.payload;
  },
  remove: (state: PhotoState, { payload }: PayloadAction<CsutomerPhotoState>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: PhotoState, { payload }: PayloadAction<PhotoState>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: PhotoState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = action.payload;
  },
  actionResultClear: (state: PhotoState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'photo',
  initialState: InitialState,
  reducers: reducers,
});

const selectphotoList = createDraftSafeSelector(
  (state: PhotoState) => state.photoList,
  photoList => photoList,
);

const selectphotoInfo = createDraftSafeSelector(
  (state: PhotoState) => state.photoInfo,
  photoInfo => photoInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: PhotoState) => state.actionResult,
  (state: PhotoState) => state.isLoading,
  (state: PhotoState) => state.error,
  (actionResult, isLoading, error) => ({
    actionResult,
    isLoading,
    error,
  }),
);

export const photoSelector = {
  photoList: state => selectphotoList(state[PHOTO]),
  photoInfo: state => selectphotoInfo(state[PHOTO]),
  status: state => selectStatus(state[PHOTO]),
};

export const PHOTO = slice.name;
export const photoReducer = slice.reducer;
export const photoAction = slice.actions;
