import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface CalendarAppDataState {
  calendarName: string;
  dateCheck: string;
  iCalendarUrlYn: string;
  iCalendarUrl: string;
}

interface WeatherAppDataState {
  location: string;
  degreeCheck: string;
  timeForecastYn: string;
  dayForecastYn: string;
}

interface TextAppDataState {
  fontSize: string;
  text: string;
  frameLinkYn: string;
}

interface FrameNoAppDataState {
  frameNoYn: string;
}

interface MediaDataState {
  file: Array<any>;
  animation: string;
  zoom: string;
  fitOption: string;
}

interface LayerContentsState {
  playlistId: string;
  frameId: string;
  layerId: string;
  contentsId: string;
  contentsType: string;
  contentsData: CalendarAppDataState | WeatherAppDataState | TextAppDataState | FrameNoAppDataState | MediaDataState;
  contentsFileList: Array<any>;
  editingMode: boolean;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const initialState: LayerContentsState = {
  layerContentsList: [
    // {
    //   contentsData: {},
    //   contentsFileList: [],
    //   contentsId: '1639465114bab9e4fb',
    //   contentsType: 'media',
    //   frameId: '1639465113772f0a46',
    //   layerId: 'media-test-layer',
    //   playlistId: '16394651132ec30eba',
    // },
    // {
    //   contentsData: {},
    //   contentsFileList: [],
    //   contentsId: '1639469sdbab9e4fb',
    //   contentsType: 'weather',
    //   frameId: '1639465113772f0a46',
    //   layerId: 'weather-test-layer',
    //   playlistId: '16394651132ec30eba',
    // },
    // {
    //   contentsData: {},
    //   contentsFileList: [],
    //   contentsId: '1639469sdbab9e4fb',
    //   contentsType: 'archive-wall',
    //   frameId: '1639465113772f0a46',
    //   layerId: 'archive-test-layer',
    //   playlistId: '16394651132ec30eba',
    // },
  ],
  actionResult: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'layerContents',
  initialState,
  reducers: {
    stateClear: (state: layerContentsState, { payload }: PayloadAction<layerContentsState>) => {
      state.layerContentsList = [];
      state.layerContentsInfo = {};
      state.actionResult = '';
      state.isLoading = false;
      state.error = '';
    },
    listSuccess: (state: layerContentsState, { payload }: PayloadAction<layerContentsState>) => {
      state.layerContentsList = payload.layerContentsList;
      state.isLoading = false;
      state.actionResult = 'LAYER_CONTENTS_LIST_OK';
      state.error = '';
    },
    actionResultClear: (state: layerContentsState) => {
      state.actionResult = '';
    },
    initLayerContentsState(state: LayerContentsState) {
      state.layerContentsList = [];
      state.actionResult = '';
      state.isLoading = false;
      state.error = null;
    },
    setLayerContentsList(state: LayerState, { payload: { layerContentsList } }: PayloadAction<LayerState>) {
      state.layerContentsList = layerContentsList;
    },
    saveLayerContents(state: LayerState, { payload: { newLayerContents, layerId } }: PayloadAction<LayerState>) {
      const idx = state.layerContentsList.findIndex(contents => contents.layerId === layerId);
      state.layerContentsList[idx].contentsData = newLayerContents;
    },
    updateLayerContents(state: LayerState, { payload: { key, index, updateKey, updateValue, layerId } }: PayloadAction<LayerState>) {
      const idx = state.layerContentsList.findIndex(contents => contents.layerId === layerId);
      state.layerContentsList[idx].contentsData[key][index][updateKey] = updateValue;
    },
    updateLayerContentsObj(state: LayerState, { payload: { key, value, layerId } }: PayloadAction<LayerState>) {
      const idx = state.layerContentsList.findIndex(contents => contents.layerId === layerId);
      state.layerContentsList[idx].contentsData[key] = value;
    },

    addContentsFileList(state: LayerState, { payload: { contentsFileList, mediaList, layerId } }: PayloadAction<LayerState>) {
      const idx = state.layerContentsList.findIndex(contents => contents.layerId === layerId);
      state.layerContentsList[idx].contentsFileList.push(...contentsFileList);
      state.layerContentsList[idx].contentsData.mediaList.push(...mediaList);
    },
    updateContentsFileList(state: LayerState, { payload: { contentsFileList, layerId } }: PayloadAction<LayerState>) {
      const idx = state.layerContentsList.findIndex(contents => contents.layerId === layerId);
      state.layerContentsList[idx].contentsFileList = contentsFileList;

      const mediaList = [...state.layerContentsList[idx].contentsData.mediaList];
      const newMediaList = [...state.layerContentsList[idx].contentsData.mediaList];
      for (let index = 0; index < contentsFileList.length; index++) {
        const contentsFile = contentsFileList[index];
        const mediaIndex = mediaList.findIndex(media => media.fileId === contentsFile.fileId);
        newMediaList[index] = mediaList[mediaIndex];
      }
      state.layerContentsList[idx].contentsData.mediaList = newMediaList;
    },
    removeContentsFileList(state: LayerState, { payload: { contentsFileList, layerId } }: PayloadAction<LayerState>) {
      const idx = state.layerContentsList.findIndex(contents => contents.layerId === layerId);
      for (const contentsFile of contentsFileList) {
        const index = state.layerContentsList[idx].contentsFileList.findIndex(file => file.fileId === contentsFile.fileId);
        state.layerContentsList[idx].contentsFileList.splice(index, 1);
        const mediaIndex = state.layerContentsList[idx].contentsData.mediaList.findIndex(file => file.fileId === contentsFile.fileId);
        state.layerContentsList[idx].contentsData.mediaList.splice(mediaIndex, 1);
      }
    },
  },
});

const selectLayerContentsList = createDraftSafeSelector(
  (state: LayerState) => state.layerContentsList,
  layerContentsList => layerContentsList,
);

const selectStatus = createDraftSafeSelector(
  (state: LayerContentsState) => state.editingMode,
  (state: LayerContentsState) => state.actionResult,
  (state: LayerContentsState) => state.isLoading,
  (state: LayerContentsState) => state.error,
  (editingMode, actionResult, isLoading, error) => ({ editingMode, actionResult, isLoading, error }),
);

export const layerContentsSelector = {
  layerContentsList: state => selectLayerContentsList(state[LAYERCONTENTS]),
  status: state => selectStatus(state[LAYERCONTENTS]),
};

export const LAYERCONTENTS = slice.name;
export const layerContentsReducer = slice.reducer;
export const layerContentsAction = slice.actions;
