import * as apiLib from './api';

export const list = async payload => {
  try {
    const data = {
      ...payload
    };

    const result = await apiLib.fetchArchiveApi('/playlist/list', data);
    if (result.resultFlag) {
      return result.playlistArray;
    } else {
      throw Object.assign(new Error('getPlaylist error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async payload => {
  try {
    const data = {
      ...payload,
    };

    const { resultFlag, playlistInfo } = await apiLib.fetchArchiveApi('/playlist/detail', data);
    if (resultFlag) {
      return playlistInfo;
    } else {
      throw Object.assign(new Error('getPlaylist error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async playlist => {
  try {
    const data = {
      ...playlist,
    };

    const result = await apiLib.fetchArchiveApi('/playlist/add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('addPlaylist error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const update = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/playlist/update', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('updatePlaylist error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/playlist/remove', data);
    if (result.resultFlag) {
      return result.filePathList;
    } else {
      throw Object.assign(new Error('removePlaylist error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
