import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IPlaceAuthInfo {
  placeAuthId: string;
}

interface PlaceAuthState {
  placeAuthList: IPlaceAuthInfo[];
  placeAuthInfo: IPlaceAuthInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: PlaceAuthState = {
  placeAuthList: [],
  placeAuthInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.placeAuthList = [];
    state.placeAuthInfo = {};
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },
  list: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.placeAuthList = payload.placeAuthList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  detail: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  detailSuccess: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.placeAuthInfo = payload.placeAuthInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  detailFailure: (state: PlaceAuthState, { payload }: PayloadAction<PlaceAuthState>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = payload.error;
  },
  actionResultClear: (state: PlaceAuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'placeAuth',
  initialState: InitialState,
  reducers: reducers,
});

const selectPlaceAuthList = createDraftSafeSelector(
  (state: PlaceAuthState) => state.placeAuthList,
  (placeAuthList) => placeAuthList
);

const selectPlaceAuthInfo = createDraftSafeSelector(
  (state: PlaceAuthState) => state.placeAuthInfo,
  placeAuthInfo => placeAuthInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: PlaceAuthState) => state.isLogin,
  (state: PlaceAuthState) => state.actionResult,
  (state: PlaceAuthState) => state.isLoading,
  (state: PlaceAuthState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const placeAuthSelector = {
  placeAuthList: state => selectPlaceAuthList(state[PLACEAUTH]),
  placeAuthInfo: state => selectPlaceAuthInfo(state[PLACEAUTH]),
  status: state => selectStatus(state[PLACEAUTH]),
};

export const PLACEAUTH = slice.name;
export const placeAuthReducer = slice.reducer;
export const placeAuthAction = slice.actions;
