import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface MobilePayload {
  deviceToken: string;
  collectionId: string;
}

interface CollectionInfo {
  collectionId: string;
  collectionNm: string;
}

interface JampicInfo {
  jampicId: string;
}

interface MobileState {
  collectionInfo: CollectionInfo;
  jampicInfo: JampicInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const mobileInitialState: MobileState = {
  collectionInfo: {},
  jampicInfo: {},
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  collection: (state: MobileState, { payload }: PayloadAction<MobilePayload>) => {
    state.actionResult = 'COLLECTION_REQ';
    state.isLoading = true;
    state.error = null;
  },
  collectionSuccess: (state: MobileState, { payload }: PayloadAction<MobileState>) => {
    state.collectionInfo = payload.collection;
    state.actionResult = 'COLLECTION_OK';
    state.isLoading = false;
    state.error = null;
  },
  collectionFailure: (state: MobileState, action: PayloadAction<string>) => {
    state.actionResult = 'COLLECTION_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  jampic: (state: MobileState, { payload }: PayloadAction<MobilePayload>) => {
    state.actionResult = 'JAMPIC_REQ';
    state.isLoading = true;
    state.error = null;
  },
  jampicSuccess: (state: MobileState, { payload }: PayloadAction<MobileState>) => {
    state.jampicInfo = payload.jampic;
    state.actionResult = 'JAMPIC_OK';
    state.isLoading = false;
    state.error = null;
  },
  jampicFailure: (state: MobileState, action: PayloadAction<string>) => {
    state.actionResult = 'JAMPIC_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: MobileState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'mobile',
  initialState: mobileInitialState,
  reducers: reducers,
});

const selectCollectionInfo = createDraftSafeSelector(
  (state: MobileState) => state.collectionInfo,
  collectionInfo => collectionInfo,
);

const selectJampicInfo = createDraftSafeSelector(
  (state: MobileState) => state.jampicInfo,
  jampicInfo => jampicInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: MobileState) => state.actionResult,
  (state: MobileState) => state.isLoading,
  (state: MobileState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const mobileSelector = {
  collectionInfo: state => selectCollectionInfo(state[MOBILE]),
  jampicInfo: state => selectJampicInfo(state[MOBILE]),
  status: state => selectStatus(state[MOBILE]),
};

export const MOBILE = slice.name;
export const mobileReducer = slice.reducer;
export const mobileAction = slice.actions;
