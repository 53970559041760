import * as apiLib from './api';

export const list = async ({ placeId }) => {
  try {
    const data = {
      placeId: placeId,
    };

    const result = await apiLib.fetchArchiveApi('/pack/list', data);
    if (result.resultFlag) {
      return result.packList;
    } else {
      throw Object.assign(new Error('pack error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ packId }) => {
  try {
    const data = {
      packId: packId,
    };

    const result = await apiLib.fetchArchiveApi('/pack/detail', data);
    if (result.resultFlag) {
      return result.packInfo;
    } else {
      throw Object.assign(new Error('pack error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({
  placeId,
  packNm,
  packType,
  packOrder,
  downloadPath,
  movePath,
  moveKillYn,
  processNm,
  processArg,
  processRunYn,
  packFile,
}) => {
  try {
    const data = {
      placeId,
      packNm,
      packType,
      packOrder,
      downloadPath,
      movePath,
      moveKillYn,
      processNm,
      processArg,
      processRunYn,
    };

    if (packFile) {
      const presignPayload = {
        placeId: placeId,
        fileNm: packFile.name,
        fileSize: packFile.size,
        fileType: packFile.type,
      };
      const { url, fileInfo } = await apiLib.fetchArchiveApi('/pack/presign', presignPayload);
      await apiLib.fetchUploadApi(url, packFile);
      data.packFile = fileInfo;
    }

    const result = await apiLib.fetchArchiveApi('/pack/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('add error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const edit = async ({
  placeId,
  packId,
  packNm,
  packType,
  packOrder,
  downloadPath,
  movePath,
  moveKillYn,
  processNm,
  processArg,
  processRunYn,
  packFile,
}) => {
  try {
    const data = {
      placeId,
      packId,
      packNm,
      packType,
      packOrder,
      downloadPath,
      movePath,
      moveKillYn,
      processNm,
      processArg,
      processRunYn,
    };

    if (packFile) {
      const presignPayload = {
        placeId: placeId,
        fileNm: packFile.name,
        fileSize: packFile.size,
        fileType: packFile.type,
      };
      const { url, fileInfo } = await apiLib.fetchArchiveApi('/pack/presign', presignPayload);
      await apiLib.fetchUploadApi(url, packFile);
      data.packFile = fileInfo;
    }

    const result = await apiLib.fetchArchiveApi('/pack/edit', data);
    if (result.resultFlag) {
      return result.packInfo;
    } else {
      throw Object.assign(new Error('edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ packId }) => {
  try {
    const data = {
      packId: packId,
    };

    const result = await apiLib.fetchArchiveApi('/pack/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const groupMove = async ({ groupId, packList }) => {
  try {
    const data = {
      groupId: groupId,
      packList: packList,
    };

    const result = await apiLib.fetchArchiveApi('/pack/group-move', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('group move error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const presign = async payload => {
  try {
    const data = {
      ...payload,
    };
    const result = await apiLib.fetchArchiveApi('/pack/presign', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw new Error('presign error');
    }
  } catch (err) {
    throw err;
  }
};
