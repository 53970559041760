import * as apiLib from './api';

export const list = async payload => {
  try {
    const data = {
      placeId: payload.placeId,
      limit: 100,
      LastEvaluatedKey: null,
    };
    const result = await apiLib.fetchArchiveApi('/record/list', data);
    if (result.resultFlag) {
      const sortRecordList = result.recordList.sort((lhs, rhs) => {
        return lhs.year - rhs.year;
      });

      return sortRecordList;
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = {
      ...payload,
    };
    const result = await apiLib.fetchArchiveApi('/record/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('record Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
export const remove = async payload => {
  try {
    const data = {
      ...payload,
    };
    const result = await apiLib.fetchArchiveApi('/record/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('record Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async payload => {
  try {
    const data = {
      ...payload,
    };
    const result = await apiLib.fetchArchiveApi('/record/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('record Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async payload => {
  try {
    const data = {
      ...payload,
    };
    const result = await apiLib.fetchArchiveApi('/record/detail', data);
    if (result.resultFlag) {
      return result.recordInfo;
    } else {
      throw Object.assign(new Error('record Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const orderUpdate = async payload => {
  try {
    const data = {
      ...payload,
    };
    const result = await apiLib.fetchArchiveApi('/record/orderUpdate', data);
    if (result.resultFlag) {
      return result.resultFlag;
    } else {
      throw Object.assign(new Error('record Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
