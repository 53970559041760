import { combineReducers } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import { AUTH, authReducer } from './authSlice';
import { ACCOUNT, accountReducer } from './accountSlice';
import { USER, userReducer } from './userSlice';
import { PLACE, placeReducer } from './placeSlice';
import { PLACEAUTH, placeAuthReducer } from './placeAuthSlice';
import { SPACE, spaceReducer } from './spaceSlice';
import { GROUP, groupReducer } from './groupSlice';
import { DEVICE, deviceReducer } from './deviceSlice';
import { PACK, packReducer } from './packSlice';
import { COLLECTION, collectionReducer } from './collectionSlice';
import { EXHIBITION, exhibitionReducer } from './exhibitionSlice';
import { CURATION, curationReducer } from './curationSlice';
import { PLAYLIST, playlistReducer } from './playlistSlice';
import { PACKAGE, packageReducer } from './packageSlice';
import { FRAME, frameReducer } from './frameSlice';
import { FRAMEGROUP, frameGroupReducer } from './frameGroupSlice';
import { LAYER, layerReducer } from './layerSlice';
import { LAYERCONTENTS, layerContentsReducer } from './layerContentsSlice';
import { MOBILE, mobileReducer } from './mobileSlice';
import { PLAYLISTLOG, playlistLogReducer } from './playlistLogSlice';
import { PHOTOGROUP, photoGroupReducer } from './photoGroupSlice';
import { PHOTO, photoReducer } from './photoSlice';
import { GALLERY, galleryReducer } from './gallerySlice';
import { RECORD, recordReducer } from './recordSlice';
import { HISTORY, historyReducer } from './historySlice';
import { HISTORYCATEGORY, historyCategoryReducer } from './historyCategorySlice';
import { FACILITY, facilityReducer } from './facilitySlice';
import { DIRECTOR, directorReducer } from './directorSlice';
import { MAGAZINE, magazineReducer } from './magazineSlice';

import authSaga from './authSaga';
import accountSaga from './accountSaga';
import userSaga from './userSaga';
import placeSaga from './placeSaga';
import placeAuthSaga from './placeAuthSaga';
import spaceSaga from './spaceSaga';
import groupSaga from './groupSaga';
import deviceSaga from './deviceSaga';
import packSaga from './packSaga';
import collectionSaga from './collectionSaga';
import exhibitionSaga from './exhibitionSaga';
import curationSaga from './curationSaga';
import playlistSaga from './playlistSaga';
import frameGroupSaga from './frameGroupSaga';
import packageSaga from './packageSaga';
import mobileSaga from './mobileSaga';
import playlistLogSaga from './playlistLogSaga';
import photoSaga from './photoSaga';
import photoGroupSaga from './photoGroupSaga';
import gallerySaga from './gallerySaga';
import recordSaga from './recordSaga';
import historySaga from './historySaga';
import historyCategorySaga from './historyCategorySaga';
import facilitySaga from './facilitySaga';
import directorSaga from './directorSaga';
import magazineSaga from './magazineSaga';

const createRootReducer = () =>
  combineReducers({
    [AUTH]: authReducer,
    [ACCOUNT]: accountReducer,
    [USER]: userReducer,
    [PLACE]: placeReducer,
    [PLACEAUTH]: placeAuthReducer,
    [SPACE]: spaceReducer,
    [GROUP]: groupReducer,
    [DEVICE]: deviceReducer,
    [PACK]: packReducer,
    [COLLECTION]: collectionReducer,
    [EXHIBITION]: exhibitionReducer,
    [CURATION]: curationReducer,
    [PLAYLIST]: playlistReducer,
    [PACKAGE]: packageReducer,
    [FRAME]: frameReducer,
    [FRAMEGROUP]: frameGroupReducer,
    [LAYER]: layerReducer,
    [LAYERCONTENTS]: layerContentsReducer,
    [MOBILE]: mobileReducer,
    [PLAYLISTLOG]: playlistLogReducer,
    [PHOTOGROUP]: photoGroupReducer,
    [PHOTO]: photoReducer,
    [GALLERY]: galleryReducer,
    [DIRECTOR]: directorReducer,
    [RECORD]: recordReducer,
    [HISTORY]: historyReducer,
    [MAGAZINE]: magazineReducer,
    [HISTORYCATEGORY]: historyCategoryReducer,
    [FACILITY]: facilityReducer,
  });

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([
    authSaga(),
    accountSaga(),
    userSaga(),
    placeSaga(),
    placeAuthSaga(),
    spaceSaga(),
    groupSaga(),
    deviceSaga(),
    packSaga(),
    collectionSaga(),
    exhibitionSaga(),
    curationSaga(),
    playlistSaga(),
    frameGroupSaga(),
    packageSaga(),
    mobileSaga(),
    playlistLogSaga(),
    photoSaga(),
    photoGroupSaga(),
    gallerySaga(),
    directorSaga(),
    historySaga(),
    historyCategorySaga(),
    recordSaga(),
    facilitySaga(),
    magazineSaga(),
  ]);
}

export default function createStore() {
  const store = configureStore({
    reducer: createRootReducer(),
    devTools: true,
    middleware: getDefaultMiddleware => [...getDefaultMiddleware({ serializableCheck: false }), sagaMiddleware],
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
