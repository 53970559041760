import * as apiLib from './api';

export const list = async ({ spaceId }) => {
  try {
    const data = {
      spaceId: spaceId,
    };

    const result = await apiLib.fetchArchiveApi('/place/list', data);
    if (result.resultFlag) {
      return result.placeList;
    } else {
      throw Object.assign(new Error('place list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const aliasList = async ({ spaceId }) => {
  try {
    const data = {
      spaceId: spaceId,
    };

    const result = await apiLib.fetchArchiveApi('/place/alias-list', data);
    if (result.resultFlag) {
      return result.placeAliasList;
    } else {
      throw Object.assign(new Error('place list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};


export const detail = async ({ placeId }) => {
  try {
    const data = {
      placeId: placeId,
    };

    const result = await apiLib.fetchArchiveApi('/place/detail', data);
    if (result.resultFlag) {
      return result.placeInfo;
    } else {
      throw Object.assign(new Error('place detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({ accountId, placePid, placeNm }) => {
  try {
    const data = {
      accountId: accountId,
      placePid: placePid,
      placeNm: placeNm,
    };

    const result = await apiLib.fetchArchiveApi('/place/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('place add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({ placeId, placeNm }) => {
  try {
    const data = {
      placeId: placeId,
      placeNm: placeNm,
    };

    const result = await apiLib.fetchArchiveApi('/place/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('place edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ placeId }) => {
  try {
    const data = {
      placeId: placeId,
    };

    const result = await apiLib.fetchArchiveApi('/place/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('place remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const upload = async ({ placeId, file }) => {
  try {
    const uploadData = new FormData();
    uploadData.append('placeId', placeId);
    uploadData.append('uploadFile', file, file.name);

    const result = await apiLib.fetchArchiveMultipartApi(
      '/place/image-upload',
      uploadData
    );
    if (result.resultFlag) {
      return result.placeInfo;
    } else {
      throw Object.assign(new Error('place upload error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const upgrade = async ({ placeId, pricing, licenseSeat }) => {
  try {
    const data = {
      placeId: placeId,
      pricing: pricing,
      licenseSeat: licenseSeat,
    };

    const result = await apiLib.fetchArchiveApi('/place/upgrade', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('place upgrade error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberList = async ({ placeId }) => {
  try {
    const data = {
      placeId: placeId,
    };

    const result = await apiLib.fetchArchiveApi('/place/member-list', data);
    if (result.resultFlag) {
      return { memberList: result.memberList, accountList: result.accountList };
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberDetail = async ({ placeId, accountId }) => {
  try {
    const data = {
      placeId: placeId,
      accountId: accountId,
    };

    const result = await apiLib.fetchArchiveApi('/place/member-detail', data);
    if (result.resultFlag) {
      return result.memberInfo;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberAdd = async ({ placeId, accountEmail, permissionType }) => {
  try {
    const data = {
      placeId: placeId,
      accountEmail: accountEmail,
      permissionType: permissionType,
    };

    const result = await apiLib.fetchArchiveApi('/place/member-add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberEdit = async ({ placeId, accountId, permissionType }) => {
  try {
    const data = {
      placeId: placeId,
      accountId: accountId,
      permissionType: permissionType,
    };

    const result = await apiLib.fetchArchiveApi('/place/member-edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberRemove = async ({ placeId, accountId }) => {
  try {
    const data = {
      placeId: placeId,
      accountId: accountId,
    };

    const result = await apiLib.fetchArchiveApi('/place/member-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const agent = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchArchiveApi('/place/agent', data);
    if (result.resultFlag) {
      return result.agentInfo;
    } else {
      throw Object.assign(new Error('agent error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
