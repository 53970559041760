import * as apiLib from './api';

export const list = async ({ placeId, groupPid }) => {
  try {
    const data = {
      placeId: placeId,
      groupPid: groupPid,
    };

    const result = await apiLib.fetchArchiveApi('/group/list', data);
    if (result.resultFlag) {
      return result.groupList;
    } else {
      throw Object.assign(new Error('group error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ groupId }) => {
  try {
    const data = {
      groupId: groupId,
    };

    const result = await apiLib.fetchArchiveApi('/group/detail', data);
    if (result.resultFlag) {
      return result.group;
    } else {
      throw Object.assign(new Error('group error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({ placeId, groupPid, groupNm, sortSeq }) => {
  try {
    const data = {
      placeId: placeId,
      groupPid: groupPid,
      groupNm: groupNm,
      sortSeq: sortSeq,
    };

    const result = await apiLib.fetchArchiveApi('/group/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('group error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({ groupPid, groupId, groupNm, sortSeq }) => {
  try {
    const data = {
      groupPid: groupPid,
      groupId: groupId,
      groupNm: groupNm,
      sortSeq: sortSeq,
    };

    const result = await apiLib.fetchArchiveApi('/group/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('group error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ placeId, groupId }) => {
  try {
    const data = {
      placeId: placeId,
      groupId: groupId,
    };

    const result = await apiLib.fetchArchiveApi('/group/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('group error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const sort = async ({
  srcGroupId,
  srcSortSeq,
  dstGroupId,
  dstSortSeq,
}) => {
  try {
    const data = {
      srcGroupId: srcGroupId,
      srcSortSeq: srcSortSeq,
      dstGroupId: dstGroupId,
      dstSortSeq: dstSortSeq,
    };

    const result = await apiLib.fetchArchiveApi('/group/sort', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('group error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
