import * as apiLib from './api';

export const detail = async ({ accountId }) => {
  try {
    const data = {
      accountId: accountId,
    };

    const result = await apiLib.fetchArchiveApi('/account/detail', data);
    if (result.resultFlag) {
      return { accountInfo: result.accountInfo, memberInfo: result.memberInfo };
    } else {
      throw Object.assign(new Error('account detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({ accountId, accountNm }) => {
  try {
    const data = {
      accountId: accountId,
      accountNm: accountNm,
    };

    const result = await apiLib.fetchArchiveApi('/account/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('account edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchArchiveApi('/account/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('account remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const passwordChange = async ({ loginPw }) => {
  try {
    const data = {
      accountPw: loginPw,
    };

    const result = await apiLib.fetchArchiveApi('/account/password-change', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('account password change error'), {
        status: 401,
      });
    }
  } catch (err) {
    throw err;
  }
};

export const placeChange = async ({ accountId, placeId }) => {
  try {
    const data = {
      accountId: accountId,
      placeId: placeId,
    };

    const result = await apiLib.fetchArchiveApi('/account/place-change', data);
    if (result.resultFlag) {
      return { accountInfo: result.accountInfo, memberInfo: result.memberInfo };
    } else {
      throw Object.assign(new Error('account place change error'), {
        status: 401,
      });
    }
  } catch (err) {
    throw err;
  }
};

export const upload = async ({ accountId, file }) => {
  try {
    const uploadData = new FormData();
    uploadData.append('accountId', accountId);
    uploadData.append('uploadFile', file, file.name);

    const result = await apiLib.fetchArchiveMultipartApi(
      '/account/image-upload',
      uploadData
    );
    if (result.resultFlag) {
      return result.accountInfo;
    } else {
      throw Object.assign(new Error('upload error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
