import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IHistoryInfo {
  historyId: string;
}

interface IHistoryFilter {
  categoryId: String;
  startYear: String;
  endYear: String;
  useYn: String;
}

interface HistoryState {
  historyList: IHistoryInfo[];
  historyInfo: IHistoryInfo;
  historyFilter: IHistoryFilter;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: HistoryState = {
  historyList: [],
  historyInfo: {},
  historyFilter: { categoryId: '', startYear: '', endYear: '', useYn: '' },
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: HistoryState, { payload }: PayloadAction<HistoryState>) => {
    state.historyList = [];
    state.historyInfo = {};
    state.historyFilter = { categoryId: '', startYear: '', endYear: '', useYn: '' };
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },
  historyFilter: (state: HistoryState, { payload }: PayloadAction<IHistoryFilter>) => {
    state.historyFilter = payload;
  },
  list: (state: HistoryState, { payload }: PayloadAction<HistoryState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: HistoryState, { payload }: PayloadAction<HistoryState>) => {
    state.historyList = payload.historyList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: HistoryState, { payload }: PayloadAction<HistoryState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  detail: (state: HistoryState, { payload }: PayloadAction<HistoryState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  detailSuccess: (state: HistoryState, { payload }: PayloadAction<HistoryState>) => {
    state.historyInfo = payload.historyInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  detailFailure: (state: HistoryState, { payload }: PayloadAction<HistoryState>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = payload.error;
  },
  add: (state: HistoryState, { payload }: PayloadAction<CsutomerHistoryState>) => {
    state.isLoading = true;
    state.actionResult = 'ADD_REQ';
    state.error = '';
  },
  addSuccess: (state: HistoryState, { payload }: PayloadAction<HistoryState>) => {
    state.historyList = payload.historyList;
    state.isLoading = false;
    state.actionResult = 'ADD_OK';
    state.error = '';
  },
  addFailure: (state: HistoryState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_ERR';
    state.error = action.payload;
  },
  edit: (state: HistoryState, { payload }: PayloadAction<CsutomerHistoryState>) => {
    state.isLoading = true;
    state.actionResult = 'EDIT_REQ';
    state.error = '';
  },
  editSuccess: (state: HistoryState, { payload }: PayloadAction<HistoryState>) => {
    state.historyList = payload.historyList;
    state.isLoading = false;
    state.actionResult = 'EDIT_OK';
    state.error = '';
  },
  editFailure: (state: HistoryState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_ERR';
    state.error = action.payload;
  },
  reOrder: (state: HistoryState, { payload }: PayloadAction<CsutomerHistoryState>) => {
    state.isLoading = true;
    state.actionResult = 'REORDER_REQ';
    state.error = '';
  },
  reOrderSuccess: (state: HistoryState, { payload }: PayloadAction<HistoryState>) => {
    state.historyList = payload.historyList;
    state.isLoading = false;
    state.actionResult = 'REORDER_OK';
    state.error = '';
  },
  reOrderFailure: (state: HistoryState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REORDER_ERR';
    state.error = action.payload;
  },
  remove: (state: HistoryState, { payload }: PayloadAction<CsutomerHistoryState>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: HistoryState, { payload }: PayloadAction<HistoryState>) => {
    state.historyList = payload.historyList;
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: HistoryState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = action.payload;
  },
  actionResultClear: (state: HistoryState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'history',
  initialState: InitialState,
  reducers: reducers,
});

const selectHistoryList = createDraftSafeSelector(
  (state: HistoryState) => state.historyList,
  historyList => historyList,
);

const selectHistoryFilterList = createDraftSafeSelector(
  (state: HistoryState) => state.historyList,
  (state: HistoryState) => state.historyFilter,
  (historyList, historyFilter) => {
    return historyList.filter(history => {
      let isCategoryIdMatch = true;
      let isHistoryDtMatch = true;
      let isUseYnMatch = true;
      if (historyFilter.categoryId !== '') {
        isCategoryIdMatch = history.categoryId === historyFilter.categoryId;
      }
      if (historyFilter.startYear !== '') {
        isHistoryDtMatch =
          Number(history.historyDt.substring(0, 4)) >= Number(historyFilter.startYear) &&
          Number(history.historyDt.substring(0, 4)) <= Number(historyFilter.endYear);
      }
      if (historyFilter.useYn !== '') {
        isUseYnMatch = history.useYn === historyFilter.useYn;
      }
      return isCategoryIdMatch && isHistoryDtMatch && isUseYnMatch;
    });
  },
);

const selectHistoryInfo = createDraftSafeSelector(
  (state: HistoryState) => state.historyInfo,
  historyInfo => historyInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: HistoryState) => state.actionResult,
  (state: HistoryState) => state.isLoading,
  (state: HistoryState) => state.error,
  (actionResult, isLoading, error) => ({
    actionResult,
    isLoading,
    error,
  }),
);

export const historySelector = {
  historyList: state => selectHistoryList(state[HISTORY]),
  historyFilterList: state => selectHistoryFilterList(state[HISTORY]),
  historyInfo: state => selectHistoryInfo(state[HISTORY]),
  status: state => selectStatus(state[HISTORY]),
};

export const HISTORY = slice.name;
export const historyReducer = slice.reducer;
export const historyAction = slice.actions;
