import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authSelector } from '../../modules/authSlice';
import { accountSelector, accountAction } from '../../modules/accountSlice';
import { userSelector, userAction } from '../../modules/userSlice';
import { placeAction, placeSelector } from '../../modules/placeSlice';
import { placeAuthAction, placeAuthSelector } from '../../modules/placeAuthSlice';

import Loading from '../../components/Loading';

const DataLoad = ({ setIsLoading }) => {
  const dispatch = useDispatch();

  const accountInfo = useSelector(accountSelector.accountInfo);
  const placeInfo = useSelector(placeSelector.placeInfo);
  const userInfo = useSelector(userSelector.userInfo);
  const loginInfo = useSelector(authSelector.loginInfo);
  const { actionResult: placeActionResult } = useSelector(placeSelector.status);
  const { actionResult: placeAuthActionResult } = useSelector(placeAuthSelector.status);
  const { actionResult: accountActionResult } = useSelector(accountSelector.status);
  const { actionResult: userActionResult } = useSelector(userSelector.status);

  useEffect(() => {
    if (loginInfo.loginType === 'user') {
      dispatch(userAction.detail({ userId: loginInfo.userId }));
    } else {
      dispatch(accountAction.detail({ accountId: loginInfo.accountId }));
    }
  }, [dispatch, loginInfo]);

  useEffect(() => {
    let placeId = '';
    if (accountActionResult === 'DETAIL_OK') {
      placeId = accountInfo.viewPlaceId;
      dispatch(accountAction.actionResultClear());
    } else if (userActionResult === 'DETAIL_OK') {
      placeId = userInfo.placeId;
      dispatch(userAction.actionResultClear());
    }

    if (placeId) {
      dispatch(
        placeAction.detail({
          placeId,
        }),
      );
    }
  }, [dispatch, accountActionResult, userActionResult, userInfo, accountInfo]);

  useEffect(() => {
    if (placeActionResult === 'DETAIL_OK') {
      dispatch(placeAction.actionResultClear());

      if (loginInfo.loginType === 'user') {
        dispatch(placeAuthAction.detail({ authId: userInfo.authId }));
      } else {
        dispatch(placeAuthAction.detail({ accountId: accountInfo.accountId, placeId: placeInfo.placeId, spaceId: placeInfo.spaceId }));
      }
    }
  }, [dispatch, placeActionResult, accountInfo, placeInfo, userInfo, loginInfo]);

  useEffect(() => {
    if (placeAuthActionResult === 'DETAIL_OK') {
      dispatch(placeAuthAction.actionResultClear());
      setIsLoading(isLoading => false);
    }
  }, [dispatch, placeAuthActionResult, setIsLoading]);

  return <Loading />;
};
// spaceId && accountId && placeId
export default React.memo(DataLoad);
