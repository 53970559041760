import React from 'react';
import { BrowserRouter, Navigate, useRoutes } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import AuthLayout from '../pages/layouts/Auth';
import ManagerLayout from '../pages/layouts/Manager';
import SpaceLayout from '../pages/layouts/Space';
import MobileLayout from '../pages/layouts/Mobile';
import ErrorBoundary from '../pages/error/ErrorBoundary';

const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

const AuthError = React.lazy(() => import('../pages/error/AuthError'));

const DashboardDefault = React.lazy(() => import('../pages/dashboard/Default'));

const SpaceList = React.lazy(() => import('../pages/space/SpaceList'));

const AccountProfile = React.lazy(() => import('../pages/profile/AccountProfile'));
const UserProfile = React.lazy(() => import('../pages/profile/UserProfile'));

const ArchiveContent = React.lazy(() => import('../pages/archiveWall/Content'));
const ArchiveContentAdd = React.lazy(() => import('../pages/archiveWall/ContentAdd'));
const ArchiveContentDetail = React.lazy(() => import('../pages/archiveWall/ContentDetail'));
const Collection = React.lazy(() => import('../pages/archiveWall/Collection'));
const CollectionAdd = React.lazy(() => import('../pages/archiveWall/CollectionAdd'));
const CollectionDetail = React.lazy(() => import('../pages/archiveWall/CollectionDetail'));
const CurationAdd = React.lazy(() => import('../pages/archiveWall/CurationAdd'));
const CurationDetail = React.lazy(() => import('../pages/archiveWall/CurationDetail'));

const PhotoKioskContent = React.lazy(() => import('../pages/photoKiosk/Content'));
const PhotoKioskContentAdd = React.lazy(() => import('../pages/photoKiosk/ContentAdd'));
const PhotoKioskContentDetail = React.lazy(() => import('../pages/photoKiosk/ContentDetail'));
const PhotoList = React.lazy(() => import('../pages/photoKiosk/PhotoList'));
const PhotoAdd = React.lazy(() => import('../pages/photoKiosk/PhotoAdd'));
const PhotoDetail = React.lazy(() => import('../pages/photoKiosk/PhotoDetail'));
const ShareGalleryList = React.lazy(() => import('../pages/photoKiosk/GalleryList'));

const Content = React.lazy(() => import('../pages/contentManagement/Content'));
const ContentAdd = React.lazy(() => import('../pages/contentManagement/ContentAdd'));
const ContentDetail = React.lazy(() => import('../pages/contentManagement/ContentDetail'));

const BasicContent = React.lazy(() => import('../pages/historyHome/BasicContent'));
const RecordAdd = React.lazy(() => import('../pages/historyHome/RecordAdd'));
const RecordDetail = React.lazy(() => import('../pages/historyHome/RecordDetail'));
const DirectorContent = React.lazy(() => import('../pages/historyHome/DirectorContent'));
const DirectorAdd = React.lazy(() => import('../pages/historyHome/DirectorAdd'));
const DirectorDetail = React.lazy(() => import('../pages/historyHome/DirectorDetail'));
const MagazineAdd = React.lazy(() => import('../pages/historyHome/MagazineAdd'));
const MagazineDetail = React.lazy(() => import('../pages/historyHome/MagazineDetail'));
const FacilityContent = React.lazy(() => import('../pages/historyHome/FacilityContent'));
const FacilityAdd = React.lazy(() => import('../pages/historyHome/FacilityAdd'));
const FacilityDetail = React.lazy(() => import('../pages/historyHome/FacilityDetail'));

const Exhibition = React.lazy(() => import('../pages/exhibition/Exhibition'));
const ExhibitionAdd = React.lazy(() => import('../pages/exhibition/ExhibitionAdd'));
const ExhibitionDetail = React.lazy(() => import('../pages/exhibition/ExhibitionDetail'));

const Report = React.lazy(() => import('../pages/report/Report'));

const System = React.lazy(() => import('../pages/system/System'));
const DeviceDetail = React.lazy(() => import('../pages/system/DeviceDetail'));
const PackAdd = React.lazy(() => import('../pages/system/PackAdd'));
const PackDetail = React.lazy(() => import('../pages/system/PackDetail'));
const UserAdd = React.lazy(() => import('../pages/system/UserAdd'));
const UserDetail = React.lazy(() => import('../pages/system/UserDetail'));

const Wall = React.lazy(() => import('../pages/mobile/Wall'));
const Jampic = React.lazy(() => import('../pages/mobile/Jampic'));

const Routes = () => {
  const authRoutes: RouteObject = {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/auth/login" />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'logout',
        element: <Logout />,
      },
    ],
  };

  const rootRoutes: RouteObject = {
    path: '/',
    element: <ManagerLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard" />,
      },
    ],
  };

  const errorRoutes: RouteObject = {
    path: '/error',
    element: <ManagerLayout />,
    children: [
      {
        path: 'auth',
        element: <AuthError />,
      },
    ],
  };

  const spaceRoutes: RouteObj = {
    path: '/space',
    element: <SpaceLayout />,
    children: [
      {
        path: 'list',
        element: <SpaceList />,
      },
    ],
  };

  const dashboardRoutes: RouteObject = {
    path: '/dashboard',
    element: <ManagerLayout />,
    children: [
      {
        index: true,
        element: <DashboardDefault />,
      },
    ],
  };

  const archiveWallRoutes: RouteObject = {
    path: '/archive-wall',
    element: <ManagerLayout />,
    children: [
      {
        path: 'content',
        children: [
          {
            path: 'list',
            element: <ArchiveContent tabIndex={0} />,
          },
          {
            path: 'add',
            element: <ArchiveContentAdd />,
          },
          {
            path: 'detail',
            element: <ArchiveContentDetail />,
          },
          {
            path: 'play/list',
            element: <ArchiveContent tabIndex={1} />,
          },
        ],
      },
      {
        path: 'collection',
        children: [
          {
            path: 'list',
            element: <Collection tabIndex={0} />,
          },
          {
            path: 'category/list',
            element: <Collection tabIndex={1} />,
          },
          {
            path: 'curation/list',
            element: <Collection tabIndex={2} />,
          },
          {
            path: 'add',
            element: <CollectionAdd />,
          },
          {
            path: 'detail',
            element: <CollectionDetail />,
          },
          {
            path: 'curation/add',
            element: <CurationAdd />,
          },
          {
            path: 'curation/detail',
            element: <CurationDetail />,
          },
        ],
      },
      {
        path: 'error/auth',
        element: <AuthError />,
      },
    ],
  };

  const photoKioskRoutes: RouteObject = {
    path: '/photo-kiosk',
    element: <ManagerLayout />,
    children: [
      {
        path: 'content',
        children: [
          {
            path: 'list',
            element: <PhotoKioskContent tabIndex={0} />,
          },
          {
            path: 'add',
            element: <PhotoKioskContentAdd />,
          },
          {
            path: 'detail',
            element: <PhotoKioskContentDetail />,
          },
          {
            path: 'play/list',
            element: <PhotoKioskContent tabIndex={1} />,
          },
        ],
      },
      {
        path: 'photo',
        children: [
          {
            path: 'list',
            element: <PhotoList />,
          },
          {
            path: 'add',
            element: <PhotoAdd />,
          },
          {
            path: 'detail',
            element: <PhotoDetail />,
          },
        ],
      },
      {
        path: 'gallery',
        children: [
          {
            path: 'list',
            element: <ShareGalleryList />,
          },
        ],
      },
      {
        path: 'error/auth',
        element: <AuthError />,
      },
    ],
  };

  const contentRoutes: RouteObject = {
    path: '/content-management',
    element: <ManagerLayout />,
    children: [
      {
        path: 'content',
        children: [
          {
            path: 'list',
            element: <Content tabIndex={0} />,
          },
          {
            path: 'add',
            element: <ContentAdd />,
          },
          {
            path: 'detail',
            element: <ContentDetail />,
          },
          {
            path: 'play/list',
            element: <Content tabIndex={1} />,
          },
        ],
      },
    ],
  };

  const historyHomeRoutes: RouteObject = {
    path: '/history-home',
    element: <ManagerLayout />,
    children: [
      {
        path: 'basic-content',
        children: [
          {
            path: 'history/list',
            element: <BasicContent tabIndex={0} />,
          },
          {
            path: 'record/list',
            element: <BasicContent tabIndex={1} />,
          },
          {
            path: 'record/add',
            element: <RecordAdd />,
          },
          {
            path: 'record/detail',
            element: <RecordDetail />,
          },
          {
            path: 'magazine/list',
            element: <BasicContent tabIndex={2} />,
          },
          {
            path: 'magazine/add',
            element: <MagazineAdd />,
          },
          {
            path: 'magazine/detail',
            element: <MagazineDetail />,
          },
        ],
      },
      {
        path: 'director-content',
        children: [
          {
            path: 'director/list',
            element: <DirectorContent tabIndex={0} />,
          },
          {
            path: 'director/add',
            element: <DirectorAdd />,
          },
          {
            path: 'director/detail',
            element: <DirectorDetail />,
          },
        ],
      },
      {
        path: 'facility-content',
        children: [
          {
            path: 'facility/list',
            element: <FacilityContent tabIndex={0} />,
          },
          {
            path: 'facility/add',
            element: <FacilityAdd />,
          },
          {
            path: 'facility/detail',
            element: <FacilityDetail />,
          },
        ],
      },
      {
        path: 'error/auth',
        element: <AuthError />,
      },
    ],
  };

  const exhibitionRoutes: RouteObject = {
    path: '/exhibition',
    element: <ManagerLayout />,
    children: [
      {
        path: 'list',
        element: <Exhibition tabIndex={0} />,
      },
      {
        path: 'add',
        element: <ExhibitionAdd />,
      },
      {
        path: 'detail',
        element: <ExhibitionDetail />,
      },
    ],
  };

  const reportRoutes: RouteObject = {
    path: '/report',
    element: <ManagerLayout />,
    children: [
      {
        path: 'collection',
        element: <Report tabIndex={0} />,
      },
      {
        path: 'exhibition',
        element: <Report tabIndex={1} />,
      },
      {
        path: 'error/auth',
        element: <AuthError />,
      },
    ],
  };

  const profileRoutes: RouteObject = {
    path: '/profile',
    element: <ManagerLayout />,
    children: [
      {
        path: 'account',
        element: <AccountProfile />,
      },
      {
        path: 'user',
        element: <UserProfile />,
      },
    ],
  };

  const systemRoutes: RouteObject = {
    path: '/system',
    element: <ManagerLayout />,
    children: [
      {
        path: 'place',
        element: <System tabIndex={0} />,
      },
      {
        path: 'device/list',
        element: <System tabIndex={1} />,
      },
      {
        path: 'device/detail',
        element: <DeviceDetail />,
      },
      {
        path: 'pack/list',
        element: <System tabIndex={2} />,
      },
      {
        path: 'pack/add',
        element: <PackAdd />,
      },
      {
        path: 'pack/detail',
        element: <PackDetail />,
      },
      {
        path: 'user/list',
        element: <System tabIndex={3} />,
      },
      {
        path: 'user/add',
        element: <UserAdd />,
      },
      {
        path: 'user/detail',
        element: <UserDetail />,
      },
    ],
  };

  const mobileRoutes: RouteObj = {
    path: '/mobile',
    element: <MobileLayout />,
    children: [
      {
        path: 'wall',
        element: <Wall />,
      },
      {
        path: 'jampic',
        element: <Jampic />,
      },
    ],
  };

  const noMatchRoutes: RouteObject = {
    path: '*',
    element: <Navigate to="/" />,
  };

  const routes: RouteObject[] = [
    authRoutes,
    rootRoutes,
    dashboardRoutes,
    profileRoutes,
    spaceRoutes,
    noMatchRoutes,
    archiveWallRoutes,
    photoKioskRoutes,
    contentRoutes,
    historyHomeRoutes,
    exhibitionRoutes,
    reportRoutes,
    errorRoutes,
    systemRoutes,
    mobileRoutes,
  ];

  return useRoutes(routes);
};

const Router = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default React.memo(Router);
