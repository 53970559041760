import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IplaylistLogInfo {
  playlistLogId: string;
}

interface PlaylistLogFilter {
  deviceFilter: String;
  viewTypeFileter: String;
}

interface playlistLogState {
  playlistLogList: IplaylistLogInfo[];
  playlistLogInfo: IplaylistLogInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: playlistLogState = {
  playlistLogList: [],
  playlistLogInfo: {},
  playlistLogFilter: { deviceFilter: '', viewTypeFileter: 'DAY' },
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: playlistLogState, { payload }: PayloadAction<playlistLogState>) => {
    state.playlistLogList = [];
    state.playlistLogInfo = {};
    state.playlistLogFilter = { deviceFilter: '', viewTypeFileter: 'DAY' };
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },

  list: (state: playlistLogState, { payload }: PayloadAction<playlistLogState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: playlistLogState, { payload }: PayloadAction<playlistLogState>) => {
    state.playlistLogList = payload.playlistLogList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: playlistLogState, { payload }: PayloadAction<playlistLogState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },

  /*

  detail: (state: playlistLogState, { payload }: PayloadAction<playlistLogState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  detailSuccess: (state: playlistLogState, { payload }: PayloadAction<playlistLogState>) => {
    state.playlistLogInfo = payload.playlistLogInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  detailFailure: (state: playlistLogState, { payload }: PayloadAction<playlistLogState>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = payload.error;
  },
  add: (state: playlistLogState, { payload }: PayloadAction<CsutomerplaylistLogState>) => {
    state.isLoading = true;
    state.actionResult = 'ADD_REQ';
    state.error = '';
  },
  addSuccess: (state: playlistLogState, { payload }: PayloadAction<playlistLogState>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_OK';
    state.error = '';
  },
  addFailure: (state: playlistLogState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_ERR';
    state.error = action.payload;
  },
  edit: (state: playlistLogState, { payload }: PayloadAction<CsutomerplaylistLogState>) => {
    state.isLoading = true;
    state.actionResult = 'EDIT_REQ';
    state.error = '';
  },
  editSuccess: (state: playlistLogState, { payload }: PayloadAction<playlistLogState>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_OK';
    state.error = '';
  },
  editFailure: (state: playlistLogState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_ERR';
    state.error = action.payload;
  },
  remove: (state: playlistLogState, { payload }: PayloadAction<CsutomerplaylistLogState>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: playlistLogState, { payload }: PayloadAction<playlistLogState>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: playlistLogState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = action.payload;
  },
  */

  playlistLogFilter: (state: playlistLogState, { payload }: PayloadAction<PlaylistLogFilter>) => {
    state.playlistLogFilter = payload;
    state.actionResult = 'FILTER_REQ';
  },
  actionResultClear: (state: playlistLogState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'playlistLog',
  initialState: InitialState,
  reducers: reducers,
});

const selectplaylistLogList = createDraftSafeSelector(
  (state: playlistLogState) => state.playlistLogList,
  playlistLogList => playlistLogList,
);

const selectCollectionFilterList = createDraftSafeSelector(
  (state: playlistLogState) => state.playlistLogList,
  (state: playlistLogState) => state.playlistLogFilter,
  (playlistLogList, playlistLogFilter) => {
    let filterPlaylistLogList = playlistLogList;

    if (playlistLogFilter.deviceFilter !== '') {
      filterPlaylistLogList = filterPlaylistLogList.filter(playlistLog => playlistLog.deviceId === playlistLogFilter.deviceFilter);
    }

    let filterCustomData = {};

    if (playlistLogFilter.viewTypeFileter === 'WORK') {
      // let filterCustomData = {};

      for (const playlistLog of filterPlaylistLogList) {
        if (filterCustomData[playlistLog.logDetail.collectionId] === undefined) {
          filterCustomData[playlistLog.logDetail.collectionId] = { ...playlistLog.logDetail };
        } else {
          filterCustomData[playlistLog.logDetail.collectionId].collectionOpenCnt += playlistLog.logDetail.collectionOpenCnt;
          filterCustomData[playlistLog.logDetail.collectionId].collectionLikeCnt += playlistLog.logDetail.collectionLikeCnt;
          filterCustomData[playlistLog.logDetail.collectionId].collectionShareCnt += playlistLog.logDetail.collectionShareCnt;
          filterCustomData[playlistLog.logDetail.collectionId].collectionNm = playlistLog.logDetail.collectionNm;
        }
      }

      filterPlaylistLogList = [];

      for (const collectId in filterCustomData) {
        filterPlaylistLogList.push(filterCustomData[collectId]);
      }

      filterPlaylistLogList = filterPlaylistLogList.sort(function (lhs, rhs) {
        if (lhs.collectionOpenCnt && rhs.collectionOpenCnt) {
          return rhs.collectionOpenCnt - lhs.collectionOpenCnt;
        }
        return 0;
      });
    } else {
      for (const playlistLog of filterPlaylistLogList) {
        if (filterCustomData[playlistLog.logDt] === undefined) {
          filterCustomData[playlistLog.logDt] = {};
        }

        if (filterCustomData[playlistLog.logDt][playlistLog.deviceId] === undefined) {
          filterCustomData[playlistLog.logDt][playlistLog.deviceId] = {
            collectionOpenCnt: playlistLog.logDetail.collectionOpenCnt,
            collectionLikeCnt: playlistLog.logDetail.collectionLikeCnt,
            collectionShareCnt: playlistLog.logDetail.collectionShareCnt,
          };
        } else {
          filterCustomData[playlistLog.logDt][playlistLog.deviceId].collectionOpenCnt += playlistLog.logDetail.collectionOpenCnt;
          filterCustomData[playlistLog.logDt][playlistLog.deviceId].collectionLikeCnt += playlistLog.logDetail.collectionLikeCnt;
          filterCustomData[playlistLog.logDt][playlistLog.deviceId].collectionShareCnt += playlistLog.logDetail.collectionShareCnt;
        }
      }

      filterPlaylistLogList = [];
      for (const logDt in filterCustomData) {
        for (const deviceId in filterCustomData[logDt]) {
          filterPlaylistLogList.push({ ...filterCustomData[logDt][deviceId], logDt: logDt, deviceId: deviceId });
        }
      }
    }

    return filterPlaylistLogList;
  },
);

const selectExhibitionFilterList = createDraftSafeSelector(
  (state: playlistLogState) => state.playlistLogList,
  (state: playlistLogState) => state.playlistLogFilter,
  (playlistLogList, playlistLogFilter) => {
    let filterPlaylistLogList = playlistLogList;

    if (playlistLogFilter.deviceFilter !== '') {
      filterPlaylistLogList = filterPlaylistLogList.filter(playlistLog => playlistLog.deviceId === playlistLogFilter.deviceFilter);
    }

    let filterCustomData = {};

    if (playlistLogFilter.viewTypeFileter === 'WORK') {
      for (const playlistLog of filterPlaylistLogList) {
        if (filterCustomData[playlistLog.logDetail.exhibitionId] === undefined) {
          filterCustomData[playlistLog.logDetail.exhibitionId] = { ...playlistLog.logDetail };
        } else {
          filterCustomData[playlistLog.logDetail.exhibitionId].exhibitionNm = playlistLog.logDetail?.exhibitionNm || '';
          filterCustomData[playlistLog.logDetail.exhibitionId].exhibitionOpenCnt += playlistLog.logDetail.exhibitionOpenCnt;
        }
      }

      filterPlaylistLogList = [];

      for (const exhibitionId in filterCustomData) {
        filterPlaylistLogList.push(filterCustomData[exhibitionId]);
      }

      filterPlaylistLogList = filterPlaylistLogList.sort(function (lhs, rhs) {
        if (lhs.exhibitionOpenCnt && rhs.exhibitionOpenCnt) {
          return rhs.exhibitionOpenCnt - lhs.exhibitionOpenCnt;
        }
        return 0;
      });
    } else {
      for (const playlistLog of filterPlaylistLogList) {
        if (filterCustomData[playlistLog.logDt] === undefined) {
          filterCustomData[playlistLog.logDt] = {};
        }

        if (filterCustomData[playlistLog.logDt][playlistLog.deviceId] === undefined) {
          filterCustomData[playlistLog.logDt][playlistLog.deviceId] = { exhibitionOpenCnt: playlistLog.logDetail.exhibitionOpenCnt };
        } else {
          filterCustomData[playlistLog.logDt][playlistLog.deviceId].exhibitionOpenCnt += playlistLog.logDetail.exhibitionOpenCnt;
        }
      }

      filterPlaylistLogList = [];
      for (const logDt in filterCustomData) {
        for (const deviceId in filterCustomData[logDt]) {
          filterPlaylistLogList.push({ ...filterCustomData[logDt][deviceId], logDt: logDt, deviceId: deviceId });
        }
      }
    }

    return filterPlaylistLogList;
  },
);

const selectplaylistLogInfo = createDraftSafeSelector(
  (state: playlistLogState) => state.playlistLogInfo,
  playlistLogInfo => playlistLogInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: playlistLogState) => state.isLogin,
  (state: playlistLogState) => state.actionResult,
  (state: playlistLogState) => state.isLoading,
  (state: playlistLogState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const playlistLogSelector = {
  playlistLogList: state => selectplaylistLogList(state[PLAYLISTLOG]),
  collectionFilterList: state => selectCollectionFilterList(state[PLAYLISTLOG]),
  exhibitionFilterList: state => selectExhibitionFilterList(state[PLAYLISTLOG]),
  playlistLogInfo: state => selectplaylistLogInfo(state[PLAYLISTLOG]),
  status: state => selectStatus(state[PLAYLISTLOG]),
};

export const PLAYLISTLOG = slice.name;
export const playlistLogReducer = slice.reducer;
export const playlistLogAction = slice.actions;
