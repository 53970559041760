import React, { Suspense, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import HashLoader from 'react-spinners/HashLoader';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';

import { authSelector } from '../../modules/authSlice';

import DataLoad from '../common/DataLoad';

const Token = React.lazy(() => import('../auth/Token'));
const Title = React.lazy(() => import('../common/Title'));
const Header = React.lazy(() => import('../common/Header'));
const NavBar = React.lazy(() => import('../common/NavBar'));
const Footer = React.lazy(() => import('../common/Footer'));

const loading = () => <div></div>;

const contentLoading = () => (
  <HashLoader
    css={{
      display: 'block',
      width: '100%',
      height: '100%',
      margin: '0 auto',
    }}
    color={'#41a1ea'}
    loading={true}
    size={100}
  />
);

const Horizontal = () => {
  const [isLoading, setIsLoading] = useState(true);

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const status = useSelector(authSelector.status);
  if (!status.isLogin) {
    return (
      <Suspense fallback={loading()}>
        <Token />
      </Suspense>
    );
  }

  return (
    <Container isMobile={isMobile}>
      {/*
      {(isMobile || isMenuOpen) && (
        <Suspense fallback={loading()}>
          <NavBar />
        </Suspense>
      )}
       */}
      <Content isMobile={isMobile}>
        <Suspense fallback={loading()}>
          <Header isMobile={isMobile} />
        </Suspense>
        {isLoading ? (
          <DataLoad setIsLoading={setIsLoading} />
        ) : (
          <Main>
            <Suspense fallback={loading()}>
              <NavBar />
            </Suspense>

            <Suspense fallback={contentLoading()}>
              <Section isMobile={isMobile}>
                <Title />
                <Body>
                  <Outlet />
                </Body>
              </Section>

              <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
              />
            </Suspense>
          </Main>
        )}
        <Suspense fallback={loading()}>
          <Footer />
        </Suspense>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
`;

const Section = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  // padding: 0 45px;
  background: #f4f5f6;
  display: flex;
  flex-direction: column;
  padding-left: ${props => (props.isMobile ? '0' : '240px')};
`;

const Main = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
`;

const Body = styled.div`
  padding: 15px 45px;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(37, 51, 66, 0.12);
  overflow: hidden;
`;

const Content = styled.div`
  width: 100%;
  padding-top: ${props => (props.isMobile ? '4rem' : '0')};
  /*
  padding-left: ${props => (props.isMobile || !props.isMenuOpen ? '0' : '12.5rem')};
    */
  display: flex;
  flex-direction: column;
`;

export default Horizontal;
