import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { magazineAction } from './magazineSlice';

import * as magazineApiLib from '../lib/magazineApi';
import * as storageApiLib from '../lib/storageApi';

function* list({ payload }) {
  try {
    const magazineList = yield call(magazineApiLib.list, payload);

    const sortArray = magazineList.sort(function (a, b) {
      return new Date(b.magazineDt) - new Date(a.magazineDt);
    });

    yield put(magazineAction.listSuccess({ magazineList: sortArray }));
  } catch (error) {
    yield put(magazineAction.listFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    const fileList = payload.fileList;
    const coverFileList = payload.coverFileList;
    const placeId = payload.placeId;
    const handleUploadProgress = payload.handleUploadProgress;

    const resultFileList = yield call(storageApiLib.upload, { fileList, placeId, handleUploadProgress });
    const resultCoverFileList = yield call(storageApiLib.upload, { fileList: coverFileList, placeId, handleUploadProgress });

    yield call(magazineApiLib.add, { ...payload, fileList: resultFileList, coverFileList: resultCoverFileList });

    yield put(magazineAction.addSuccess());
  } catch (error) {
    yield put(magazineAction.addFailure({ error }));
  }
}

function* detail({ payload }) {
  try {
    const magazineInfo = yield call(magazineApiLib.detail, payload);

    yield put(magazineAction.detailSuccess({ magazineInfo }));
  } catch (error) {
    yield put(magazineAction.detailFailure({ error }));
  }
}

function* edit({ payload }) {
  try {
    const placeId = payload.placeId;
    const fileListInfo = payload?.fileListInfo || { addFileList: [], removeFileList: [], fileList: [], placeId: placeId };
    const coverFileListInfo = payload?.coverFileListInfo || { addFileList: [], removeFileList: [], fileList: [], placeId: placeId };
    const handleUploadProgress = payload.handleUploadProgress;

    const resultFileList = yield call(storageApiLib.uploadAndRemove, { ...fileListInfo, handleUploadProgress });
    const resultCoverFileList = yield call(storageApiLib.uploadAndRemove, { ...coverFileListInfo, handleUploadProgress });

    const callList = payload.updateList.map(update => {
      const updateInfo = { ...update.updateInfo, fileList: resultFileList, coverFileList: resultCoverFileList };

      const callApi = call(magazineApiLib.edit, { magazineId: update.magazineId, updateInfo: updateInfo });
      return callApi;
    });

    yield all([...callList]);

    yield put(magazineAction.editSuccess());
  } catch (error) {
    yield put(magazineAction.editFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    const removeMagazineList = payload.removeMagazineList;
    let magazineIdList = [];
    let removeCallList = [];

    for (const magazine of removeMagazineList) {
      const magazineId = magazine.magazineId;

      magazineIdList.push(magazineId);
    }

    yield all([...removeCallList]);
    yield call(storageApiLib.remove, { filePathList: payload.removeFilePathList });
    yield call(magazineApiLib.remove, { magazineIdList });

    const magazineList = yield call(magazineApiLib.list, { placeId: payload.placeId });

    yield put(magazineAction.removeSuccess({ magazineList }));
  } catch (error) {
    yield put(magazineAction.removeFailure({ error }));
  }
}

export function* watchList() {
  yield takeLatest(magazineAction.list, list);
}

export function* watchAdd() {
  yield takeLatest(magazineAction.add, add);
}

export function* watchDetail() {
  yield takeLatest(magazineAction.detail, detail);
}

export function* watchEdit() {
  yield takeLatest(magazineAction.edit, edit);
}

export function* watchRemove() {
  yield takeLatest(magazineAction.remove, remove);
}

function* rootSaga() {
  yield all([fork(watchList), fork(watchAdd), fork(watchDetail), fork(watchEdit), fork(watchRemove)]);
}

export default rootSaga;
