import * as apiLib from './api';

export const list = async ({ accountId }) => {
  try {
    const data = {
      accountId: accountId,
    };

    const result = await apiLib.fetchArchiveApi('/space/list', data);
    if (result.resultFlag) {
      return result.spaceList;
    } else {
      throw Object.assign(new Error('space list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ spaceId }) => {
  try {
    const data = {
      spaceId: spaceId,
    };

    const result = await apiLib.fetchArchiveApi('/space/detail', data);
    if (result.resultFlag) {
      return result.spaceInfo;
    } else {
      throw Object.assign(new Error('space detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({ accountId, spacePid, spaceNm }) => {
  try {
    const data = {
      accountId: accountId,
      spacePid: spacePid,
      spaceNm: spaceNm,
    };

    const result = await apiLib.fetchArchiveApi('/space/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('space add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({ spaceId, spaceNm }) => {
  try {
    const data = {
      spaceId: spaceId,
      spaceNm: spaceNm,
    };

    const result = await apiLib.fetchArchiveApi('/space/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('space edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ spaceId }) => {
  try {
    const data = {
      spaceId: spaceId,
    };

    const result = await apiLib.fetchArchiveApi('/space/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('space remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const upload = async ({ spaceId, file }) => {
  try {
    const uploadData = new FormData();
    uploadData.append('spaceId', spaceId);
    uploadData.append('uploadFile', file, file.name);

    const result = await apiLib.fetchArchiveMultipartApi(
      '/space/image-upload',
      uploadData
    );
    if (result.resultFlag) {
      return result.spaceInfo;
    } else {
      throw Object.assign(new Error('space upload error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const upgrade = async ({ spaceId, pricing, licenseSeat }) => {
  try {
    const data = {
      spaceId: spaceId,
      pricing: pricing,
      licenseSeat: licenseSeat,
    };

    const result = await apiLib.fetchArchiveApi('/space/upgrade', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('space upgrade error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberList = async ({ spaceId }) => {
  try {
    const data = {
      spaceId: spaceId,
    };

    const result = await apiLib.fetchArchiveApi('/space/member-list', data);
    if (result.resultFlag) {
      return { memberList: result.memberList, accountList: result.accountList };
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberDetail = async ({ spaceId, accountId }) => {
  try {
    const data = {
      spaceId: spaceId,
      accountId: accountId,
    };

    const result = await apiLib.fetchArchiveApi('/space/member-detail', data);
    if (result.resultFlag) {
      return result.memberInfo;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberAdd = async ({ spaceId, accountEmail, permissionType }) => {
  try {
    const data = {
      spaceId: spaceId,
      accountEmail: accountEmail,
      permissionType: permissionType,
    };

    const result = await apiLib.fetchArchiveApi('/space/member-add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberEdit = async ({ spaceId, accountId, permissionType }) => {
  try {
    const data = {
      spaceId: spaceId,
      accountId: accountId,
      permissionType: permissionType,
    };

    const result = await apiLib.fetchArchiveApi('/space/member-edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberRemove = async ({ spaceId, accountId }) => {
  try {
    const data = {
      spaceId: spaceId,
      accountId: accountId,
    };

    const result = await apiLib.fetchArchiveApi('/space/member-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const player = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchArchiveApi('/space/player', data);
    if (result.resultFlag) {
      return result.playerInfo;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
