import { all, fork, put, call, takeLatest } from 'redux-saga/effects';

import { photoAction } from './photoSlice';

import * as photoApiLib from '../lib/photoApi';
import * as storageApiLib from '../lib/storageApi';
import * as photoGroupApiLib from '../lib/photoGroupApi';

function* list({ payload }) {
  try {
    const photoList = yield call(photoApiLib.list, payload);

    yield put(photoAction.listSuccess({ photoList }));
  } catch (error) {
    yield put(photoAction.listFailure({ error }));
  }
}

function* detail({ payload }) {
  try {
    const photoInfo = yield call(photoApiLib.detail, payload);

    yield put(photoAction.detailSuccess({ photoInfo }));
  } catch (error) {
    yield put(photoAction.detailFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    const photoId = payload.photoId;
    const placeId = payload.placeId;
    const photoFileList = payload.photoFileList;
    const photoFileDetailList = payload.photoFileDetailList;
    const formValue = payload.formValue;
    const handleUploadProgress = payload.handleUploadProgress;

    const resultPhotoFileList = yield call(storageApiLib.upload, { fileList: photoFileList, placeId, handleUploadProgress });
    const resultPhotoFileDetailList = yield call(storageApiLib.upload, { fileList: photoFileDetailList, placeId, handleUploadProgress });

    yield call(photoGroupApiLib.linkPhoto, { groupIdList: formValue.groupIdList, photoId });

    yield call(photoApiLib.add, {
      ...formValue,
      photoId,
      placeId,
      photoFileList: resultPhotoFileList,
      photoFileDetailList: resultPhotoFileDetailList,
    });

    yield put(photoAction.addSuccess());
  } catch (error) {
    yield put(photoAction.addFailure({ error }));
  }
}
function* update({ payload }) {
  try {
    const formValue = payload.formValue;
    const placeId = payload.placeId;
    const photoId = payload.photoId;
    const photoFileList = payload.photoFileList;
    const photoFileDetailList = payload.photoFileDetailList;
    const addPhotoFileList = payload.addPhotoFileList;
    const addPhotoFileDetailList = payload.addPhotoFileDetailList;
    const removePhotoFileList = payload.removePhotoFileList;
    const removePhotoFileDetailList = payload.removePhotoFileDetailList;
    const linkGroupIdList = payload.linkGroupIdList;
    const unLinkGroupIdList = payload.unLinkGroupIdList;
    const handleUploadProgress = payload.handleUploadProgress;

    const resultPhotoFileList = yield call(storageApiLib.uploadAndRemove, {
      addFileList: addPhotoFileList,
      removeFileList: removePhotoFileList,
      fileList: photoFileList,
      placeId: placeId,
      handleUploadProgress,
    });

    const resultPhotoFileDetailList = yield call(storageApiLib.uploadAndRemove, {
      addFileList: addPhotoFileDetailList,
      removeFileList: removePhotoFileDetailList,
      fileList: photoFileDetailList,
      placeId: placeId,
      handleUploadProgress,
    });

    yield call(photoGroupApiLib.linkPhoto, { groupIdList: linkGroupIdList, photoId: photoId });
    yield call(photoGroupApiLib.unLinkPhoto, { groupIdList: unLinkGroupIdList, photoId: photoId });

    yield call(photoApiLib.update, {
      ...formValue,
      photoId,
      photoFileList: resultPhotoFileList,
      photoFileDetailList: resultPhotoFileDetailList,
    });

    yield put(photoAction.updateSuccess());
  } catch (error) {
    yield put(photoAction.updateFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    let callList = [];
    let photoIdList = [];
    let removeFilePathList = [];

    for (const photoInfo of payload.photoIdList) {
      photoIdList.push(photoInfo.photoId);
      const fileListInfo = photoInfo.photoFileList.concat(photoInfo.photoFileDetailList);

      for (const file of fileListInfo) {
        removeFilePathList.push(file.filePath);
        if (file.thumbFilePath) {
          removeFilePathList.push(file.thumbFilePath);
        }
      }

      callList.push(call(photoGroupApiLib.unLinkPhoto, { photoId: photoInfo.photoId, groupIdList: photoInfo.groupIdList }));
    }

    yield call(storageApiLib.remove, { filePathList: removeFilePathList });
    yield all([...callList]);
    yield call(photoApiLib.remove, { photoIdList: photoIdList });

    yield put(photoAction.removeSuccess());
  } catch (error) {
    yield put(photoAction.removeFailure({ error }));
  }
}

export function* watchList() {
  yield takeLatest(photoAction.list, list);
}

export function* watchDetail() {
  yield takeLatest(photoAction.detail, detail);
}

export function* watchAdd() {
  yield takeLatest(photoAction.add, add);
}

export function* watchUpdate() {
  yield takeLatest(photoAction.update, update);
}

export function* watchRemove() {
  yield takeLatest(photoAction.remove, remove);
}

function* rootSaga() {
  yield all([fork(watchList), fork(watchAdd), fork(watchDetail), fork(watchUpdate), fork(watchRemove)]);
}

export default rootSaga;
