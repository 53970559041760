import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { directorAction } from './directorSlice';

import * as directorApiLib from '../lib/directorApi';
import * as storageApiLib from '../lib/storageApi';
import * as historyCategoryApi from '../lib/historyCategoryApi';

function* list({ payload }) {
  try {
    const directorList = yield call(directorApiLib.list, payload);

    yield put(directorAction.listSuccess({ directorList }));
  } catch (error) {
    yield put(directorAction.listFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    const fileList = payload.fileList;
    const placeId = payload.placeId;
    const handleUploadProgress = payload.handleUploadProgress;
    const directorId = payload.directorId;
    const categoryId = payload.categoryId;

    const resultFileList = yield call(storageApiLib.upload, { fileList, placeId, handleUploadProgress });

    yield call(historyCategoryApi.linkCategory, { relationIdList: [directorId], categoryId: categoryId });
    yield call(directorApiLib.add, { ...payload, fileList: resultFileList });

    yield put(directorAction.addSuccess());
  } catch (error) {
    yield put(directorAction.addFailure({ error }));
  }
}

function* detail({ payload }) {
  try {
    const directorInfo = yield call(directorApiLib.detail, payload);

    yield put(directorAction.detailSuccess({ directorInfo }));
  } catch (error) {
    yield put(directorAction.detailFailure({ error }));
  }
}

function* edit({ payload }) {
  try {
    const directorId = payload.directorId;
    const handleUploadProgress = payload.handleUploadProgress;
    const linkId = payload.linkId;
    const unLinkId = payload.unLinkId;

    const fileList = yield call(storageApiLib.uploadAndRemove, {
      addFileList: payload.addFileList,
      removeFileList: payload.removeFileList,
      fileList: payload.fileList,
      placeId: payload.placeId,
      handleUploadProgress,
    });

    if (linkId !== unLinkId) {
      yield call(historyCategoryApi.linkCategory, { relationIdList: [directorId], categoryId: linkId });
      yield call(historyCategoryApi.unLinkCategory, { relationIdList: [directorId], categoryId: unLinkId });
    }

    yield call(directorApiLib.edit, {
      directorId,
      updateInfo: { ...payload.updateInfo, fileList },
    });

    yield put(directorAction.editSuccess());
  } catch (error) {
    yield put(directorAction.editFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    const removeDirectorList = payload.removeDirectorList;
    let directorIdList = [];
    let unLinkCategoryInfo = {};
    let removeCallList = [];

    for (const director of removeDirectorList) {
      const directorId = director.directorId;
      const categoryId = director.categoryId;
      directorIdList.push(directorId);
      if (categoryId) {
        if (unLinkCategoryInfo[categoryId]) {
          unLinkCategoryInfo[categoryId].push(directorId);
        } else {
          unLinkCategoryInfo[categoryId] = [directorId];
        }
      }
    }

    for (const categoryId in unLinkCategoryInfo) {
      removeCallList.push(
        call(historyCategoryApi.unLinkCategory, { relationIdList: unLinkCategoryInfo[categoryId], categoryId: categoryId }),
      );
    }

    yield all([...removeCallList]);
    yield call(storageApiLib.remove, { filePathList: payload.removeFilePathList });
    yield call(directorApiLib.remove, { directorIdList });

    const directorList = yield call(directorApiLib.list, { placeId: payload.placeId });

    yield put(directorAction.removeSuccess({ directorList }));
  } catch (error) {
    yield put(directorAction.removeFailure({ error }));
  }
}

export function* watchList() {
  yield takeLatest(directorAction.list, list);
}

export function* watchAdd() {
  yield takeLatest(directorAction.add, add);
}

export function* watchDetail() {
  yield takeLatest(directorAction.detail, detail);
}

export function* watchEdit() {
  yield takeLatest(directorAction.edit, edit);
}

export function* watchRemove() {
  yield takeLatest(directorAction.remove, remove);
}

function* rootSaga() {
  yield all([fork(watchList), fork(watchAdd), fork(watchDetail), fork(watchEdit), fork(watchRemove)]);
}

export default rootSaga;
