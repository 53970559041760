import * as apiLib from './api';

export const list = async payload => {
  try {
    let photoGroupList = [];

    let searchFlag = true;

    let data = {
      placeId: payload.placeId,
      limit: 100,
      LastEvaluatedKey: null,
    };

    while (searchFlag) {
      const result = await apiLib.fetchArchiveApi('/photoGroup/limit-list', data);
      photoGroupList = photoGroupList.concat(result.photoGroupList);
      if (result.LastEvaluatedKey === undefined) {
        searchFlag = false;
      }
      data.LastEvaluatedKey = result.LastEvaluatedKey;
    }

    // const sortPhotoGroupList = photoGroupList.sort((lhs, rhs) => lhs.regDt - rhs.regDt);
    // return sortPhotoGroupList;

    return photoGroupList;
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/photoGroup/add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('photo Add Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const update = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/photoGroup/update', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('photoGroup update Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/photoGroup/remove', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('photoGroup Remove Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};

export const linkPhoto = async ({ groupIdList, photoId }) => {
  try {
    const data = {
      groupIdList,
      photoId,
    };

    const result = await apiLib.fetchArchiveApi('/photoGroup/link-photo', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('photo Link photoGroup Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};

export const unLinkPhoto = async ({ groupIdList, photoId }) => {
  try {
    const data = {
      groupIdList,
      photoId,
    };

    const result = await apiLib.fetchArchiveApi('/photoGroup/unLink-photo', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('photo unLink photoGroup Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};
