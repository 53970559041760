import { all, fork, call, put, select, takeLatest } from 'redux-saga/effects';
import { collectionAction, collectionSelector } from './collectionSlice';

import * as collectionApiLib from '../lib/collectionApi';
import * as storageApiLib from '../lib/storageApi';
import * as curationApiLib from '../lib/curationApi';

function* list({ payload }) {
  try {
    const collectionList = yield call(collectionApiLib.list, payload);

    yield put(collectionAction.listSuccess({ collectionList }));
  } catch (error) {
    yield put(collectionAction.listFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    const fileList = payload.fileList;
    const placeId = payload.placeId;
    const handleUploadProgress = payload.handleUploadProgress;

    const resultFileList = yield call(storageApiLib.upload, { fileList, placeId, handleUploadProgress });
    yield call(collectionApiLib.add, { ...payload, fileList: resultFileList });
    yield call(collectionApiLib.linkCollection, {
      collectionId: payload.collectionId,
      relationCollectionIdList: payload.relationCollectionIdList,
    });

    yield put(collectionAction.addSuccess());
  } catch (error) {
    yield put(collectionAction.addFailure({ error }));
  }
}

function* detail({ payload }) {
  try {
    const collectionInfo = yield call(collectionApiLib.detail, payload);

    yield put(collectionAction.detailSuccess({ collectionInfo }));
  } catch (error) {
    yield put(collectionAction.detailFailure({ error }));
  }
}

function* edit({ payload }) {
  try {
    const { categoryId: beforeCategoryId, relationCollectionIdList: beforeRelationCollectionIdList } = yield select(
      collectionSelector.collectionInfo,
    );
    const collectionId = payload.collectionId;
    const afterCategoryId = payload.updateInfo.categoryId;
    const afterRelationCollectionIdList = payload.updateInfo.relationCollectionIdList;
    const handleUploadProgress = payload.handleUploadProgress;

    const fileList = yield call(storageApiLib.uploadAndRemove, {
      addFileList: payload.addFileList,
      removeFileList: payload.removeFileList,
      fileList: payload.fileList,
      placeId: payload.placeId,
      handleUploadProgress,
    });

    yield call(collectionApiLib.edit, {
      collectionId,
      updateInfo: { ...payload.updateInfo, fileList },
      beforeCategoryId,
      afterCategoryId,
    });

    if (afterRelationCollectionIdList) {
      const removeRelationCollectionIdList = beforeRelationCollectionIdList.filter(
        collectionId => !afterRelationCollectionIdList.find(afterRelationCollectionId => afterRelationCollectionId === collectionId),
      );
      const addRelationCollectionIdList = afterRelationCollectionIdList.filter(
        collectionId => !beforeRelationCollectionIdList.find(beforeRelationCollectionId => beforeRelationCollectionId === collectionId),
      );
      if (addRelationCollectionIdList.length > 0) {
        yield call(collectionApiLib.linkCollection, { collectionId, relationCollectionIdList: addRelationCollectionIdList });
      }
      if (removeRelationCollectionIdList.length > 0) {
        yield call(collectionApiLib.unlinkCollection, { collectionId, relationCollectionIdList: removeRelationCollectionIdList });
      }
    }

    yield put(collectionAction.editSuccess());
  } catch (error) {
    yield put(collectionAction.editFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    const collectionList = yield select(collectionSelector.collectionList);
    const collectionIdList = payload.collectionIdList;
    yield call(storageApiLib.remove, { filePathList: payload.removeFilePathList });
    yield call(collectionApiLib.remove, { collectionIdList });

    for (const collectionId of collectionIdList) {
      const relationCollectionIdList = collectionList.find(
        collection => collection.collectionId === collectionId,
      )?.relationCollectionIdList;
      const relationCurationIdList = collectionList.find(collection => collection.collectionId === collectionId)?.relationCurationIdList;
      yield call(collectionApiLib.unlinkCollection, { collectionId, relationCollectionIdList });
      yield call(curationApiLib.unlinkCuration, { collectionId, relationCurationIdList });
    }

    yield put(collectionAction.removeSuccess());
  } catch (error) {
    yield put(collectionAction.removeFailure({ error }));
  }
}

function* categoryList({ payload }) {
  try {
    const categoryList = yield call(collectionApiLib.categoryList, payload);

    yield put(collectionAction.categoryListSuccess({ categoryList }));
  } catch (err) {
    yield put(collectionAction.categoryListFailure('Category List Error'));
  }
}

function* categoryAdd({ payload }) {
  try {
    const fileList = payload?.fileList || [];
    const placeId = payload.placeId;

    const resultFileList = yield call(storageApiLib.upload, { fileList, placeId });

    yield call(collectionApiLib.categoryAdd, { ...payload, fileList: resultFileList });

    const categoryList = yield call(collectionApiLib.categoryList, { placeId });

    yield put(collectionAction.categoryAddSuccess({ categoryList }));
  } catch (err) {
    yield put(collectionAction.categoryAddFailure('Category Add Error'));
  }
}

function* categoryUpdate({ payload }) {
  try {
    const addFileList = payload?.addFileList || [];
    const removeFileList = payload?.removeFileList || [];
    const fileList = payload?.fileList || [];

    const resultFileList = yield call(storageApiLib.uploadAndRemove, {
      addFileList: addFileList,
      removeFileList: removeFileList,
      fileList: fileList,
      placeId: payload.placeId,
    });

    const callList = payload.updateList.map(update => {
      const updateInfo = { ...update.updateInfo, fileList: resultFileList };
      const callApi = call(collectionApiLib.categoryUpdate, { categoryId: update.categoryId, updateInfo: updateInfo });
      return callApi;
    });

    yield all([...callList]);
    // yield call(collectionApiLib.categoryUpdate, payload);

    const categoryList = yield call(collectionApiLib.categoryList, { placeId: payload.placeId });

    yield put(collectionAction.categoryUpdateSuccess({ categoryList }));
  } catch (err) {
    yield put(collectionAction.categoryUpdateFailure('Category Update Error'));
  }
}

function* categoryOrderUpdate({ payload }) {
  try {
    const callList = payload.updateList.map(updateInfo => {
      const callApi = call(collectionApiLib.categoryUpdate, { ...updateInfo });
      return callApi;
    });

    yield all([...callList]);
    // yield call(collectionApiLib.categoryUpdate, payload);

    const categoryList = yield call(collectionApiLib.categoryList, { placeId: payload.placeId });

    yield put(collectionAction.categoryOrderUpdateSuccess({ categoryList }));
  } catch (err) {
    yield put(collectionAction.categoryOrderUpdateFailure('Category Order Update Error'));
  }
}

function* categoryRemove({ payload }) {
  try {
    yield call(storageApiLib.remove, { filePathList: payload.removeFilePathList });

    const callList = payload.removeList.map(categoryId => {
      const callApi = call(collectionApiLib.categoryRemove, { categoryId });
      return callApi;
    });

    yield all([...callList]);

    let categoryList = yield call(collectionApiLib.categoryList, { placeId: payload.placeId });

    yield put(collectionAction.categoryRemoveSuccess({ categoryList }));

    let updateIndex = [];

    for (let index = 0; index < categoryList.length; index++) {
      if (categoryList[index].categoryOrder !== index + 1) {
        updateIndex.push({
          categoryId: categoryList[index].categoryId,
          updateInfo: {
            categoryOrder: index + 1,
          },
        });
      }
    }

    yield put(collectionAction.categoryOrderUpdate({ updateList: updateIndex, placeId: payload.placeId }));
    //TODO update
    // yield put(collectionAction)
  } catch (err) {
    yield put(collectionAction.categoryRemoveFailure({ error: err }));
  }
}

function* curationIdAdd({ payload }) {
  try {
    /*
    const callList = payload.collectionList.map(collectionId => {
      const callApi = call(collectionApiLib.linkCuration, { collectionId : collectionId, curationId:payload.curationId });
      return callApi;
    });

    yield all([...callList]);
    */

    for (const curationId of payload.curationList) {
      yield call(collectionApiLib.linkCuration, { curationId: curationId, collectionList: payload.collectionList });
    }

    const collectionList = yield call(collectionApiLib.list, { placeId: payload.placeId });

    yield put(collectionAction.curationIdAddSuccess({ collectionList }));
  } catch (err) {
    yield put(collectionAction.curationIdAddFailure('curationId Add Error'));
  }
}

function* curationIdRemove({ payload }) {
  try {
    /*
    const callList = payload.collectionList.map(collectionId => {
      const callApi = call(collectionApiLib.unlinkCuration, { collectionId : collectionId, curationId:payload.curationId });
      return callApi;
    });
    yield all([...callList]);
    */

    for (const curationId of payload.curationList) {
      yield call(collectionApiLib.unlinkCuration, { curationId: curationId, collectionList: payload.collectionList });
    }

    const collectionList = yield call(collectionApiLib.list, { placeId: payload.placeId });

    yield put(collectionAction.curationIdRemoveSuccess({ collectionList }));
  } catch (err) {
    yield put(collectionAction.curationIdRemoveFailure('curationId Remove Error'));
  }
}

export function* watchCollectionList() {
  yield takeLatest(collectionAction.list, list);
}

export function* watchCollectionAdd() {
  yield takeLatest(collectionAction.add, add);
}

export function* watchCollectionDetail() {
  yield takeLatest(collectionAction.detail, detail);
}

export function* watchCollectionEdit() {
  yield takeLatest(collectionAction.edit, edit);
}

export function* watchCollectionRemove() {
  yield takeLatest(collectionAction.remove, remove);
}

export function* watchCollectionCategoryList() {
  yield takeLatest(collectionAction.categoryList, categoryList);
}

export function* watchCollectionCategoryAdd() {
  yield takeLatest(collectionAction.categoryAdd, categoryAdd);
}

export function* watchCollectionCategoryUpdate() {
  yield takeLatest(collectionAction.categoryUpdate, categoryUpdate);
}

export function* watchCollectionCategoryOrderUpdate() {
  yield takeLatest(collectionAction.categoryOrderUpdate, categoryOrderUpdate);
}

export function* watchCollectionCategoryRemove() {
  yield takeLatest(collectionAction.categoryRemove, categoryRemove);
}

export function* watchCurationIdAdd() {
  yield takeLatest(collectionAction.curationIdAdd, curationIdAdd);
}

export function* watchCurationIdRemove() {
  yield takeLatest(collectionAction.curationIdRemove, curationIdRemove);
}

function* rootSaga() {
  yield all([
    fork(watchCollectionList),
    fork(watchCollectionAdd),
    fork(watchCollectionDetail),
    fork(watchCollectionEdit),
    fork(watchCollectionRemove),
    fork(watchCollectionCategoryList),
    fork(watchCollectionCategoryAdd),
    fork(watchCollectionCategoryUpdate),
    fork(watchCollectionCategoryOrderUpdate),
    fork(watchCollectionCategoryRemove),
    fork(watchCurationIdAdd),
    fork(watchCurationIdRemove),
  ]);
}

export default rootSaga;
