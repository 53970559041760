import React from 'react';
import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';
import { Link } from 'react-router-dom';

const buttonSizes = {
  large: {
    minWidth: '72px',
    height: '48px',
    fontSize: '20px'
  },
  medium: {
    minWidth: '58px',
    height: '32px',
    fontSize: '14px'
  },
  small: {
    minWidth: '44px',
    height: '32px',
    fontSize: '12px'
  }
};

const buttonStyles = css`
  /* 공통 스타일 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 18px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding: 0 10px;

  /* 기타 */
  & + & {
    margin-left: 10px;
  }

  /* 색상 */
  ${({ theme, color, outline , font}) => {
    const buttonColor = theme[color];
    const fontColor = theme[font];

    return css`
      background: ${buttonColor};
      color: ${fontColor};
      &:hover {
        background: ${lighten(0.1, buttonColor)};
      }
      &:active {
        background: ${darken(0.1, buttonColor)};
      }

      ${outline &&
        css`
          color: ${buttonColor};
          background: none;
          border: 1px solid ${buttonColor};
          &:hover {
            background: ${buttonColor};
            color: white;
          }
          &:active {
            background: ${darken(0.1, buttonColor)};
            color: white;
          }
        `}

  
    `;
  }}

  /* 크기 */
  ${({ size }) => css`
    min-width: ${buttonSizes[size].minWidth};
    height: ${buttonSizes[size].height};
    font-size: ${buttonSizes[size].fontSize};
  `}
`;

const StyledButton = styled.button`
  ${buttonStyles}
`;

const StyledLink = styled(Link)`
  ${buttonStyles}
`;

const Button = ({
  to,
  color = 'defaultBlue',
  size = 'medium',
  outline,
  font = 'white',
  ...rest
}) => {
  return to ? (
    <StyledLink to={to} color={color} size={size} outline={outline} font={font} {...rest} />
  ) : (
    <StyledButton color={color} size={size} outline={outline} font = {font} {...rest} />
  )
};

export default Button;
