import { createSlice, createDraftSafeSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface ExhibitionFilterPayload {
  openStateFilter: String;
  searchValue: String;
  searchType: String;
}
interface IFileInfo {
  fileDuration: number;
  fileId: string;
  fileNm: string;
  fileOrder: number;
  filePath: string;
  fileSize: number;
  fileType: string;
  placeId: string;
  regDt: string;
  thumbFilePath?: string;
  thumbFileSize?: string;
}

interface IExhibitionInfo {
  exhibitionId: string;
  placeId: string;
  exhibitionNm: string;
  exhibitionEngNm: string;
  placeNm: string;
  placeEngNm: string;
  authorNm: string;
  authorEngNm: string;
  exhibitionStartDt: string;
  exhibitionEndDt: string;
  subTitleList: { subTitleId: string, subTitle: string, subEngTitle: string }[];
  exhibitionDetail: string;
  exhibitionEngDetail: string;
  exhibitionOrder: number;
  fileList: IFileInfo[];
  photoFileList: IFileInfo[];
  exhibitionType: string;
  useYn: string;
  regDt: string;
  regId: string;
  modDt: string;
  modId: string;
  delYn: string;
  delId: string;
  delDt: string;
}

interface exhibitionFilter {
  openStateFilter: String;
  searchValue: String;
  searchType: String;
}

interface ExhibitionState {
  exhibitionList: Array<IExhibitionInfo>;
  exhibitionInfo: IExhibitionInfo;
  exhibitionFilter: exhibitionFilter;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const exhibitionInitialState = {
  exhibitionList: [],
  exhibitionInfo: {},
  exhibitionFilter: { openStateFilter: 'ALL', searchValue: '', searchType: 'NAME' },
  actionResult: '',
  isLoading: false,
  error: null,
};
const reducers = {
  stateClear: (state: ExhibitionState, { payload }: PayloadAction<ExhibitionState>) => {
    state.exhibitionList = [];
    state.exhibitionInfo = {};
    state.exhibitionFilter = { openStateFilter: 'ALL', searchValue: '', searchType: 'NAME' };
    state.actionResult = '';
    state.isLoading = false;
    state.error = null;
  },
  list: (state: ExhibitionState, { payload }: PayloadAction<{}>) => {
    state.actionResult = 'EXHIBITION_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  listSuccess: (state: ExhibitionState, { payload }: PayloadAction<ExhibitionState>) => {
    state.exhibitionList = payload.exhibitionList;
    state.actionResult = 'EXHIBITION_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  listFailure: (state: ExhibitionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'EXHIBITION_LIST_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  detail: (state: ExhibitionState, { payload }: PayloadAction<{}>) => {
    state.actionResult = 'EXHIBITION_DETAIL_REQ';
    state.isLoading = true;
    state.error = null;
  },
  detailSuccess: (state: ExhibitionState, { payload }: PayloadAction<ExhibitionState>) => {
    state.exhibitionInfo = payload.exhibitionInfo;
    state.actionResult = 'EXHIBITION_DETAIL_OK';
    state.isLoading = false;
    state.error = null;
  },
  detailFailure: (state: ExhibitionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'EXHIBITION_DETAIL_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  add: (state: ExhibitionState, { payload }: PayloadAction<{}>) => {
    state.actionResult = 'EXHIBITION_ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  addSuccess: (state: ExhibitionState, { payload }: PayloadAction<ExhibitionState>) => {
    state.exhibitionList = payload.exhibitionList;
    state.actionResult = 'EXHIBITION_ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  addFailure: (state: ExhibitionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'EXHIBITION_ADD_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  update: (state: ExhibitionState, { payload }: PayloadAction<{}>) => {
    state.actionResult = 'EXHIBITION_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  updateSuccess: (state: ExhibitionState, { payload }: PayloadAction<ExhibitionState>) => {
    state.exhibitionList = payload.exhibitionList;
    state.actionResult = 'EXHIBITION_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  updateFailure: (state: ExhibitionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'EXHIBITION_UPDATE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  orderUpdate: (state: ExhibitionState, { payload }: PayloadAction<{}>) => {
    state.actionResult = 'EXHIBITION_ORDER_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  orderUpdateSuccess: (state: ExhibitionState, { payload }: PayloadAction<ExhibitionState>) => {
    state.exhibitionList = payload.exhibitionList;
    state.actionResult = 'EXHIBITION_ORDER_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  orderUpdateFailure: (state: ExhibitionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'EXHIBITION_ORDER_UPDATE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  remove: (state: ExhibitionState, { payload }: PayloadAction<{}>) => {
    state.actionResult = 'EXHIBITION_REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: ExhibitionState, { payload }: PayloadAction<ExhibitionState>) => {
    state.exhibitionList = payload.exhibitionList;
    state.actionResult = 'EXHIBITION_REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: ExhibitionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'EXHIBITION_REMOVE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  exhibitionFilter: (state: ExhibitionState, { payload }: PayloadAction<ExhibitionFilterPayload>) => {
    state.exhibitionFilter = payload;
  },
  actionResultClear: (state: ExhibitionState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'exhibition',
  initialState: exhibitionInitialState,
  reducers: reducers,
});

const selectExhibitionList = createDraftSafeSelector(
  (state: ExhibitionState) => state.exhibitionList,
  exhibitionList => exhibitionList,
);

const selectExhibitionFilterList = createDraftSafeSelector(
  (state: ExhibitionState) => state.exhibitionList,
  (state: ExhibitionState) => state.exhibitionFilter,
  (exhibitionList, exhibitionFilter) => {
    const today = dayjs().format('YYYY-MM-DD');

    let filterExhibitionList = exhibitionList.filter(exhibition => {
      let openState = 'CURRENT';
      if (exhibition.exhibitionStartDt < today && exhibition.exhibitionEndDt < today) {
        openState = 'BEFORE';
      } else if (exhibition.exhibitionStartDt > today && exhibition.exhibitionEndDt > today) {
        openState = 'AFTER';
      }
      if (
        (exhibitionFilter.openStateFilter === 'ALL' || exhibitionFilter.openStateFilter === openState) &&
        (exhibitionFilter.searchValue === '' ||
          (exhibitionFilter.searchType === 'NAME' &&
            (exhibition.exhibitionNm?.toLowerCase().indexOf(exhibitionFilter.searchValue.toLowerCase()) >= 0 ||
              exhibition.exhibitionEngNm?.toLowerCase().indexOf(exhibitionFilter.searchValue.toLowerCase()) >= 0)) ||
          (exhibitionFilter.searchType === 'AUTHOR' &&
            (exhibition.authorNm?.toLowerCase().indexOf(exhibitionFilter.searchValue.toLowerCase()) >= 0 ||
              exhibition.authorEngNm?.toLowerCase().indexOf(exhibitionFilter.searchValue.toLowerCase()) >= 0)))
      ) {
        return true;
      } else {
        return false;
      }
    });

    return filterExhibitionList;
  },
);

const selectExhibitionInfo = createDraftSafeSelector(
  (state: ExhibitionState) => state.exhibitionInfo,
  exhibitionInfo => exhibitionInfo,
);

const selectStatus = createDraftSafeSelector(
  state => state.actionResult,
  state => state.isLoading,
  state => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const exhibitionSelector = {
  exhibitionList: state => selectExhibitionList(state[EXHIBITION]),
  exhibitionFilterList: state => selectExhibitionFilterList(state[EXHIBITION]),
  status: state => selectStatus(state[EXHIBITION]),
  exhibitionInfo: state => selectExhibitionInfo(state[EXHIBITION]),
};

export const EXHIBITION = slice.name;
export const exhibitionReducer = slice.reducer;
export const exhibitionAction = slice.actions;
