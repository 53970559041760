import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import { packageAction } from './packageSlice';
import { frameGroupAction } from './frameGroupSlice';
import { frameAction } from './frameSlice';
import { layerAction } from './layerSlice';
import { layerContentsAction } from './layerContentsSlice';

import * as packageApiLib from '../lib/packageApi';
import * as frameGroupApiLib from '../lib/frameGroupApi';
import * as frameApiLib from '../lib/frameApi';
import * as layerApiLib from '../lib/layerApi';
import * as layerContentsApiLib from '../lib/layerContentsApi';

function* list({ payload }) {
  try {
    const packageList = yield call(packageApiLib.list, payload);

    yield put(packageAction.listSuccess({ packageList }));
  } catch (error) {
    yield put(packageAction.listFailure({ error }));
  }
}
function* detail({ payload }) {
  try {

    let packageInfo = {};
    let frameGroupList = [];
    let frameList = [];
    let layerList = [];
    let layerContentsList = [];
    if (payload.packageId !== '') {
      packageInfo = yield call(packageApiLib.detail, payload);
      frameGroupList = yield call(frameGroupApiLib.list, { playlistId: payload.packageId });
      frameList = yield call(frameApiLib.list, { playlistId: payload.packageId });
      layerList = yield call(layerApiLib.list, { playlistId: payload.packageId });
      layerContentsList = yield call(layerContentsApiLib.list, { playlistId: payload.packageId });
    }

    yield put(frameGroupAction.listSuccess({frameGroupList}));
    yield put(frameAction.listSuccess({ frameList }));
    yield put(layerAction.listSuccess({ layerList }));
    yield put(layerContentsAction.listSuccess({ layerContentsList }));
    yield put(packageAction.detailSuccess({ packageInfo }));
  } catch (error) {
    yield put(packageAction.detailFailure({ error }));
  }
}

/*

function* add({ payload }) {
  try {
    yield call(packageApiLib.add, payload);

    yield put(packageAction.addSuccess());
  } catch (error) {
    yield put(packageAction.addFailure({ error }));
  }
}



function* edit({ payload }) {
  try {
    yield call(packageApiLib.edit, payload);

    yield put(packageAction.editSuccess());
  } catch (error) {
    yield put(packageAction.editFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    yield call(packageApiLib.remove, payload);

    yield put(packageAction.removeSuccess());
  } catch (error) {
    yield put(packageAction.removeFailure({ error }));
  }
}

*/

export function* watchList() {
  yield takeLatest(packageAction.list, list);
}

export function* watchDetail() {
  yield takeLatest(packageAction.detail, detail);
}

/*

export function* watchAdd() {
  yield takeLatest(packageAction.add, add);
}


export function* watchEdit() {
  yield takeLatest(packageAction.edit, edit);
}

export function* watchRemove() {
  yield takeLatest(packageAction.remove, remove);
}
*/

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchDetail),
    // fork(watchAdd),
    // fork(watchEdit),
    // fork(watchRemove),
  ]);
}

export default rootSaga;
