import * as apiLib from './api';

export const collection = async ({ collectionId }) => {
  try {
    const data = { collectionId: collectionId };

    const result = await apiLib.fetchArchiveApi('/mobile/collection', data);
    if (result.resultFlag) {
      return result.collection;
    } else {
      throw Object.assign(new Error('mobile collection error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const jampic = async ({ jampicId }) => {
  try {
    const data = { jampicId: jampicId };

    const result = await apiLib.fetchArchiveApi('/mobile/jampic', data);
    if (result.resultFlag) {
      return result.jampic;
    } else {
      throw Object.assign(new Error('mobile jampic error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
