import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import * as playlistApiLib from '../lib/playlistApi';
import * as frmaeGroupApiLib from '../lib/frameGroupApi';
import * as frameApiLib from '../lib/frameApi';
import * as layerApiLib from '../lib/layerApi';
import * as layerContentsApiLib from '../lib/layerContentsApi';
import * as storageApiLib from '../lib/storageApi';
import * as packageApiLib from '../lib/packageApi';
import * as deviceApiLib from '../lib/deviceApi';

import { packageAction } from './packageSlice';
import { playlistAction } from './playlistSlice';
import { frameGroupAction } from './frameGroupSlice';
import { frameAction } from './frameSlice';
import { layerAction } from './layerSlice';
import { layerContentsAction } from './layerContentsSlice';

function* list({ payload }) {
  try {
    const playlistList = yield call(playlistApiLib.list, payload);

    yield put(playlistAction.listSuccess({ playlistList }));
  } catch (error) {
    yield put(playlistAction.listFailure({ error }));
  }
}

function* detail({ payload }) {
  try {
    const playlistInfo = yield call(playlistApiLib.detail, payload);
    const packageInfo = yield call(packageApiLib.detail, { packageId: playlistInfo.packageId });
    const frameGroupList = yield call(frmaeGroupApiLib.list, { playlistId: payload.playlistId });
    const frameList = yield call(frameApiLib.list, { playlistId: payload.playlistId });
    const layerList = yield call(layerApiLib.list, { playlistId: payload.playlistId });
    const layerContentsList = yield call(layerContentsApiLib.list, { playlistId: payload.playlistId });

    yield put(frameGroupAction.listSuccess({ frameGroupList }));
    yield put(frameAction.listSuccess({ frameList }));
    yield put(layerAction.listSuccess({ layerList }));
    yield put(layerContentsAction.listSuccess({ layerContentsList }));
    yield put(packageAction.detailSuccess({ packageInfo }));
    yield put(playlistAction.detailSuccess({ playlistInfo }));
  } catch (error) {
    yield put(playlistAction.detailFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    const playlistFileInfo = payload?.playlistFileInfo?.fileList || [];
    const placeId = payload.placeId;
    const playlist = payload.playlist;

    const frameGroupList = payload.frameGroupList || [];
    const frameList = payload.frameList || [];

    const handleUploadProgress = payload.handleUploadProgress;

    const resultFileList = yield call(storageApiLib.upload, { fileList: playlistFileInfo, placeId, handleUploadProgress });

    yield call(playlistApiLib.add, { ...playlist, fileList: resultFileList });
    yield call(frmaeGroupApiLib.add, { frameGroupList: frameGroupList });
    yield call(frameApiLib.add, { frameList: frameList });
    yield call(layerApiLib.add, { layerList: payload.layerList });

    const layerContentsList = payload.layerContentsList;

    const alreadyUploadLogoFileIdList = [];
    const uploadResultLogoFileObj = {};
    for (const layerContents of layerContentsList) {
      if (layerContents.contentsType === 'archive' || layerContents.contentsType === 'photo-kiosk') {
        if (layerContents.contentsData.logoFile.fileId) {
          const logoFileId = layerContents.contentsData.logoFile.fileId;
          if (!alreadyUploadLogoFileIdList.find(fileId => fileId === logoFileId)) {
            const fileList = [layerContents.contentsData.logoFile];
            const resultLogoFileList = yield call(storageApiLib.upload, { fileList, placeId, handleUploadProgress });
            alreadyUploadLogoFileIdList.push(logoFileId);
            uploadResultLogoFileObj[logoFileId] = resultLogoFileList[0];
          }
          const contentsData = {
            ...layerContents.contentsData,
            logoFile: uploadResultLogoFileObj[logoFileId],
          };
          layerContents.contentsData = contentsData;
          layerContents.contentsFileList = [contentsData.logoFile];
        }
      }

      if (layerContents.contentsType === 'media') {
        const fileList = layerContents.contentsFileList;
        const resultFileList = yield call(storageApiLib.upload, { fileList, placeId, handleUploadProgress });
        layerContents.contentsFileList = resultFileList;
        const contentsData = {
          mediaList: layerContents.contentsData.mediaList.map((media, index) => ({
            ...media,
            fileId: resultFileList[index].fileId,
            filePath: resultFileList[index].filePath,
            thumbFilePath: resultFileList[index].thumbFilePath,
          })),
        };
        layerContents.contentsData = contentsData;
      }
    }

    yield call(layerContentsApiLib.add, { layerContentsList });

    yield put(playlistAction.addSuccess());
  } catch (error) {
    console.log('error', error);
    yield put(playlistAction.addFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    yield call(storageApiLib.remove, { filePathList: payload.removePlaylistFilePathList });

    const callList = payload.removeList.map(playlistId => {
      const callApi = call(playlistApiLib.remove, { playlistId });
      return callApi;
    });

    const layerContentFilePathList = yield all([...callList]); // 옵션 파일리스트
    const filePathList = layerContentFilePathList.flat();

    yield call(storageApiLib.remove, { filePathList });

    yield put(playlistAction.removeSuccess());
  } catch (error) {
    yield put(playlistAction.removeFailure({ error }));
  }
}

function* update({ payload }) {
  try {
    const addFileList = payload?.playlistFileInfo?.addFileList || [];
    const removeFileList = payload?.playlistFileInfo?.removeFileList || [];
    const fileList = payload?.playlistFileInfo?.fileList || [];
    const handleUploadProgress = payload.handleUploadProgress;

    const placeId = payload.placeId;
    const playlistId = payload.playlistId;
    const frameGroupList = payload.frameGroupList || [];
    const frameList = payload?.frameList || [];

    const resultFileList = yield call(storageApiLib.uploadAndRemove, {
      addFileList: addFileList,
      removeFileList: removeFileList,
      fileList: fileList,
      placeId: placeId,
      handleUploadProgress,
    });

    const updateInfo = { ...payload.playlist, fileList: resultFileList };
    yield call(playlistApiLib.update, {
      playlistId: playlistId,
      updateInfo: updateInfo,
    });

    yield call(frmaeGroupApiLib.updateList, {
      frameGroupList: frameGroupList,
    });

    yield call(frameApiLib.updateList, {
      frameList: frameList,
    });

    const layerContentsList = payload.layerContentsList;
    const originLogoFileList = payload.originLogoFileList; // 아카이브앱 원본 파일들
    const originMediaFileList = payload.originMediaFileList; // 미디어앱 원본 파일들

    const alreadyUploadLogoFileIdList = []; // 업로드한 로고파일아이디 담는 배열
    const uploadResultLogoFileObj = {}; // 업로드후 파일 객체
    let removeFilePathList = [...originMediaFileList]; // 삭제할 파일경로 담는 배열

    for (const layerContents of layerContentsList) {
      if (layerContents.contentsType === 'archive' || layerContents.contentsType === 'photo-kiosk') {
        if (layerContents.contentsData.logoFile.fileId) {
          // 로고가 있을때
          const logoFileId = layerContents.contentsData.logoFile.fileId;
          const findOriginLogoFile = originLogoFileList.find(originFile => originFile.fileId === logoFileId);
          if (findOriginLogoFile) {
            // 이미 업로드 되어있는 파일
            alreadyUploadLogoFileIdList.push(logoFileId);
            uploadResultLogoFileObj[logoFileId] = findOriginLogoFile;
          } else {
            // 새로 등록하는 파일
            if (!alreadyUploadLogoFileIdList.find(fileId => fileId === logoFileId)) {
              const fileList = [layerContents.contentsData.logoFile];
              const resultLogoFileList = yield call(storageApiLib.upload, { fileList, placeId, handleUploadProgress });

              alreadyUploadLogoFileIdList.push(logoFileId);
              uploadResultLogoFileObj[logoFileId] = resultLogoFileList[0];
            }
          }

          const contentsData = {
            ...layerContents.contentsData,
            logoFile: uploadResultLogoFileObj[logoFileId],
          };
          layerContents.contentsData = contentsData;
          layerContents.contentsFileList = [contentsData.logoFile];
        }
      }

      if (layerContents.contentsType === 'media') {
        const fileList = layerContents.contentsFileList;
        const addFileList = fileList.filter(file => !originMediaFileList.find(originFile => originFile.fileId === file.fileId));

        removeFilePathList = removeFilePathList.filter(originFile => !fileList.find(file => file.fileId === originFile.fileId));

        if (addFileList.length) {
          const resultFileList = yield call(storageApiLib.upload, {
            fileList: addFileList,
            placeId,
            handleUploadProgress,
          });
          layerContents.contentsFileList = resultFileList;
          const contentsData = {
            mediaList: layerContents.contentsData.mediaList.map((media, index) => ({
              ...media,
              fileId: resultFileList[index].fileId,
              filePath: resultFileList[index].filePath,
              thumbFilePath: resultFileList[index].thumbFilePath,
            })),
          };
          layerContents.contentsData = contentsData;
        }

        // const removeFileList = originMediaFileList.filter(originFile => !fileList.find(file => file.fileId === originFile.fileId));
        // const resultFileList = yield call(storageApiLib.uploadAndRemove, {
        //   fileList,
        //   addFileList,
        //   removeFileList,
        //   placeId,
        //   handleUploadProgress,
        // });
      }
    }
    yield call(layerContentsApiLib.update, { layerContentsList });

    // 아카이브 or 포토키오스크 로고파일 삭제 로직
    const removeArchiveFileList = originLogoFileList.filter(
      originFile => !alreadyUploadLogoFileIdList.find(fileId => fileId === originFile.fileId),
    );
    let removeArchiveFilePathList = [];
    for (const removeArchiveFile of removeArchiveFileList) {
      removeArchiveFilePathList.push(removeArchiveFile.filePath);

      if (removeArchiveFile.thumbFilePath) {
        removeArchiveFilePathList.push(removeArchiveFile.thumbFilePath);
      }
    }

    removeArchiveFilePathList = Array.from(new Set(removeArchiveFilePathList));
    yield call(storageApiLib.remove, { filePathList: removeArchiveFilePathList });

    // 미디어 파일 삭제 로직
    if (removeFilePathList.length) {
      yield call(storageApiLib.remove, {
        filePathList: removeFilePathList
          .map(file => {
            if (file.thumbFilePath) {
              return [file.filePath, file.thumbFilePath];
            } else {
              return [file.filePath];
            }
          })
          .flat(),
      });
    }

    if (payload.isPlay) {
      const deviceList = yield call(deviceApiLib.list, { placeId });

      const filterDeviceList = deviceList.filter(device => device.playlistId === playlistId);

      let deviceIdList = [];

      for (const device of filterDeviceList) {
        deviceIdList.push(device.deviceId);
      }
      yield call(deviceApiLib.play, { playlistId, deviceIdList: deviceIdList });
    }

    yield put(playlistAction.updateSuccess());
  } catch (error) {
    console.log('error', error);
    yield put(playlistAction.updateFailure({ error }));
  }
}

export function* watchUpdate() {
  yield takeLatest(playlistAction.update, update);
}

export function* watchList() {
  yield takeLatest(playlistAction.list, list);
}

export function* watchDetail() {
  yield takeLatest(playlistAction.detail, detail);
}

export function* watchAdd() {
  yield takeLatest(playlistAction.add, add);
}

export function* watchRemove() {
  yield takeLatest(playlistAction.remove, remove);
}

function* rootSaga() {
  yield all([fork(watchList), fork(watchAdd), fork(watchRemove), fork(watchDetail), fork(watchUpdate)]);
}

export default rootSaga;
