import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface PlacePayload {
  placeId: string;
  placeNm: string;
  accountId: string;
  licenseSeat: string;
}

interface ImagePayload {
  placeId: string;
  file: Array<any>;
}

interface PlaceInfo {
  placeId: string;
  placeNm: string;
}

interface AgentInfo {
  path: string;
  version: string;
}

interface PlaceState {
  placeList: Array<PlaceInfo>;
  placeInfo: PlaceInfo;
  agentInfo: AgentInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const placeInitialState: PlaceState = {
  placeInfo: {},
  placeList: [],
  placeAliasList: [],
  agentInfo: {},
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  list: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  listSuccess: (state: PlaceState, { payload }: PayloadAction<PlaceState>) => {
    state.placeList = payload.placeList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  listFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  aliasList: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'ALIAS_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  aliasListSuccess: (state: PlaceState, { payload }: PayloadAction<PlaceState>) => {
    state.placeAliasList = payload.placeAliasList;
    state.actionResult = 'ALIAS_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  aliasListFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'ALIAS_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  detail: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'DETAIL_REQ';
    state.isLoading = true;
    state.error = null;
  },
  detailSuccess: (state: PlaceState, { payload }: PayloadAction<PlaceState>) => {
    state.placeInfo = payload.placeInfo;
    state.actionResult = 'DETAIL_OK';
    state.isLoading = false;
    state.error = null;
  },
  detailFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  add: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  addSuccess: (state: PlaceState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  addFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  edit: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  editSuccess: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.placeInfo.placeNm = payload.placeNm;
    for (let place of state.placeList) {
      if (place.placeId === payload.placeId) {
        place.placeNm = payload.placeNm;
      }
    }
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = null;
  },
  editFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  remove: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: PlaceState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  imageUpload: (state: PlaceState, { payload }: PayloadAction<ImagePayload>) => {
    state.actionResult = 'UPLOAD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  imageUploadSuccess: (state: PlaceState, { payload }: PayloadAction<PlaceState>) => {
    state.placeInfo = payload.placeInfo;
    state.actionResult = 'UPLOAD_OK';
    state.isLoading = false;
    state.error = null;
  },
  imageUploadFailure: (state: PlaceState, { payload }: PayloadAction<String>) => {
    state.actionResult = 'UPLOAD_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  agentInfo: (state: PlaceState) => {
    state.actionResult = 'AGENT_INFO_REQ';
    state.isLoading = true;
    state.error = null;
  },
  agentInfoSuccess: (state: PlaceState, { payload }: PayloadAction<PlacePayload>) => {
    state.agentInfo = payload.agentInfo;
    state.actionResult = 'AGENT_INFO_OK';
    state.isLoading = false;
    state.error = null;
  },
  agentInfoFailure: (state: PlaceState, action: PayloadAction<string>) => {
    state.actionResult = 'AGENT_INFO_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'place',
  initialState: placeInitialState,
  reducers: reducers,
});

const selectPlaceList = createDraftSafeSelector(
  (state: PlaceState) => state.placeList,
  placeList => placeList,
);

const selectPlaceAliasList = createDraftSafeSelector(
  (state: PlaceState) => state.placeAliasList,
  placeAliasList => placeAliasList,
);

const selectPlaceInfo = createDraftSafeSelector(
  (state: PlaceState) => state.placeInfo,
  placeInfo => placeInfo,
);

const selectAgentInfo = createDraftSafeSelector(
  (state: PlaceState) => state.agentInfo,
  agentInfo => agentInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: PlaceState) => state.actionResult,
  (state: PlaceState) => state.isLoading,
  (state: PlaceState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const placeSelector = {
  placeList: state => selectPlaceList(state[PLACE]),
  placeAliasList: state => selectPlaceAliasList(state[PLACE]),
  placeInfo: state => selectPlaceInfo(state[PLACE]),
  agentInfo: state => selectAgentInfo(state[PLACE]),
  status: state => selectStatus(state[PLACE]),
};

export const PLACE = slice.name;
export const placeReducer = slice.reducer;
export const placeAction = slice.actions;
