import * as apiLib from './api';

export const list = async payload => {
  try {
    let magazine = [];

    let searchFlag = true;

    let data = {
      placeId: payload.placeId,
      limit: 100,
      LastEvaluatedKey: null,
    };

    while (searchFlag) {
      const result = await apiLib.fetchArchiveApi('/magazine/limit-list', data);
      magazine = magazine.concat(result.magazineList);
      if (result.LastEvaluatedKey === undefined) {
        searchFlag = false;
      }
      data.LastEvaluatedKey = result.LastEvaluatedKey;
    }

    const sortHistoryList = magazine.sort(function (lhs, rhs) {
      if (lhs.magazineOrder && rhs.magazineOrder) {
        return lhs.magazineOrder - rhs.magazineOrder;
      }
      return 0;
    });

    return sortHistoryList;
  } catch (err) {
    throw err;
  }
};


export const detail = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/magazine/detail', data);
    if (result.resultFlag) {
      return result.magazineInfo;
    } else {
      throw Object.assign(new Error('magazine detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/magazine/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('magazine add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/magazine/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('magazine edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/magazine/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('magazine remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
