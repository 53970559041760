import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface GalleryInfo {
  jampicId: string;
  deviceId?: string;
  filePath?: any[];
  photoId?: string;
  photoNm?: string;
  placeId?: string;
  regDt?: string;
  ttl?: string;
}

interface GalleryState {
  galleryList: GalleryInfo[];
  galleryInfo: GalleryInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: GalleryState = {
  galleryList: [],
  galleryInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: GalleryState, { payload }: PayloadAction<GalleryState>) => {
    state.galleryList = [];
    state.galleryInfo = {};
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },
  list: (state: GalleryState, { payload }: PayloadAction<GalleryState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: GalleryState, { payload }: PayloadAction<GalleryState>) => {
    state.galleryList = payload.galleryList.sort(function (a, b) {
      a = new Date(a.regDt);
      b = new Date(b.regDt);
      return a > b ? -1 : a < b ? 1 : 0;
    });
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: GalleryState, { payload }: PayloadAction<GalleryState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  remove: (state: GalleryState, { payload }: PayloadAction<GalleryState>) => {
    state.isLoading = true;
    state.actionResult = 'GALLERY_REMOVE_REQ';
    state.error = null;
  },
  removeSuccess: (state: GalleryState, { payload }: PayloadAction<GalleryState>) => {
    //state.galleryList = payload.galleryList;
    state.isLoading = false;
    state.actionResult = 'GALLERY_REMOVE_OK';
    state.error = null;
  },
  removeFailure: (state: GalleryState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'GALLERY_REMOVE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  actionResultClear: (state: GalleryState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'gallery',
  initialState: InitialState,
  reducers: reducers,
});

const selectjampicList = createDraftSafeSelector(
  (state: GalleryState) => state.galleryList,
  galleryList => galleryList,
);

const selectjampicInfo = createDraftSafeSelector(
  (state: GalleryState) => state.galleryInfo,
  galleryInfo => galleryInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: GalleryState) => state.actionResult,
  (state: GalleryState) => state.isLoading,
  (state: GalleryState) => state.error,
  (actionResult, isLoading, error) => ({
    actionResult,
    isLoading,
    error,
  }),
);

export const gallerySelector = {
  galleryList: state => selectjampicList(state[GALLERY]),
  galleryInfo: state => selectjampicInfo(state[GALLERY]),
  status: state => selectStatus(state[GALLERY]),
};

export const GALLERY = slice.name;
export const galleryReducer = slice.reducer;
export const galleryAction = slice.actions;
