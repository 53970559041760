import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { curationAction } from './curationSlice';

import * as collectionApiLib from '../lib/collectionApi';
import * as curationApiLib from '../lib/curationApi';

function* list({ payload }) {
  try {
    const curationList = yield call(curationApiLib.list, payload);

    yield put(curationAction.listSuccess({ curationList }));
  } catch (err) {
    yield put(curationAction.listFailure('curation List Error'));
  }
}

function* detail({ payload }) {
  try {
    const curationInfo = yield call(curationApiLib.detail, payload);

    yield put(curationAction.detailSuccess({ curationInfo }));
  } catch (error) {
    yield put(curationAction.detailFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    yield call(curationApiLib.add, payload);

    const curationList = yield call(curationApiLib.list, { placeId: payload.placeId });

    yield put(curationAction.addSuccess({ curationList }));
  } catch (err) {
    yield put(curationAction.addFailure('Curation Add Error'));
  }
}

function* update({ payload }) {
  try {
    const callList = payload.updateList.map(updateInfo => {
      const callApi = call(curationApiLib.update, updateInfo);
      return callApi;
    });

    yield all([...callList]);
    // yield call(collectionApiLib.categoryUpdate, payload);

    const curationList = yield call(curationApiLib.list, { placeId: payload.placeId });

    yield put(curationAction.updateSuccess({ curationList }));
  } catch (err) {
    yield put(curationAction.updateFailure('curation Update Error'));
  }
}

function* remove({ payload }) {
  try {
    let curationList = yield call(curationApiLib.list, { placeId: payload.placeId });
    let callList = [];

    for (const curationId of payload.removeList) {
      let unlinkCallList = [];
      const collectionList = curationList.find(curaiton => curaiton.curationId === curationId)?.collectionList;

      let lastIndex = Math.ceil(collectionList.length / 50);

      for (let i = 0; i < lastIndex; i++) {
        unlinkCallList.push(
          call(collectionApiLib.unlinkCuration, { curationId: curationId, collectionList: collectionList.slice(50 * i, 50 * (i + 1)) }),
        );
      }
      // yield call(collectionApiLib.unlinkCuration, { curationId: curationId, collectionList: collectionList });

      yield all([...unlinkCallList]);
      callList.push(call(curationApiLib.remove, { curationId }));
    }
    yield all([...callList]);

    curationList = yield call(curationApiLib.list, { placeId: payload.placeId });

    yield put(curationAction.removeSuccess({ curationList }));

    let updateIndex = [];

    for (let index = 0; index < curationList.length; index++) {
      if (curationList[index].curationOrder !== index + 1) {
        updateIndex.push({
          curationId: curationList[index].curationId,
          updateInfo: {
            curationOrder: index + 1,
          },
        });
      }
    }

    yield put(curationAction.update({ updateList: updateIndex, placeId: payload.placeId }));
  } catch (err) {
    yield put(curationAction.categoryRemoveFailure({ error: err }));
  }
}

export function* watchCurationList() {
  yield takeLatest(curationAction.list, list);
}

export function* watchCurationDetail() {
  yield takeLatest(curationAction.detail, detail);
}

export function* watchCurationAdd() {
  yield takeLatest(curationAction.add, add);
}

export function* watchCurationUpdate() {
  yield takeLatest(curationAction.update, update);
}

export function* watchCurationRemove() {
  yield takeLatest(curationAction.remove, remove);
}

function* rootSaga() {
  yield all([
    fork(watchCurationList),
    fork(watchCurationDetail),
    fork(watchCurationAdd),
    fork(watchCurationUpdate),
    fork(watchCurationRemove),
  ]);
}

export default rootSaga;
