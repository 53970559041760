import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IplaylistInfo {
  playlistId: string;
}

interface playlistState {
  playlistList: IplaylistInfo[];
  playlistInfo: IplaylistInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: playlistState = {
  playlistList: [],
  playlistInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: playlistState, { payload }: PayloadAction<playlistState>) => {
    state.playlistList = [];
    state.playlistInfo = {};
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },

  list: (state: playlistState, { payload }: PayloadAction<playlistState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: playlistState, { payload }: PayloadAction<playlistState>) => {
    state.playlistList = payload.playlistList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: playlistState, { payload }: PayloadAction<playlistState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  detail: (state: playlistState, { payload }: PayloadAction<playlistState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  detailSuccess: (state: playlistState, { payload }: PayloadAction<playlistState>) => {
    state.playlistInfo = payload.playlistInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  detailFailure: (state: playlistState, { payload }: PayloadAction<playlistState>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = payload.error;
  },
  add: (state: playlistState, { payload }: PayloadAction<CsutomerplaylistState>) => {
    state.isLoading = true;
    state.actionResult = 'PLAYLIST_ADD_REQ';
    state.error = '';
  },
  addSuccess: (state: playlistState, { payload }: PayloadAction<playlistState>) => {
    state.isLoading = false;
    state.actionResult = 'PLAYLIST_ADD_OK';
    state.error = '';
  },
  addFailure: (state: playlistState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'PLAYLIST_ADD_ERR';
    state.error = action.payload;
  },
  remove: (state: playlistState, { payload }: PayloadAction<CsutomerplaylistState>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: playlistState, { payload }: PayloadAction<playlistState>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: playlistState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = action.payload;
  },

  update: (state: playlistState, { payload }: PayloadAction<CsutomerplaylistState>) => {
    state.isLoading = true;
    state.actionResult = 'UPDATE_REQ';
    state.error = '';
  },
  updateSuccess: (state: playlistState, { payload }: PayloadAction<playlistState>) => {
    state.isLoading = false;
    state.actionResult = 'UPDATE_OK';
    state.error = '';
  },
  updateFailure: (state: playlistState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'UPDATE_ERR';
    state.error = action.payload;
  },
  actionResultClear: (state: playlistState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'playlist',
  initialState: InitialState,
  reducers: reducers,
});

const selectplaylistList = createDraftSafeSelector(
  (state: playlistState) => state.playlistList,
  (playlistList) => playlistList
);

const selectplaylistInfo = createDraftSafeSelector(
  (state: playlistState) => state.playlistInfo,
  playlistInfo => playlistInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: playlistState) => state.isLogin,
  (state: playlistState) => state.actionResult,
  (state: playlistState) => state.isLoading,
  (state: playlistState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const playlistSelector = {
  playlistList: state => selectplaylistList(state[PLAYLIST]),
  playlistInfo: state => selectplaylistInfo(state[PLAYLIST]),
  status: state => selectStatus(state[PLAYLIST]),
};

export const PLAYLIST = slice.name;
export const playlistReducer = slice.reducer;
export const playlistAction = slice.actions;
