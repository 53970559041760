import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { frameGroupAction } from './frameGroupSlice';

import * as frameGroupApiLib from '../lib/frameGroupApi';

/*
function* list({ payload }) {
  try {
    const frameGroupList = yield call(frameGroupApiLib.list, payload);

    yield put(frameGroupAction.listSuccess({ frameGroupList }));
  } catch (error) {
    yield put(frameGroupAction.listFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    yield call(frameGroupApiLib.add, payload);

    yield put(frameGroupAction.addSuccess());
  } catch (error) {
    yield put(frameGroupAction.addFailure({ error }));
  }
}

function* detail({ payload }) {
  try {
    const frameGroupInfo = yield call(frameGroupApiLib.detail, payload);

    yield put(frameGroupAction.detailSuccess({ frameGroupInfo }));
  } catch (error) {
    yield put(frameGroupAction.detailFailure({ error }));
  }
}

function* edit({ payload }) {
  try {
    yield call(frameGroupApiLib.edit, payload);

    yield put(frameGroupAction.editSuccess());
  } catch (error) {
    yield put(frameGroupAction.editFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    yield call(frameGroupApiLib.remove, payload);

    yield put(frameGroupAction.removeSuccess());
  } catch (error) {
    yield put(frameGroupAction.removeFailure({ error }));
  }
}

export function* watchList() {
  yield takeLatest(frameGroupAction.list, list);
}

export function* watchAdd() {
  yield takeLatest(frameGroupAction.add, add);
}

export function* watchDetail() {
  yield takeLatest(frameGroupAction.detail, detail);
}

export function* watchEdit() {
  yield takeLatest(frameGroupAction.edit, edit);
}

export function* watchRemove() {
  yield takeLatest(frameGroupAction.remove, remove);
}
*/

function* rootSaga() {
  yield all([
    // fork(watchList),
    // fork(watchAdd),
    // fork(watchDetail),
    // fork(watchEdit),
    // fork(watchRemove),
  ]);
}

export default rootSaga;
