import { createThumbnailFile, getDuration } from '../utils/file';
import * as apiLib from './api';

export const upload = async ({ fileList, placeId, handleUploadProgress }) => {
  try {
    const resultFileList = [];
    const uploadThumbFileList = [];
    const uploadFileList = [];

    for (let index = 0; index < fileList.length; index++) {
      const file = fileList[index];
      const fileDuration = await getDuration(file.fileData);
      const thumbFile = await createThumbnailFile(file.fileData);

      let thumbFileId = '';
      let thumbFilePath = '';
      let thumbFileSize = '';
      let thumbFileHash = '';
      if (thumbFile) {
        const thumbPresignPayload = {
          placeId,
          thumbFileNm: thumbFile.name,
          thumbFileType: thumbFile.type,
          thumbFileSize: thumbFile.size,
        };
        const { url, thumbFileInfo } = await apiLib.fetchArchiveApi('/storage/thumb-presign', thumbPresignPayload);

        uploadFileList.push({ url, file: thumbFile });

        // thumbFileHash = await getFileHash(thumbFile);
        thumbFileId = thumbFileInfo.thumbFileId;
        thumbFilePath = thumbFileInfo.thumbFilePath;
        thumbFileSize = thumbFileInfo.thumbFileSize;
      }

      // const fileHash = await getFileHash(file.fileData);
      const fileHash = '';
      const presignPayload = {
        placeId,
        fileNm: file.fileNm,
        fileSize: file.fileSize,
        fileType: file.fileType,
        fileOrder: file.fileOrder,
        fileHash,
        fileDuration,
        thumbFileId,
        thumbFilePath,
        thumbFileSize,
        thumbFileHash,
      };

      const { url, fileInfo } = await apiLib.fetchArchiveApi('/storage/presign', presignPayload);
      uploadFileList.push({ url, file: file.fileData, onUploadProgress: handleUploadProgress, id: fileInfo.fileId });

      resultFileList.push(fileInfo);
    }

    if (fileList.length > 0) {
      await Promise.all([
        ...uploadThumbFileList.map(upload => apiLib.fetchUploadApi(upload.url, upload.file)),
        ...uploadFileList.map(upload => apiLib.fetchUploadApi(upload.url, upload.file, upload.onUploadProgress, upload.id)),
      ]);
    }

    return resultFileList;
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ filePathList }) => {
  try {
    const data = {
      filePathList,
    };
    const result = await apiLib.fetchArchiveApi('/storage/remove', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw new Error('remove error');
    }
  } catch (err) {
    throw err;
  }
};

export const uploadAndRemove = async ({ fileList, addFileList, removeFileList, placeId, handleUploadProgress }) => {
  try {
    let returnFileList = fileList;

    if (removeFileList.length > 0) {
      const filePathList = [];
      for (const removeFile of removeFileList) {
        filePathList.push(removeFile.filePath);

        if (removeFile.thumbFilePath) {
          filePathList.push(removeFile.thumbFilePath);
        }
      }
      await remove({ filePathList });
    }

    if (addFileList.length > 0) {
      const resultFileList = await upload({ fileList: addFileList, placeId, handleUploadProgress });
      let i = 0;
      returnFileList = returnFileList.map(file => {
        if (addFileList.find(addFile => addFile.fileId === file.fileId)) {
          const returnFile = resultFileList[i];
          i += 1;
          return returnFile;
        } else {
          return file;
        }
      });
    }

    return returnFileList;
  } catch (err) {
    throw err;
  }
};

export const presign = async payload => {
  try {
    const data = {
      ...payload,
    };
    const result = await apiLib.fetchArchiveApi('/storage/presign', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw new Error('presign error');
    }
  } catch (err) {
    throw err;
  }
};

export const thumbPresign = async payload => {
  try {
    const data = {
      ...payload,
    };
    const result = await apiLib.fetchArchiveApi('/storage/thumb-presign', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw new Error('thumb presign error');
    }
  } catch (err) {
    throw err;
  }
};
