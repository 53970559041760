import * as apiLib from './api';

export const list = async payload => {
  try {
    let curationList = [];

    let searchFlag = true;

    let data = {
      limit: 100,
      LastEvaluatedKey: null,
      placeId: payload.placeId,
    };

    while (searchFlag) {
      const result = await apiLib.fetchArchiveApi('/curation/limit-list', data);
      curationList = curationList.concat(result.curationList);
      if (result.LastEvaluatedKey === undefined) {
        searchFlag = false;
      }
      data.LastEvaluatedKey = result.LastEvaluatedKey;
    }

    const sortCurationList = curationList.sort(function (lhs, rhs) {
      if (lhs.curationOrder && rhs.curationOrder) {
        return lhs.curationOrder - rhs.curationOrder;
      }
      return 0;
    });

    return sortCurationList;
  } catch (err) {
    throw err;
  }
};

export const detail = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/curation/detail', data);
    if (result.resultFlag) {
      return result.curationInfo;
    } else {
      throw Object.assign(new Error('curation detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/curation/add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Curation Add Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const update = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/curation/update', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('curation Update Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/curation/remove', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Curation Remove Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};

export const unlinkCuration = async ({ collectionId, relationCurationIdList }) => {
  try {
    const data = {
      collectionId,
      relationCurationIdList,
    };

    const result = await apiLib.fetchArchiveApi('/curation/unlink-collection', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('curation UnLink collection Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};
