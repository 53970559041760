import * as apiLib from './api';

export const list = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/frame-group/list', data);
    if (result.resultFlag) {
      return result.frameGroupList;
    } else {
      if (result.isNotPlaylist) {
        throw Object.assign(new Error('no playlist.'), { status: 401 });
      } else {
        throw Object.assign(new Error('getFrameGroupList error'), { status: 401 });
      }
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/frame-group/add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('addFrameGroup error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const updateList = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/frame-group/updateList', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('frame Group Update Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
