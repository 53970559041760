import * as apiLib from './api';

// export const list = async payload => {
//   try {
//     const data = {
//       ...payload,
//     };

//     const result = await apiLib.fetchArchiveApi('/collection/list', data);
//     if (result.resultFlag) {
//       return result.collectionList;
//     } else {
//       throw Object.assign(new Error('collection list error'), { status: 401 });
//     }
//   } catch (err) {
//     throw err;
//   }
// };

export const list = async payload => {
  try {
    let collectionList = [];

    let searchFlag = true;

    let data = {
      placeId: payload.placeId,
      limit: 100,
      LastEvaluatedKey: null,
    };

    while (searchFlag) {
      const result = await apiLib.fetchArchiveApi('/collection/limit-list', data);
      collectionList = collectionList.concat(result.collectionList);
      if (result.LastEvaluatedKey === undefined) {
        searchFlag = false;
      }
      data.LastEvaluatedKey = result.LastEvaluatedKey;
    }

    const sortCollectionList = collectionList.sort((lhs, rhs) => {
      return lhs.regDt - rhs.regDt});

    return sortCollectionList;
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/collection/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('collection add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/collection/detail', data);
    if (result.resultFlag) {
      return result.collectionInfo;
    } else {
      throw Object.assign(new Error('collection detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/collection/edit', data);
    if (result.resultFlag) {
      return result.collectionInfo;
    } else {
      throw Object.assign(new Error('collection edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/collection/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('collection remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const linkCollection = async ({ collectionId, relationCollectionIdList }) => {
  try {
    const data = {
      collectionId,
      relationCollectionIdList,
    };

    const result = await apiLib.fetchArchiveApi('/collection/link-collection', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Collection Link Collection Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};

export const unlinkCollection = async ({ collectionId, relationCollectionIdList }) => {
  try {
    const data = {
      collectionId,
      relationCollectionIdList,
    };

    const result = await apiLib.fetchArchiveApi('/collection/unlink-collection', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Collection UnLink Collection Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};

export const linkCuration = async ({curationId,collectionList }) => {
  try {
    const data = {
      collectionList,
      curationId,
    };

    const result = await apiLib.fetchArchiveApi('/collection/link-curation', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Collection Link Curation Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};


export const unlinkCuration = async ({ collectionList, curationId }) => {
  try {
    const data = {
      collectionList,
      curationId,
    };

    const result = await apiLib.fetchArchiveApi('/collection/unlink-curation', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Collection Link Curation Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};






/*
export const categoryList = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchArchiveApi('/category/list', data);

    if (result.resultFlag) {
      return result.categoryList;
    } else {
      throw Object.assign(new Error('Category List Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
*/

export const categoryList = async payload => {
  try {
    let categoryList = [];

    let searchFlag = true;

    let data = {
      limit: 100,
      LastEvaluatedKey: null,
      placeId:payload.placeId
    };

    while (searchFlag) {
      const result = await apiLib.fetchArchiveApi('/category/limit-list', data);
      categoryList = categoryList.concat(result.categoryList);
      if (result.LastEvaluatedKey === undefined) {
        searchFlag = false;
      }
      data.LastEvaluatedKey = result.LastEvaluatedKey;
    }

    const sortCategoryList = categoryList.sort(function (lhs, rhs) {
      if (lhs.categoryOrder && rhs.categoryOrder) {
        return lhs.categoryOrder - rhs.categoryOrder;
      }
      return 0;
    });

    return sortCategoryList;
  } catch (err) {
    throw err;
  }
};

export const categoryAdd = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/category/add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Category Add Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const categoryUpdate = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/category/update', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Category Update Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const categoryRemove = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/category/remove', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Category Remove Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};
