import { all, fork, put, call, takeLatest } from 'redux-saga/effects';

import { exhibitionAction } from './exhibitionSlice';

import * as exhibitionApiLib from '../lib/exhibitionApi';
import * as storageApiLib from '../lib/storageApi';

function* list({ payload }) {
  try {
    const exhibitionList = yield call(exhibitionApiLib.list, payload);

    yield put(exhibitionAction.listSuccess({ exhibitionList }));
  } catch (err) {
    yield put(exhibitionAction.listFailure('exhibition List Error'));
  }
}

function* detail({ payload }) {
  try {
    const exhibitionInfo = yield call(exhibitionApiLib.detail, payload);

    yield put(exhibitionAction.detailSuccess({ exhibitionInfo }));
  } catch (error) {
    yield put(exhibitionAction.detailFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    const fileList = payload.fileList;
    const photoFileList = payload.photoFileList;
    const placeId = payload.placeId;
    const handleUploadProgress = payload.handleUploadProgress;

    const resultFileList = yield call(storageApiLib.upload, { fileList, placeId, handleUploadProgress });
    const resultPhotoFileList = yield call(storageApiLib.upload, { fileList: photoFileList, placeId, handleUploadProgress });
    const exhibitionType = resultFileList.length > 0 ? 'PHOTO' : 'NORMAL';

    yield call(exhibitionApiLib.add, { ...payload, fileList: resultFileList, photoFileList: resultPhotoFileList, exhibitionType });

    const exhibitionList = yield call(exhibitionApiLib.list, { placeId: payload.placeId });

    yield put(exhibitionAction.addSuccess({ exhibitionList }));
  } catch (err) {
    yield put(exhibitionAction.addFailure('exhibition Add Error'));
  }
}

function* update({ payload }) {
  try {
    // const addFileList = payload?.addFileList || [];
    // const removeFileList = payload?.removeFileList || [];
    // const fileList = payload?.fileList || [];
    const placeId = payload.placeId;
    const fileListInfo = payload?.fileListInfo || { addFileList: [], removeFileList: [], fileList: [], placeId: placeId };
    const photoFileListInfo = payload?.photoFileListInfo || { addFileList: [], removeFileList: [], fileList: [], placeId: placeId };
    const handleUploadProgress = payload.handleUploadProgress;

    const resultFileList = yield call(storageApiLib.uploadAndRemove, { ...fileListInfo, handleUploadProgress });
    const resultPhotoFileList = yield call(storageApiLib.uploadAndRemove, { ...photoFileListInfo, handleUploadProgress });

    const exhibitionType = resultFileList.length > 0 ? 'PHOTO' : 'NORMAL';

    const callList = payload.updateList.map(update => {
      const updateInfo = { ...update.updateInfo, fileList: resultFileList, photoFileList: resultPhotoFileList, exhibitionType };

      const callApi = call(exhibitionApiLib.update, { exhibitionId: update.exhibitionId, updateInfo: updateInfo });
      return callApi;
    });

    yield all([...callList]);

    const exhibitionList = yield call(exhibitionApiLib.list, { placeId: payload.placeId });

    yield put(exhibitionAction.updateSuccess({ exhibitionList }));
  } catch (err) {
    yield put(exhibitionAction.updateFailure('exhibition Update Error'));
  }
}

function* orderUpdate({ payload }) {
  try {
    const callList = payload.updateList.map(updateInfo => {
      const callApi = call(exhibitionApiLib.update, updateInfo);
      return callApi;
    });

    yield all([...callList]);

    const exhibitionList = yield call(exhibitionApiLib.list, { placeId: payload.placeId });

    yield put(exhibitionAction.orderUpdateSuccess({ exhibitionList }));
  } catch (err) {
    yield put(exhibitionAction.orderUpdateFailure('exhibition Order Update Error'));
  }
}

function* remove({ payload }) {
  try {
    yield call(storageApiLib.remove, { filePathList: payload.removeFilePathList });

    let callList = payload.removeList.map(exhibitionId => {
      const callApi = call(exhibitionApiLib.remove, { exhibitionId });
      return callApi;
    });

    yield all([...callList]);

    let exhibitionList = yield call(exhibitionApiLib.list, { placeId: payload.placeId });

    yield put(exhibitionAction.removeSuccess({ exhibitionList }));

    let updateIndex = [];

    for (let index = 0; index < exhibitionList.length; index++) {
      if (exhibitionList[index].exhibitionOrder !== index + 1) {
        updateIndex.push({
          exhibitionId: exhibitionList[index].exhibitionId,
          updateInfo: {
            exhibitionOrder: index + 1,
          },
        });
      }
    }

    yield put(exhibitionAction.orderUpdate({ updateList: updateIndex, placeId: payload.placeId }));
  } catch (err) {
    yield put(exhibitionAction.categoryRemoveFailure({ error: err }));
  }
}

export function* watchExhibitionList() {
  yield takeLatest(exhibitionAction.list, list);
}

export function* watchExhibitionDetail() {
  yield takeLatest(exhibitionAction.detail, detail);
}

export function* watchExhibitionAdd() {
  yield takeLatest(exhibitionAction.add, add);
}

export function* watchExhibitionUpdate() {
  yield takeLatest(exhibitionAction.update, update);
}

export function* watchExhibitionOrderUpdate() {
  yield takeLatest(exhibitionAction.orderUpdate, orderUpdate);
}

export function* watchExhibitionRemove() {
  yield takeLatest(exhibitionAction.remove, remove);
}

function* rootSaga() {
  yield all([
    fork(watchExhibitionList),
    fork(watchExhibitionDetail),
    fork(watchExhibitionAdd),
    fork(watchExhibitionUpdate),
    fork(watchExhibitionOrderUpdate),
    fork(watchExhibitionRemove),
  ]);
}

export default rootSaga;
