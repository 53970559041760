import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IPhotoGroupInfo {
  groupId: string;
  placeId?: string;
  relationPhotoIdList?: any[];
  groupNm?: string;
  useYn?: string;
  regDt?: string;
  regId?: string;
  modDt?: string;
  modId?: string;
  delYn?: string;
  delId?: string;
}

interface PhotoGroupState {
  photoGroupList: IPhotoGroupInfo[];
  photoGroupInfo: IPhotoGroupInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: PhotoGroupState = {
  photoGroupList: [],
  photoGroupInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: PhotoGroupState, { payload }: PayloadAction<PhotoGroupState>) => {
    state.photoGroupList = [];
    state.photoGroupInfo = {};
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },
  list: (state: PhotoGroupState, { payload }: PayloadAction<PhotoGroupState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: PhotoGroupState, { payload }: PayloadAction<PhotoGroupState>) => {
    state.photoGroupList = payload.photoGroupList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: PhotoGroupState, { payload }: PayloadAction<PhotoGroupState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload;
  },
  add: (state: PhotoGroupState, { payload }: PayloadAction<{}>) => {
    state.isLoading = true;
    state.actionResult = 'ADD_REQ';
    state.error = '';
  },
  addSuccess: (state: PhotoGroupState, { payload }: PayloadAction<PhotoGroupState>) => {
    state.photoGroupList = payload.photoGroupList;
    state.isLoading = false;
    state.actionResult = 'ADD_OK';
    state.error = '';
  },
  addFailure: (state: PhotoGroupState, { payload }: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_ERR';
    state.error = payload;
  },
  update: (state: PhotoGroupState, { payload }: PayloadAction<CsutomerPhotoGroupState>) => {
    const groupId = payload.groupId;
    const photoGroupList = state.photoGroupList.map(photoGroup =>
      photoGroup.groupId === groupId ? { ...photoGroup, groupNm: payload.groupNm } : photoGroup,
    );

    state.photoGroupList = photoGroupList;
    state.isLoading = true;
    state.actionResult = 'UPDATE_REQ';
    state.error = '';
  },
  updateSuccess: (state: PhotoGroupState, { payload }: PayloadAction<PhotoGroupState>) => {
    state.photoGroupList = payload.photoGroupList;
    state.isLoading = false;
    state.actionResult = 'UPDATE_OK';
    state.error = '';
  },
  updateFailure: (state: PhotoGroupState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'UPDATE_ERR';
    state.error = action.payload;
  },
  remove: (state: PhotoGroupState, { payload }: PayloadAction<CsutomerPhotoGroupState>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: PhotoGroupState, { payload }: PayloadAction<PhotoGroupState>) => {
    state.photoGroupList = payload.photoGroupList;
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: PhotoGroupState, { payload }: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = payload;
  },

  /*
  list: (state: PhotoGroupState, { payload }: PayloadAction<PhotoGroupState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: PhotoGroupState, { payload }: PayloadAction<PhotoGroupState>) => {
    state.photoGroupList = payload.photoGroupList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: PhotoGroupState, { payload }: PayloadAction<PhotoGroupState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },




  */
  actionResultClear: (state: PhotoGroupState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'photoGroup',
  initialState: InitialState,
  reducers: reducers,
});

const selectphotoGroupList = createDraftSafeSelector(
  (state: PhotoGroupState) => state.photoGroupList,
  photoGroupList => photoGroupList,
);

const selectphotoGroupInfo = createDraftSafeSelector(
  (state: PhotoGroupState) => state.photoGroupInfo,
  photoGroupInfo => photoGroupInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: PhotoGroupState) => state.actionResult,
  (state: PhotoGroupState) => state.isLoading,
  (state: PhotoGroupState) => state.error,
  (actionResult, isLoading, error) => ({
    actionResult,
    isLoading,
    error,
  }),
);

export const photoGroupSelector = {
  photoGroupList: state => selectphotoGroupList(state[PHOTOGROUP]),
  photoGroupInfo: state => selectphotoGroupInfo(state[PHOTOGROUP]),
  status: state => selectStatus(state[PHOTOGROUP]),
};

export const PHOTOGROUP = slice.name;
export const photoGroupReducer = slice.reducer;
export const photoGroupAction = slice.actions;
