import * as apiLib from './api';

export const list = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/layer-contents/list', data);

    if (result.resultFlag) {
      return result.layerContentsList;
    } else {
      throw Object.assign(new Error('getLayerContentsList error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/layer-contents/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getSaveLayerContents error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const update = async payload => {
  try {
    const data = {
      ...payload,
    };
    const result = await apiLib.fetchArchiveApi('/layer-contents/update', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('getUpdateLayerContents error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
