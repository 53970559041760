import * as apiLib from './api';

export const list = async payload => {
  try {
    let exhibitionList = [];

    let searchFlag = true;

    let data = {
      limit: 100,
      LastEvaluatedKey: null,
      placeId: payload.placeId,
    };

    while (searchFlag) {
      const result = await apiLib.fetchArchiveApi('/exhibition/limit-list', data);
      exhibitionList = exhibitionList.concat(result.exhibitionList);
      if (result.LastEvaluatedKey === undefined) {
        searchFlag = false;
      }
      data.LastEvaluatedKey = result.LastEvaluatedKey;
    }

    const sortExhibitionList = exhibitionList.sort(function (lhs, rhs) {
      if (lhs.exhibitionOrder && rhs.exhibitionOrder) {
        return lhs.exhibitionOrder - rhs.exhibitionOrder;
      }
      return 0;
    });

    return sortExhibitionList;
  } catch (err) {
    throw err;
  }
};

export const detail = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/exhibition/detail', data);
    if (result.resultFlag) {
      return result.exhibitionInfo;
    } else {
      throw Object.assign(new Error('exhibition detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/exhibition/add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('exhibition Add Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const update = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/exhibition/update', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('exhibition Update Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/exhibition/remove', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('exhibition Remove Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};