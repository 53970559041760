import * as apiLib from './api';
import sortBy from 'lodash/sortBy';

export const list = async payload => {
  try {
    let director = [];

    let searchFlag = true;

    let data = {
      placeId: payload.placeId,
      limit: 100,
      LastEvaluatedKey: null,
    };

    while (searchFlag) {
      const result = await apiLib.fetchArchiveApi('/director/limit-list', data);
      director = director.concat(result.directorList);
      if (result.LastEvaluatedKey === undefined) {
        searchFlag = false;
      }
      data.LastEvaluatedKey = result.LastEvaluatedKey;
    }

    const sortDirectorList  = sortBy(director, 'regDt').reverse();

    return sortDirectorList;
  } catch (err) {
    throw err;
  }
};

export const detail = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/director/detail', data);
    if (result.resultFlag) {
      return result.directorInfo;
    } else {
      throw Object.assign(new Error('director detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/director/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('director add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/director/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('director edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchArchiveApi('/director/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('director remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
