import * as apiLib from './api';

export const list = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/package/list', data);
    if (result.resultFlag) {
      return result.packageList;
    } else {
      throw Object.assign(new Error('package list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async payload => {
  try {
    const data = {
      ...payload,
    };

    const { resultFlag, packageInfo } = await apiLib.fetchArchiveApi('/package/detail', data);
    if (resultFlag) {
      return packageInfo;
    } else {
      throw Object.assign(new Error('package detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
