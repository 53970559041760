import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { userAction } from './userSlice';

import * as userApiLib from '../lib/userApi';

function* list({ payload }) {
  try {
    const userList = yield call(userApiLib.list, payload);

    yield put(userAction.listSuccess({ userList }));
  } catch (error) {
    yield put(userAction.listFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    yield call(userApiLib.add, payload);

    yield put(userAction.addSuccess());
  } catch (error) {
    yield put(userAction.addFailure({ error }));
  }
}

function* detail({ payload }) {
  try {
    const userInfo = yield call(userApiLib.detail, payload);

    yield put(userAction.detailSuccess({ userInfo }));
  } catch (error) {
    yield put(userAction.detailFailure({ error }));
  }
}

function* edit({ payload }) {
  try {
    yield call(userApiLib.edit, payload);

    yield put(userAction.editSuccess());
  } catch (error) {
    yield put(userAction.editFailure({ error }));
  }
}

function* pwChange({ payload }) {
  try {
    yield call(userApiLib.pwChange, payload);

    yield put(userAction.pwChangeSuccess());
  } catch (error) {
    yield put(userAction.pwChangeFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    yield call(userApiLib.remove, payload);

    yield put(userAction.removeSuccess());
  } catch (error) {
    yield put(userAction.removeFailure({ error }));
  }
}

export function* watchList() {
  yield takeLatest(userAction.list, list);
}

export function* watchAdd() {
  yield takeLatest(userAction.add, add);
}

export function* watchDetail() {
  yield takeLatest(userAction.detail, detail);
}

export function* watchEdit() {
  yield takeLatest(userAction.edit, edit);
}

export function* watchPwChange() {
  yield takeLatest(userAction.pwChange, pwChange);
}

export function* watchRemove() {
  yield takeLatest(userAction.remove, remove);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchAdd),
    fork(watchDetail),
    fork(watchEdit),
    fork(watchPwChange),
    fork(watchRemove),
  ]);
}

export default rootSaga;
