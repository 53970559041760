import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IpackageInfo {
  packageId: string;
}

interface PackageFiilter {
  resolutionType: string;
}
interface packageState {
  packageList: IpackageInfo[];
  packageInfo: IpackageInfo;
  packageFilter: PackageFiilter;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: packageState = {
  packageList: [],
  packageInfo: {},
  packageFilter: { packageType: 'MUSEUM', resolutionType: 'UHD-2R' },
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: packageState, { payload }: PayloadAction<packageState>) => {
    state.packageList = [];
    state.packageInfo = {};
    state.actionResult = '';
    state.packageFilter = { packageType: 'MUSEUM', resolutionType: 'UHD-2R' };
    state.isLoading = false;
    state.error = '';
  },
  initPackageState(state: PackageInitState) {
    state.packageList = [];
    state.packageInfo = {};
    state.packageFilter = { packageType: 'MUSEUM', resolutionType: 'UHD-2R' };
    state.actionResult = '';
    state.isLoading = false;
    state.error = null;
  },

  list: (state: packageState, { payload }: PayloadAction<packageState>) => {
    if (payload.packageType === 'PHOTO-KIOSK') {
      state.packageFilter.packageType = 'PHOTO-KIOSK';
      state.packageFilter.resolutionType = '_FHD-2R2C';
    } else {
      state.packageFilter.packageType = 'MUSEUM';
      state.packageFilter.resolutionType = 'UHD-2R';
    }
    state.isLoading = true;
    state.actionResult = 'PACKAGE_LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: packageState, { payload }: PayloadAction<packageState>) => {
    state.packageList = payload.packageList;
    state.isLoading = false;
    state.actionResult = 'PACKAGE_LIST_OK';
    state.error = '';
  },
  listFailure: (state: packageState, { payload }: PayloadAction<packageState>) => {
    state.isLoading = false;
    state.actionResult = 'PACKAGE_LIST_ERR';
    state.error = payload.error;
  },
  detail: (state: packageState, { payload }: PayloadAction<packageState>) => {
    state.isLoading = true;
    state.actionResult = 'PACKAGE_DETAIL_REQ';
    state.error = '';
  },
  detailSuccess: (state: packageState, { payload }: PayloadAction<packageState>) => {
    state.packageInfo = payload.packageInfo;
    state.isLoading = false;
    state.actionResult = 'PACKAGE_DETAIL_OK';
    state.error = '';
  },
  detailFailure: (state: packageState, { payload }: PayloadAction<packageState>) => {
    state.isLoading = false;
    state.actionResult = 'PACKAGE_DETAIL_ERR';
    state.error = payload.error;
  },

  /*

  
  add: (state: packageState, { payload }: PayloadAction<CsutomerpackageState>) => {
    state.isLoading = true;
    state.actionResult = 'ADD_REQ';
    state.error = '';
  },
  addSuccess: (state: packageState, { payload }: PayloadAction<packageState>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_OK';
    state.error = '';
  },
  addFailure: (state: packageState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_ERR';
    state.error = action.payload;
  },
  edit: (state: packageState, { payload }: PayloadAction<CsutomerpackageState>) => {
    state.isLoading = true;
    state.actionResult = 'EDIT_REQ';
    state.error = '';
  },
  editSuccess: (state: packageState, { payload }: PayloadAction<packageState>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_OK';
    state.error = '';
  },
  editFailure: (state: packageState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_ERR';
    state.error = action.payload;
  },
  remove: (state: packageState, { payload }: PayloadAction<CsutomerpackageState>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: packageState, { payload }: PayloadAction<packageState>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: packageState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = action.payload;
  },
  */
  packageFilter: (state: packageState, { payload }: PayloadAction<PackageFiilter>) => {
    state.packageFilter = payload;
    state.actionResult = 'FILTER_UPDATE';
  },
  actionResultClear: (state: packageState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'package',
  initialState: InitialState,
  reducers: reducers,
});

const selectPackageList = createDraftSafeSelector(
  (state: packageState) => state.packageList,
  packageList => packageList,
);

const selectPackageFilterList = createDraftSafeSelector(
  (state: packageState) => state.packageList,
  (state: packageState) => state.packageFilter,
  (packageList, packageFilter) => {
    const URL = window.location.href;
    const devFlag = URL.indexOf('localhost') > 0 || URL.indexOf('eligaws') > 0;

    // const filterList = packageList.filter(
    //   packageInfo =>
    //     packageInfo.resolutionType === packageFilter.resolutionType &&
    //     packageInfo.packageType === packageFilter.packageType &&
    //     (!devFlag || packageInfo.devYn === 'N'),
    // );

    //devYn = 'Y' : 개발버전
    //devYn = 'N' : OP버전

    const filterList = packageList.filter(
      packageInfo =>
        packageInfo.resolutionType === packageFilter.resolutionType &&
        packageInfo.packageType === packageFilter.packageType &&
        (devFlag ? true : packageInfo.devYn === 'N'),
    );

    return filterList;
  },
);

const selectPackageInfo = createDraftSafeSelector(
  (state: packageState) => state.packageInfo,
  packageInfo => packageInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: packageState) => state.isLogin,
  (state: packageState) => state.actionResult,
  (state: packageState) => state.isLoading,
  (state: packageState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const packageSelector = {
  packageList: state => selectPackageList(state[PACKAGE]),
  packageFilterList: state => selectPackageFilterList(state[PACKAGE]),
  packageInfo: state => selectPackageInfo(state[PACKAGE]),
  status: state => selectStatus(state[PACKAGE]),
};

export const PACKAGE = slice.name;
export const packageReducer = slice.reducer;
export const packageAction = slice.actions;
