import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { spaceAction } from './spaceSlice';

import * as spaceApiLib from '../lib/spaceApi';

function* list({ payload }) {
  try {
    const spaceList = yield call(spaceApiLib.list, payload);

    yield put(spaceAction.listSuccess({ spaceList }));
  } catch (err) {
    yield put(spaceAction.listFailure('List Error'));
  }
}

export function* watchList() {
  yield takeLatest(spaceAction.list, list);
}

function* rootSaga() {
  yield all([
    fork(watchList),
  ]);
}

export default rootSaga;
