import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface PackPayload {
  placeId: string;
  groupId: string;
  packId: string;
}

interface FilterInfo {
  groupId: string;
  packNm: string;
}

interface PageInfo {
  rowsPerPage: number;
  curPage: number;
}

interface PackInfo {
  placeId: string;
  packId: string;
  packNm: string;
}

interface PackState {
  filterInfo: FilterInfo;
  pageInfo: PageInfo;
  packInfo: PackInfo;
  packList: Array<PackInfo>;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const packInitialState: PackState = {
  filterInfo: {},
  pageInfo: { rowsPerPage: 10, curPage: 1 },
  packInfo: {},
  packList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  initPackList: (
    state: PackState,
    { payload }: PayloadAction<PackPayload>,
  ) => {
    state.filterInfo = {};
    state.pageInfo = { rowsPerPage: 10, curPage: 1 };
    state.packList = [];
    state.actionResult = '';
  },
  packFilter(state: PackState, { payload }: PayloadAction<PackPayload>) {
    state.filterInfo.groupId = payload.groupId;
    state.filterInfo.packNm = payload.packNm;
  },
  packPage: (
    state: PackState,
    { payload }: PayloadAction<PackPayload>,
  ) => {
    state.pageInfo.rowsPerPage = payload.rowsPerPage;
    state.pageInfo.curPage = payload.curPage;
  },
  packList: (
    state: PackState,
    { payload }: PayloadAction<PackPayload>,
  ) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  packListSuccess: (
    state: PackState,
    { payload }: PayloadAction<PackState>,
  ) => {
    state.packList = payload.packList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  packListFailure: (state: PackState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  packInfo: (
    state: PackState,
    { payload }: PayloadAction<PackPayload>,
  ) => {
    state.actionResult = 'INFO_REQ';
    state.isLoading = true;
    state.error = null;
  },
  packInfoSuccess: (
    state: PackState,
    { payload }: PayloadAction<PackState>,
  ) => {
    state.packInfo = payload.packInfo;
    state.actionResult = 'INFO_OK';
    state.isLoading = false;
    state.error = null;
  },
  packInfoFailure: (state: PackState, action: PayloadAction<string>) => {
    state.actionResult = 'INFO_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  packInfoClear: (state: PackState) => {
    state.packInfo = {};
  },
  packAdd: (
    state: PackState,
    { payload }: PayloadAction<PackPayload>,
  ) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  packAddSuccess: (state: PackState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  packAddFailure: (state: PackState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  packEdit: (
    state: PackState,
    { payload }: PayloadAction<PackPayload>,
  ) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  packEditSuccess: (
    state: PackState,
    { payload }: PayloadAction<PackState>,
  ) => {
    state.packInfo = {
      ...state.packInfo,
      ...payload.packInfo,
    };
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = null;
  },
  packEditFailure: (state: PackState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  packRemove: (
    state: PackState,
    { payload }: PayloadAction<PackPayload>,
  ) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  packRemoveSuccess: (state: PackState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  packRemoveFailure: (state: PackState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  groupMove(state: PackState, { payload }: PayloadAction<PackPayload>) {
    state.actionResult = 'GROUP_MOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  groupMoveSuccess(state: PackState) {
    state.actionResult = 'GROUP_MOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  groupMoveFailure(state: PackState, { payload }: PayloadAction<string>) {
    state.actionResult = 'GROUP_MOVE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  actionResultClear: (state: PackState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'pack',
  initialState: packInitialState,
  reducers: reducers,
});

const selectFilterInfo = createDraftSafeSelector(
  (state: BoardState) => state.filterInfo,
  filterInfo => filterInfo,
);

const selectPageInfo = createDraftSafeSelector(
  (state: BoardState) => state.pageInfo,
  pageInfo => {
    return {
      rowsPerPage: pageInfo.rowsPerPage,
      curPage: pageInfo.curPage,
    };
  },
);

const selectPackInfo = createDraftSafeSelector(
  (state: PackState) => state.packInfo,
  packInfo => packInfo,
);

const selectPackList = createDraftSafeSelector(
  (state: PackState) => state.filterInfo,
  (state: PackState) => state.packList,
  (filterInfo, packList) => {
    if (
      (!filterInfo.groupId || filterInfo.groupId.trim() === '') &&
      (!filterInfo.packNm || filterInfo.packNm.trim() === '')
    ) {
      return packList;
    }

    return packList.filter(pack => {
      let groupFilter = true;
      let packFilter = true;

      if (filterInfo.groupId && filterInfo.groupId !== '') {
        groupFilter = pack.groupId === filterInfo.groupId;
      }

      if (filterInfo.packNm && filterInfo.packNm !== '') {
        packFilter = pack.packNm.indexOf(filterInfo.packNm) >= 0;
      }

      return groupFilter && packFilter;
    });
  },
);

const selectPagePackList = createDraftSafeSelector(
  (state: PackState) => state.filterInfo,
  (state: PackState) => state.pageInfo,
  (state: PackState) => state.packList,
  (filterInfo, pageInfo, packList) => {
    if (
      (filterInfo.groupId && filterInfo.groupId.trim() !== '') ||
      (filterInfo.packNm && filterInfo.packNm.trim() !== '')
    ) {
      const filterPackList = packList.filter(pack => {
        let groupFilter = true;
        let packFilter = true;

        if (filterInfo.groupId && filterInfo.groupId !== '') {
          groupFilter = pack.groupId === filterInfo.groupId;
        }

        if (filterInfo.packNm && filterInfo.packNm !== '') {
          packFilter = pack.packNm.indexOf(filterInfo.packNm) >= 0;
        }

        return groupFilter && packFilter;
      });

      const totalCount = filterPackList.length;
      const startIndex = pageInfo.rowsPerPage * (pageInfo.curPage - 1);
      const endIndex = totalCount - startIndex;
      const totalPages =
        filterPackList.length % pageInfo.rowsPerPage > 0
          ? Math.floor(filterPackList.length / pageInfo.rowsPerPage) + 1
          : Math.floor(filterPackList.length / pageInfo.rowsPerPage);

      const sliceList = filterPackList.slice(
        pageInfo.rowsPerPage * (pageInfo.curPage - 1),
        pageInfo.rowsPerPage * pageInfo.curPage,
      );

      return {
        totalCount,
        startIndex,
        endIndex,
        totalPages,
        packList: sliceList,
      };
    }

    const totalCount = packList.length;
    const startIndex = pageInfo.rowsPerPage * (pageInfo.curPage - 1);
    const endIndex = totalCount - startIndex;
    const totalPages =
      packList.length % pageInfo.rowsPerPage > 0
        ? Math.floor(packList.length / pageInfo.rowsPerPage) + 1
        : Math.floor(packList.length / pageInfo.rowsPerPage);

    const sliceList = packList.slice(
      pageInfo.rowsPerPage * (pageInfo.curPage - 1),
      pageInfo.rowsPerPage * pageInfo.curPage,
    );

    return {
      totalCount,
      startIndex,
      endIndex,
      totalPages,
      packList: sliceList,
    };
  },
);

const selectStatus = createDraftSafeSelector(
  (state: PackState) => state.actionResult,
  (state: PackState) => state.isLoading,
  (state: PackState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const packSelector = {
  filterInfo: state => selectFilterInfo(state[PACK]),
  pageInfo: state => selectPageInfo(state[PACK]),
  packInfo: state => selectPackInfo(state[PACK]),
  packList: state => selectPackList(state[PACK]),
  pagePackList: state => selectPagePackList(state[PACK]),
  status: state => selectStatus(state[PACK]),
};

export const PACK = slice.name;
export const packReducer = slice.reducer;
export const packAction = slice.actions;
