import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { packAction } from './packSlice';

import * as packApiLib from '../lib/packApi';

function* packList({ payload }) {
  try {
    const packList = yield call(packApiLib.list, payload);

    yield put(packAction.packListSuccess({ packList }));
  } catch (err) {
    yield put(packAction.packListFailure('Pack List Error'));
  }
}

function* packInfo({ payload }) {
  try {
    const packInfo = yield call(packApiLib.detail, payload);

    yield put(packAction.packInfoSuccess({ packInfo }));
  } catch (err) {
    yield put(packAction.packInfoFailure('Pack Info Error'));
  }
}

function* packAdd({ payload }) {
  try {
    yield call(packApiLib.add, payload);

    yield put(packAction.packAddSuccess());
  } catch (err) {
    yield put(packAction.packAddFailure('Pack Add Error'));
  }
}

function* packEdit({ payload }) {
  try {
    const packInfo = yield call(packApiLib.edit, payload);

    yield put(packAction.packEditSuccess({ packInfo }));
  } catch (err) {
    yield put(packAction.packEditFailure('Pack Edit Error'));
  }
}

function* packRemove({ payload }) {
  try {
    yield call(packApiLib.remove, payload);

    yield put(packAction.packRemoveSuccess());
  } catch (err) {
    yield put(packAction.packRemoveFailure('Pack Remove Error'));
  }
}

function* groupMove({ payload }) {
  try {
    yield call(packApiLib.groupMove, payload);

    yield put(packAction.groupMoveSuccess());
  } catch (error) {
    yield put(packAction.groupMoveFailure('Group Move Error'));
  }
}

export function* watchPackList() {
  yield takeLatest(packAction.packList, packList);
}

export function* watchPackInfo() {
  yield takeLatest(packAction.packInfo, packInfo);
}

export function* watchPackAdd() {
  yield takeLatest(packAction.packAdd, packAdd);
}

export function* watchPackEdit() {
  yield takeLatest(packAction.packEdit, packEdit);
}

export function* watchPackRemove() {
  yield takeLatest(packAction.packRemove, packRemove);
}

export function* watchGroupMove() {
  yield takeLatest(packAction.groupMove, groupMove);
}

function* rootSaga() {
  yield all([
    fork(watchPackList),
    fork(watchPackInfo),
    fork(watchPackAdd),
    fork(watchPackEdit),
    fork(watchPackRemove),
    fork(watchGroupMove),
  ]);
}

export default rootSaga;
