import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IRecordInfo {
  recordId: string;
}

interface RecordState {
  recordList: IrecordInfo[];
  recordInfo: IrecordInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const recordInitialState: RecordState = {
  recordList: [],
  recordInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: RecordState, { payload }: PayloadAction<RecordState>) => {
    state.recordList = [];
    state.recordInfo = {};
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },

  list: (state: RecordState, { payload }: PayloadAction<RecordState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: RecordState, { payload }: PayloadAction<RecordState>) => {
    state.recordList = payload.recordList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: RecordState, { payload }: PayloadAction<RecordState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  detail: (state: RecordState, { payload }: PayloadAction<RecordState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  detailSuccess: (state: RecordState, { payload }: PayloadAction<RecordState>) => {
    state.recordInfo = payload.recordInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  detailFailure: (state: RecordState, { payload }: PayloadAction<RecordState>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = payload.error;
  },
  add: (state: RecordState, { payload }: PayloadAction<{}>) => {
    state.isLoading = true;
    state.actionResult = 'ADD_REQ';
    state.error = '';
  },
  addSuccess: (state: RecordState, { payload }: PayloadAction<RecordState>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_OK';
    state.error = '';
  },
  addFailure: (state: RecordState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_ERR';
    state.error = action.payload;
  },
  edit: (state: RecordState, { payload }: PayloadAction<{}>) => {
    state.isLoading = true;
    state.actionResult = 'EDIT_REQ';
    state.error = '';
  },
  editSuccess: (state: RecordState, { payload }: PayloadAction<RecordState>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_OK';
    state.error = '';
  },
  editFailure: (state: RecordState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_ERR';
    state.error = action.payload;
  },
  remove: (state: RecordState, { payload }: PayloadAction<{}>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: RecordState, { payload }: PayloadAction<RecordState>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: RecordState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = action.payload;
  },
  orderUpdate: (state: RecordState, { payload }: PayloadAction<{}>) => {
    state.isLoading = true;
    state.actionResult = 'ORDER_REQ';
    state.error = '';
  },
  orderUpdateSuccess: (state: RecordState, { payload }: PayloadAction<RecordState>) => {
    state.isLoading = false;
    state.recordList = payload.recordList;
    state.actionResult = 'ORDER_OK';
    state.error = '';
  },
  orderUpdateFailure: (state: RecordState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'ORDER_ERR';
    state.error = action.payload;
  },
  actionResultClear: (state: RecordState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'record',
  initialState: recordInitialState,
  reducers: reducers,
});

const selectRecordList = createDraftSafeSelector(
  (state: recordState) => state.recordList,
  recordList => recordList,
);

const selectRecordInfo = createDraftSafeSelector(
  (state: recordState) => state.recordInfo,
  recordInfo => recordInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: recordState) => state.isLogin,
  (state: recordState) => state.actionResult,
  (state: recordState) => state.isLoading,
  (state: recordState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const recordSelector = {
  recordList: state => selectRecordList(state[RECORD]),
  recordInfo: state => selectRecordInfo(state[RECORD]),
  status: state => selectStatus(state[RECORD]),
};

export const RECORD = slice.name;
export const recordReducer = slice.reducer;
export const recordAction = slice.actions;
