import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { recordAction } from './recordSlice';

import * as recordApiLib from '../lib/recordApi';
import * as storageApiLib from '../lib/storageApi';
import * as historyCategoryApi from '../lib/historyCategoryApi';
import dayjs from 'dayjs';
import { v4 } from 'uuid';

function* list({ payload }) {
  try {
    const recordList = yield call(recordApiLib.list, payload);
    const sort = recordList.sort(function (lhs, rhs) {
      if (lhs.mediaOrder && rhs.mediaOrder) {
        return lhs.mediaOrder - rhs.mediaOrder;
      }
      return 0;
    });

    yield put(recordAction.listSuccess({ recordList: sort }));
  } catch (error) {
    yield put(recordAction.listFailure({ error }));
  }
}

function* detail({ payload }) {
  try {
    const recordInfo = yield call(recordApiLib.detail, payload);

    yield put(recordAction.detailSuccess({ recordInfo }));
  } catch (error) {
    yield put(recordAction.detailFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    const placeId = payload.placeId;
    const fileList = payload.fileList;
    const formValue = payload.formValue;
    const recordList = payload.recordList;

    const handleUploadProgress = payload.handleUploadProgress;
    const resultMediaFileList = yield call(storageApiLib.upload, { fileList, placeId, handleUploadProgress });
    const categoryId = payload.categoryId;
    let relationIdList = [];

    resultMediaFileList.map(item => (item['categoryId'] = formValue.categoryId));
    resultMediaFileList.forEach(file => relationIdList.push(file.fileId));

    yield call(historyCategoryApi.linkCategory, { relationIdList, categoryId: categoryId });
    let order = recordList.length + 1;
    const callList = resultMediaFileList.map(file => {
      const recordId = dayjs().unix() + v4().substring(0, 8);
      const callApi = call(recordApiLib.add, {
        ...formValue,
        recordId,
        placeId,
        categoryId,
        fileList: [file],
        mediaOrder: order,
      });
      order++;
      return callApi;
    });

    yield all([...callList]);

    yield put(recordAction.addSuccess());
  } catch (error) {
    yield put(recordAction.addFailure({ error }));
  }
}
function* remove({ payload }) {
  try {
    const removeList = payload.removeList;
    let unLinkCategoryInfo = {};
    let removeCallList = [];
    let recordIdList = [];

    for (const record of removeList) {
      const recordId = record.recordId;
      const categoryId = record.categoryId;
      recordIdList.push(recordId);
      if (categoryId) {
        if (unLinkCategoryInfo[categoryId]) {
          unLinkCategoryInfo[categoryId].push(recordId);
        } else {
          unLinkCategoryInfo[categoryId] = [recordId];
        }
      }
    }

    for (const categoryId in unLinkCategoryInfo) {
      removeCallList.push(
        call(historyCategoryApi.unLinkCategory, { relationIdList: unLinkCategoryInfo[categoryId], categoryId: categoryId }),
      );
    }

    yield all([...removeCallList]);
    yield call(storageApiLib.remove, { filePathList: payload.removeFilePathList });
    yield call(recordApiLib.remove, { removeList: payload.removeList, placeId: payload.placeId });
    yield put(recordAction.removeSuccess());
  } catch (error) {
    yield put(recordAction.removeFailure({ error }));
  }
}

function* edit({ payload }) {
  try {
    try {
      const placeId = payload.placeId;
      const recordId = payload.recordId;
      const fileListInfo = payload?.fileListInfo || { addFileList: [], removeFileList: [], fileList: [], placeId: placeId };
      const handleUploadProgress = payload.handleUploadProgress;
      const resultFileList = yield call(storageApiLib.uploadAndRemove, { ...fileListInfo, handleUploadProgress });
      const linkId = payload.linkId;
      const unLinkId = payload.unLinkId;

      if (linkId !== unLinkId) {
        yield call(historyCategoryApi.linkCategory, { relationIdList: [recordId], categoryId: linkId });
        yield call(historyCategoryApi.unLinkCategory, { relationIdList: [recordId], categoryId: unLinkId });
      }

      const callList = payload.updateList.map(update => {
        const updateInfo = { ...update.updateInfo, fileList: resultFileList };
        const callApi = call(recordApiLib.edit, { facilityId: update.facilityId, updateInfo: updateInfo, recordId });
        return callApi;
      });

      yield all([...callList]);

      yield put(recordAction.editSuccess());
    } catch (error) {
      yield put(recordAction.editFailure({ error }));
    }
  } catch (error) {
    yield put(recordAction.editFailure({ error }));
  }
}

function* recordOrderUpdate({ payload }) {
  try {
    const callList = payload.updateList.map(updateInfo => {
      const callApi = call(recordApiLib.orderUpdate, { ...updateInfo });
      return callApi;
    });
    yield all([...callList]);

    const recordList = yield call(recordApiLib.list, payload);
    const sort = recordList.sort(function (lhs, rhs) {
      if (lhs.mediaOrder && rhs.mediaOrder) {
        return lhs.mediaOrder - rhs.mediaOrder;
      }
      return 0;
    });
    yield put(recordAction.orderUpdateSuccess({ recordList: sort }));
  } catch (err) {
    yield put(recordAction.orderUpdateFailure());
  }
}

// function* edit({ payload }) {
//   try {
//     const fileList = payload.fileList;
//     const changeCategoryId = payload.categoryId;
//     let linkCategroyList = [];
//     let unLinkCategoryInfo = {};
//     let removeCallList = [];

//     for (const file of fileList) {
//       const fileId = file.fileId;
//       const categoryId = file.categoryId;

//       if (categoryId !== changeCategoryId) {
//         linkCategroyList.push(fileId);
//         if (categoryId) {
//           if (unLinkCategoryInfo[categoryId]) {
//             unLinkCategoryInfo[categoryId].push(fileId);
//           } else {
//             unLinkCategoryInfo[categoryId] = [fileId];
//           }
//         }
//       }
//     }

//     for (const categoryId in unLinkCategoryInfo) {
//       removeCallList.push(
//         call(historyCategoryApi.unLinkCategory, { relationIdList: unLinkCategoryInfo[categoryId], categoryId: categoryId }),
//       );
//     }

//     yield all([...removeCallList]);
//     yield call(historyCategoryApi.linkCategory, { relationIdList: linkCategroyList, categoryId: changeCategoryId });
//     yield call(recordApiLib.edit, payload);

//     yield put(recordAction.editSuccess());
//   } catch (error) {
//     yield put(recordAction.editFailure({ error }));
//   }
// }

export function* watchList() {
  yield takeLatest(recordAction.list, list);
}

export function* watchAdd() {
  yield takeLatest(recordAction.add, add);
}
export function* watchRemove() {
  yield takeLatest(recordAction.remove, remove);
}

export function* watchDetail() {
  yield takeLatest(recordAction.detail, detail);
}

export function* watchEdit() {
  yield takeLatest(recordAction.edit, edit);
}
export function* watchOrderUpdate() {
  yield takeLatest(recordAction.orderUpdate, recordOrderUpdate);
}

function* rootSaga() {
  yield all([fork(watchList), fork(watchDetail), fork(watchAdd), fork(watchRemove), fork(watchEdit), fork(watchOrderUpdate)]);
}

export default rootSaga;
