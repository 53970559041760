import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { mobileAction } from './mobileSlice';

import * as mobileApiLib from '../lib/mobileApi';

function* collection({ payload }) {
  try {
    const collection = yield call(mobileApiLib.collection, payload);

    yield put(mobileAction.collectionSuccess({ collection }));
  } catch (err) {
    yield put(mobileAction.collectionFailure('Collection Error'));
  }
}

function* jampic({ payload }) {
  try {
    const jampic = yield call(mobileApiLib.jampic, payload);

    yield put(mobileAction.jampicSuccess({ jampic }));
  } catch (err) {
    yield put(mobileAction.jampicFailure('Jampic Error'));
  }
}

export function* watchCollection() {
  yield takeLatest(mobileAction.collection, collection);
}

export function* watchJampic() {
  yield takeLatest(mobileAction.jampic, jampic);
}

function* rootSaga() {
  yield all([fork(watchCollection), fork(watchJampic)]);
}

export default rootSaga;
