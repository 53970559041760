import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IFrameGroupInfo {
  frameGroupId: string;
  frameGroupNm?: string;
  frameGroupOrder?: string;
  frameGroupPid?: string;
  playlistId?: string;
  packageId?: string;
  useYn?: string;
}

interface FrameGroupState {
  frameGroupList: IFrameGroupInfo[];
  frameGroupInfo: IFrameGroupInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: FrameGroupState = {
  frameGroupList: [],
  frameGroupInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: FrameGroupState, { payload }: PayloadAction<FrameGroupState>) => {
    state.frameGroupList = [];
    state.frameGroupInfo = {};
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },
  list: (state: FrameGroupState, { payload }: PayloadAction<FrameGroupState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: FrameGroupState, { payload }: PayloadAction<FrameGroupState>) => {
    state.frameGroupList = payload.frameGroupList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: FrameGroupState, { payload }: PayloadAction<FrameGroupState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  detail: (state: FrameGroupState, { payload }: PayloadAction<FrameGroupState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  detailSuccess: (state: FrameGroupState, { payload }: PayloadAction<FrameGroupState>) => {
    state.frameGroupInfo = payload.frameGroupInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  detailFailure: (state: FrameGroupState, { payload }: PayloadAction<FrameGroupState>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = payload.error;
  },
  add: (state: FrameGroupState, { payload }: PayloadAction<CsutomerFrameGroupState>) => {
    state.isLoading = true;
    state.actionResult = 'ADD_REQ';
    state.error = '';
  },
  addSuccess: (state: FrameGroupState, { payload }: PayloadAction<FrameGroupState>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_OK';
    state.error = '';
  },
  addFailure: (state: FrameGroupState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_ERR';
    state.error = action.payload;
  },
  edit: (state: FrameGroupState, { payload }: PayloadAction<CsutomerFrameGroupState>) => {
    state.isLoading = true;
    state.actionResult = 'EDIT_REQ';
    state.error = '';
  },
  editSuccess: (state: FrameGroupState, { payload }: PayloadAction<FrameGroupState>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_OK';
    state.error = '';
  },
  editFailure: (state: FrameGroupState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_ERR';
    state.error = action.payload;
  },
  remove: (state: FrameGroupState, { payload }: PayloadAction<CsutomerFrameGroupState>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: FrameGroupState, { payload }: PayloadAction<FrameGroupState>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: FrameGroupState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = action.payload;
  },

  updateFrameGroupNm(state: FrameState, { payload: { frameGroupId, frameGroupNm } }: PayloadAction<FrameState>) {
    const idx = state.frameGroupList.findIndex(frameGroup => frameGroup.frameGroupId === frameGroupId);
    state.frameGroupList[idx].frameGroupNm = frameGroupNm;
  },
  actionResultClear: (state: FrameGroupState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'frameGroup',
  initialState: InitialState,
  reducers: reducers,
});

const selectFrameGroupList = createDraftSafeSelector(
  (state: FrameGroupState) => state.frameGroupList,
  frameGroupList => {
    let sortFrameGroupList = [...frameGroupList];
    sortFrameGroupList = sortFrameGroupList.sort((a, b) => {
      return a.frameGroupOrder - b.frameGroupOrder;
    });

    return sortFrameGroupList;
  },
);

const selectFrameGroupInfo = createDraftSafeSelector(
  (state: FrameGroupState) => state.frameGroupInfo,
  frameGroupInfo => frameGroupInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: FrameGroupState) => state.isLogin,
  (state: FrameGroupState) => state.actionResult,
  (state: FrameGroupState) => state.isLoading,
  (state: FrameGroupState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const frameGroupSelector = {
  frameGroupList: state => selectFrameGroupList(state[FRAMEGROUP]),
  frameGroupInfo: state => selectFrameGroupInfo(state[FRAMEGROUP]),
  status: state => selectStatus(state[FRAMEGROUP]),
};

export const FRAMEGROUP = slice.name;
export const frameGroupReducer = slice.reducer;
export const frameGroupAction = slice.actions;
