import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IHistoryCategoryInfo {
  categoryId: string;
  placeId?: string;
  relationIdList?: any[];
  categoryNm?: string;
  categoryType?: string;
  useYn?: string;
  regDt?: string;
  regId?: string;
  modDt?: string;
  modId?: string;
  delYn?: string;
  delId?: string;
}

interface HistoryCategoryState {
  categoryList: IHistoryCategoryInfo[];
  categoryInfo: IHistoryCategoryInfo;
  categoryFilter: string;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: HistoryCategoryState = {
  categoryList: [],
  categoryInfo: {},
  categoryFilter: '',
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: HistoryCategoryState, { payload }: PayloadAction<HistoryCategoryState>) => {
    state.categoryList = [];
    state.categoryInfo = {};
    state.actionResult = '';
    state.categoryFilter = '';
    state.isLoading = false;
    state.error = '';
  },
  list: (state: HistoryCategoryState, { payload }: PayloadAction<HistoryCategoryState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: HistoryCategoryState, { payload }: PayloadAction<HistoryCategoryState>) => {
    state.categoryList = payload.categoryList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: HistoryCategoryState, { payload }: PayloadAction<HistoryCategoryState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload;
  },
  add: (state: HistoryCategoryState, { payload }: PayloadAction<{}>) => {
    state.isLoading = true;
    state.actionResult = 'ADD_REQ';
    state.error = '';
  },
  addSuccess: (state: HistoryCategoryState, { payload }: PayloadAction<HistoryCategoryState>) => {
    state.categoryList = payload.categoryList;
    state.isLoading = false;
    state.actionResult = 'ADD_OK';
    state.error = '';
  },
  addFailure: (state: HistoryCategoryState, { payload }: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_ERR';
    state.error = payload;
  },
  update: (state: HistoryCategoryState, { payload }: PayloadAction<CsutomerHistoryCategoryState>) => {
    const categoryId = payload.categoryId;
    const categoryList = state.categoryList.map(category =>
      category.categoryId === categoryId ? { ...category, categoryNm: payload.categoryNm } : category,
    );

    state.categoryList = categoryList;
    state.isLoading = true;
    state.actionResult = 'UPDATE_REQ';
    state.error = '';
  },
  updateSuccess: (state: HistoryCategoryState, { payload }: PayloadAction<HistoryCategoryState>) => {
    state.categoryList = payload.categoryList;
    state.isLoading = false;
    state.actionResult = 'UPDATE_OK';
    state.error = '';
  },
  updateFailure: (state: HistoryCategoryState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'UPDATE_ERR';
    state.error = action.payload;
  },
  remove: (state: HistoryCategoryState, { payload }: PayloadAction<CsutomerHistoryCategoryState>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: HistoryCategoryState, { payload }: PayloadAction<HistoryCategoryState>) => {
    state.categoryList = payload.categoryList;
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: HistoryCategoryState, { payload }: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = payload;
  },

  categoryFilter: (state: HistoryCategoryState, { payload }: PayloadAction<string>) => {
    state.categoryFilter = payload;
  },
  actionResultClear: (state: HistoryCategoryState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'historyCategory',
  initialState: InitialState,
  reducers: reducers,
});

const selectcategoryList = createDraftSafeSelector(
  (state: HistoryCategoryState) => state.categoryList,
  categoryList => categoryList,
);

const selectcategoryFilterList = createDraftSafeSelector(
  (state: HistoryCategoryState) => state.categoryList,
  (state: HistoryCategoryState) => state.categoryFilter,
  (categoryList, categoryFilter) => {
    let filterCategoryList = categoryList;
    if (categoryFilter !== '') {
      filterCategoryList = categoryList.filter(categroy => categroy.categoryType === categoryFilter);
    }
    return filterCategoryList;
  },
);

const selectcategoryInfo = createDraftSafeSelector(
  (state: HistoryCategoryState) => state.categoryInfo,
  categoryInfo => categoryInfo,
);

const selectcategoryFilter = createDraftSafeSelector(
  (state: HistoryCategoryState) => state.categoryFilter,
  categoryFilter => categoryFilter,
);

const selectStatus = createDraftSafeSelector(
  (state: HistoryCategoryState) => state.actionResult,
  (state: HistoryCategoryState) => state.isLoading,
  (state: HistoryCategoryState) => state.error,
  (actionResult, isLoading, error) => ({
    actionResult,
    isLoading,
    error,
  }),
);

export const historyCategorySelector = {
  categoryList: state => selectcategoryList(state[HISTORYCATEGORY]),
  categoryFilterList: state => selectcategoryFilterList(state[HISTORYCATEGORY]),
  categoryInfo: state => selectcategoryInfo(state[HISTORYCATEGORY]),
  categoryFilter: state => selectcategoryFilter(state[HISTORYCATEGORY]),
  status: state => selectStatus(state[HISTORYCATEGORY]),
};

export const HISTORYCATEGORY = slice.name;
export const historyCategoryReducer = slice.reducer;
export const historyCategoryAction = slice.actions;
