import * as apiLib from './api';

export const list = async payload => {
  try {
    let categoryList = [];

    let searchFlag = true;

    let data = {
      placeId: payload.placeId,
      limit: 100,
      LastEvaluatedKey: null,
    };

    while (searchFlag) {
      const result = await apiLib.fetchArchiveApi('/historyCategory/limit-list', data);
      categoryList = categoryList.concat(result.categoryList);
      if (result.LastEvaluatedKey === undefined) {
        searchFlag = false;
      }
      data.LastEvaluatedKey = result.LastEvaluatedKey;
    }

    return categoryList;
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/historyCategory/add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('historyCategory Add Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const update = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/historyCategory/update', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('historyCategory update Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/historyCategory/remove', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('historyCategory Remove Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};

export const linkCategory = async ({ relationIdList, categoryId }) => {
  try {
    
    if (categoryId === undefined || relationIdList === undefined ||categoryId===null|| relationIdList === null||categoryId === '' || relationIdList.length === 0) {
      return true;
    }

    const data = {
      relationIdList,
      categoryId,
    };

    const result = await apiLib.fetchArchiveApi('/historyCategory/link-id', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Link historyCategory Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};

export const unLinkCategory = async ({ relationIdList, categoryId }) => {
  try {
    if (categoryId === undefined || relationIdList === undefined ||categoryId===null|| relationIdList === null||categoryId === '' || relationIdList.length === 0) {
      return true;
    }
    
    const data = {
      relationIdList,
      categoryId,
    };

    const result = await apiLib.fetchArchiveApi('/historyCategory/unLink-id', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('unLink historyCategory Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};
