import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface ICollection {}

interface CurationFilterPayload {
  useYnFilter: String;
  searchValue: String;
}

interface ICurationInfo {
  curationId: String;
  placeId: string;
  curationNm: String;
  curationEngNm: String;
  curationDetail: String;
  curationEngDetail: String;
  collectionList: ICollection[];
  curationOrder: Number;
  useYn: String;
  regDt: string;
  regId: string;
  modDt: string;
  modId: string;
  delYn: string;
  delId: string;
  delDt: string;
}

interface CurationFilter {
  useYnFilter: String;
  searchValue: String;
}

interface CurationState {
  curationList: ICurationInfo[];
  curationInfo: ICurationInfo;
  curationFilter: CurationFilter;
  actinoResult: String;
  isLoading: boolean;
  error: string | null;
}

const curationInitialState: CurationState = {
  curationList: [],
  curationInfo: {},
  curationFilter: { useYnFilter: 'ALL', searchValue: '' },
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: CurationState, { payload }: PayloadAction<CurationState>) => {
    state.curationList = [];
    state.curationInfo = {};
    state.curationFilter = { useYnFilter: 'ALL', searchValue: '' };
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },
  list: (state: CurationState, { payload }: PayloadAction<{}>) => {
    state.actionResult = 'CURATION_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  listSuccess: (state: CurationState, { payload }: PayloadAction<CurationState>) => {
    state.curationList = payload.curationList;
    state.actionResult = 'CURATION_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  listFailure: (state: CurationState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CURATION_LIST_ERR';
    state.isLoading = false;
    state.error = payload;
  },

  detail: (state: CurationState, { payload }: PayloadAction<{}>) => {
    state.actionResult = 'CURATION_DETAIL_REQ';
    state.isLoading = true;
    state.error = null;
  },
  detailSuccess: (state: CurationState, { payload }: PayloadAction<CurationState>) => {
    state.curationInfo = payload.curationInfo;
    state.actionResult = 'CURATION_DETAIL_OK';
    state.isLoading = false;
    state.error = null;
  },
  detailFailure: (state: CurationState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CURATION_DETAIL_ERR';
    state.isLoading = false;
    state.error = payload;
  },

  add: (state: CurationState, { payload }: PayloadAction<{}>) => {
    state.actionResult = 'CURATION_ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  addSuccess: (state: CurationState, { payload }: PayloadAction<CurationState>) => {
    state.curationList = payload.curationList;
    state.actionResult = 'CURATION_ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  addFailure: (state: CurationState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CURATION_ADD_ERR';
    state.isLoading = false;
    state.error = payload;
  },

  update: (state: CurationState, { payload }: PayloadAction<{}>) => {
    state.actionResult = 'CURATION_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  updateSuccess: (state: CurationState, { payload }: PayloadAction<CurationState>) => {
    state.curationList = payload.curationList;
    state.actionResult = 'CURATION_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  updateFailure: (state: CurationState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CURATION_UPDATE_ERR';
    state.isLoading = false;
    state.error = payload;
  },

  remove: (state: CurationState, { payload }: PayloadAction<{}>) => {
    state.actionResult = 'CURATION_REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  removeSuccess: (state: CurationState, { payload }: PayloadAction<CurationState>) => {
    state.curationList = payload.curationList;
    state.actionResult = 'CURATION_REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  removeFailure: (state: CurationState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CURATION_REMOVE_ERR';
    state.isLoading = false;
    state.error = payload;
  },

  curationFilter: (state: CurationState, { payload }: PayloadAction<CurationFilterPayload>) => {
    state.curationFilter = payload;
  },
  actionResultClear: (state: CurationState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'curation',
  initialState: curationInitialState,
  reducers: reducers,
});

const selectCurationList = createDraftSafeSelector(
  (state: CurationState) => state.curationList,
  curationList => curationList,
);

const selectCurationInfo = createDraftSafeSelector(
  (state: CurationState) => state.curationInfo,
  curationInfo => curationInfo,
);

const selectCurationFilterList = createDraftSafeSelector(
  (state: CurationState) => state.curationList,
  (state: CurationState) => state.curationFilter,
  (curationList, curationFilter) => {
    let filterCurationList = curationList.filter(curation => {
      if (
        (curationFilter?.useYnFilter === 'ALL' || curationFilter.useYnFilter === curation.useYn) &&
        (curationFilter.searchValue === '' ||
          curation.curationNm?.toLowerCase().indexOf(curationFilter.searchValue.toLowerCase()) >= 0 ||
          curation.curationEngNm?.toLowerCase().indexOf(curationFilter.searchValue.toLowerCase()) >= 0)
      ) {
        return true;
      } else {
        return false;
      }
    });

    return filterCurationList;
  },
);

const selectStatus = createDraftSafeSelector(
  (state: CurationState) => state.isLogin,
  (state: CurationState) => state.actionResult,
  (state: CurationState) => state.isLoading,
  (state: CurationState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const curationSelector = {
  status: state => selectStatus(state[CURATION]),
  curationList: state => selectCurationList(state[CURATION]),
  curationFilterList: state => selectCurationFilterList(state[CURATION]),
  curationInfo: state => selectCurationInfo(state[CURATION]),
};

export const CURATION = slice.name;
export const curationReducer = slice.reducer;
export const curationAction = slice.actions;
