import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { playlistLogAction } from './playlistLogSlice';

import dayjs from 'dayjs';

import * as playlistLogApiLib from '../lib/playlistLogApi';

function* list({ payload }) {
  try {
    let searchStartDt = payload.searchStartDt;
    let searchEndDt = payload.searchEndDt;

    let callList = [];

    while (searchStartDt <= searchEndDt) {
      callList.push(
        call(playlistLogApiLib.list, {
          ...payload,
          searchStartDt: dayjs(searchStartDt).startOf('day').toISOString(),
          searchEndDt: dayjs(searchStartDt).endOf('day').toISOString(),
        }),
      );

      searchStartDt = dayjs(searchStartDt).add(1, 'day').format('YYYY-MM-DD');
    }

    const playlistLogList = yield all([...callList]);

    // const playlistLogList = yield call(playlistLogApiLib.list, payload);

    yield put(playlistLogAction.listSuccess({ playlistLogList: playlistLogList.flat() }));
  } catch (error) {
    yield put(playlistLogAction.listFailure({ error }));
  }
}

export function* watchList() {
  yield takeLatest(playlistLogAction.list, list);
}

/*

function* add({ payload }) {
  try {
    yield call(playlistLogApiLib.add, payload);

    yield put(playlistLogAction.addSuccess());
  } catch (error) {
    yield put(playlistLogAction.addFailure({ error }));
  }
}

function* detail({ payload }) {
  try {
    const playlistLogInfo = yield call(playlistLogApiLib.detail, payload);

    yield put(playlistLogAction.detailSuccess({ playlistLogInfo }));
  } catch (error) {
    yield put(playlistLogAction.detailFailure({ error }));
  }
}

function* edit({ payload }) {
  try {
    yield call(playlistLogApiLib.edit, payload);

    yield put(playlistLogAction.editSuccess());
  } catch (error) {
    yield put(playlistLogAction.editFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    yield call(playlistLogApiLib.remove, payload);

    yield put(playlistLogAction.removeSuccess());
  } catch (error) {
    yield put(playlistLogAction.removeFailure({ error }));
  }
}



export function* watchAdd() {
  yield takeLatest(playlistLogAction.add, add);
}

export function* watchDetail() {
  yield takeLatest(playlistLogAction.detail, detail);
}

export function* watchEdit() {
  yield takeLatest(playlistLogAction.edit, edit);
}

export function* watchRemove() {
  yield takeLatest(playlistLogAction.remove, remove);
}
*/

function* rootSaga() {
  yield all([
    fork(watchList),
    // fork(watchAdd),
    // fork(watchDetail),
    // fork(watchEdit),
    // fork(watchRemove),
  ]);
}

export default rootSaga;
