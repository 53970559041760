import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IUserInfo {
  userId: string;
}

interface UserState {
  userList: IUserInfo[];
  userInfo: IUserInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: UserState = {
  userList: [],
  userInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: UserState, { payload }: PayloadAction<UserState>) => {
    state.userList = [];
    state.userInfo = {};
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },
  list: (state: UserState, { payload }: PayloadAction<UserState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: UserState, { payload }: PayloadAction<UserState>) => {
    state.userList = payload.userList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: UserState, { payload }: PayloadAction<UserState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  detail: (state: UserState, { payload }: PayloadAction<UserState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  detailSuccess: (state: UserState, { payload }: PayloadAction<UserState>) => {
    state.userInfo = payload.userInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  detailFailure: (state: UserState, { payload }: PayloadAction<UserState>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = payload.error;
  },
  add: (state: UserState, { payload }: PayloadAction<CsutomerUserState>) => {
    state.isLoading = true;
    state.actionResult = 'ADD_REQ';
    state.error = '';
  },
  addSuccess: (state: UserState, { payload }: PayloadAction<UserState>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_OK';
    state.error = '';
  },
  addFailure: (state: UserState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_ERR';
    state.error = action.payload;
  },
  edit: (state: UserState, { payload }: PayloadAction<CsutomerUserState>) => {
    state.isLoading = true;
    state.actionResult = 'EDIT_REQ';
    state.error = '';
  },
  editSuccess: (state: UserState, { payload }: PayloadAction<UserState>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_OK';
    state.error = '';
  },
  editFailure: (state: UserState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_ERR';
    state.error = action.payload;
  },
  pwChange: (state: UserState, { payload }: PayloadAction<CsutomerUserState>) => {
    state.isLoading = true;
    state.actionResult = 'PW_CHANGE_REQ';
    state.error = '';
  },
  pwChangeSuccess: (state: UserState, { payload }: PayloadAction<UserState>) => {
    state.isLoading = false;
    state.actionResult = 'PW_CHANGE_OK';
    state.error = '';
  },
  pwChangeFailure: (state: UserState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'PW_CHANGE_ERR';
    state.error = action.payload;
  },
  remove: (state: UserState, { payload }: PayloadAction<CsutomerUserState>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: UserState, { payload }: PayloadAction<UserState>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: UserState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = action.payload;
  },
  actionResultClear: (state: UserState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'user',
  initialState: InitialState,
  reducers: reducers,
});

const selectuserList = createDraftSafeSelector(
  (state: UserState) => state.userList,
  (userList) => userList
);

const selectuserInfo = createDraftSafeSelector(
  (state: UserState) => state.userInfo,
  userInfo => userInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: UserState) => state.isLogin,
  (state: UserState) => state.actionResult,
  (state: UserState) => state.isLoading,
  (state: UserState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const userSelector = {
  userList: state => selectuserList(state[USER]),
  userInfo: state => selectuserInfo(state[USER]),
  status: state => selectStatus(state[USER]),
};

export const USER = slice.name;
export const userReducer = slice.reducer;
export const userAction = slice.actions;
