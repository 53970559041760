import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface LoginPayload {
  loginId: string;
  loginPw: string;
  idSave: string;
}

interface LoginInfo {
  accountNm: string;
  lastLoginDt: string;
  accessToken: string;
}

interface AuthState {
  isLogin: boolean;
  loginInfo: LoginInfo;
  actionResult: string;
  isLoading: boolean;
  error: string;
}

const authInitialState: AuthState = {
  isLogin: false,
  loginInfo: {},
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  login: (state: AuthState, { payload }: PayloadAction<LoginPayload>) => {
    state.isLoading = true;
    state.actionResult = 'LOGIN_REQ';
    state.error = '';
  },
  loginSuccess: (state: AuthState, { payload }: PayloadAction<AuthState>) => {
    state.isLogin = true;
    state.loginInfo = payload.loginInfo;
    state.isLoading = false;
    state.actionResult = 'LOGIN_OK';
    state.error = '';
  },
  loginFailure: (state: AuthState, action: PayloadAction<string>) => {
    state.isLogin = false;
    state.loginInfo = {};
    state.isLoading = false;
    state.actionResult = 'LOGIN_ERR';
    state.error = action.payload;
  },
  token: (state: AuthState) => {
    state.isLoading = true;
    state.actionResult = 'TOKEN_REQ';
    state.error = '';
  },
  tokenSuccess: (state: AuthState, { payload }: PayloadAction<AuthState>) => {
    state.isLogin = true;
    state.loginInfo = payload.loginInfo;
    state.isLoading = false;
    state.actionResult = 'TOKEN_OK';
    state.error = '';
  },
  tokenFailure: (state: AuthState, action: PayloadAction<string>) => {
    state.isLogin = false;
    state.isLoading = false;
    state.actionResult = 'TOKEN_ERR';
    state.error = action.payload;
  },
  kraftonToken: (state: AuthState, { payload }: PayloadAction<LoginPayload>) => {
    state.isLoading = true;
    state.actionResult = 'KRAFTON_TOKEN_REQ';
    state.error = '';
  },
  kraftonTokenSuccess: (state: AuthState, { payload }: PayloadAction<AuthState>) => {
    state.isLogin = true;
    state.loginInfo = payload.loginInfo;
    state.isLoading = false;
    state.actionResult = 'KRAFTON_TOKEN_OK';
    state.error = '';
  },
  kraftonTokenFailure: (state: AuthState, action: PayloadAction<string>) => {
    state.isLogin = false;
    state.isLoading = false;
    state.actionResult = 'KRAFTON_TOKEN_ERR';
    state.error = action.payload;
  },
  logout: (state: AuthState, { payload }: PayloadAction<LoginPayload>) => {
    state.isLoading = true;
    state.actionResult = 'LOGOUT_REQ';
    state.error = '';
  },
  logoutSuccess: (state: AuthState) => {
    state.isLogin = false;
    state.loginInfo = {};
    state.isLoading = false;
    state.actionResult = 'LOGOUT_OK';
    state.error = '';
  },
  logoutFailure: (state: AuthState, action: PayloadAction<string>) => {
    state.isLogin = false;
    state.loginInfo = {};
    state.isLoading = false;
    state.actionResult = 'LOGOUT_ERR';
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
  setLoginInfo: (state: AuthState, { payload }: PayloadAction<string>) => {
    state.loginInfo = payload.loginInfo;
  },
};

const slice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: reducers,
});

const selectLoginInfo = createDraftSafeSelector(
  (state: AuthState) => state.loginInfo,
  loginInfo => loginInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: AuthState) => state.isLogin,
  (state: AuthState) => state.actionResult,
  (state: AuthState) => state.isLoading,
  (state: AuthState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const authSelector = {
  loginInfo: state => selectLoginInfo(state[AUTH]),
  status: state => selectStatus(state[AUTH]),
};

export const AUTH = slice.name;
export const authReducer = slice.reducer;
export const authAction = slice.actions;
