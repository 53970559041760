import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IFileInfo {
  fileDuration: number;
  fileId: string;
  fileNm: string;
  fileOrder: number;
  filePath: string;
  fileSize: number;
  fileType: string;
  placeId: string;
  regDt: string;
  thumbFilePath?: string;
  thumbFileSize?: string;
}

interface ICollectionInfo {
  placeId: string;
  collectionId: string;
  managementNo: string;
  collectionNm: string;
  collectionEngNm: string;
  authorNm: string;
  authorEngNm: string;
  collectionDetail: string;
  collectionEngDetail: string;
  useYn: string;
  shareYn: string;
  fileList: IFileInfo[];
  subTitleList: { subTitleId: string, subTitle: string, subEngTitle: string }[];
  categoryId: string;
  relationCollectionIdList: string[];
  regDt: string;
  regId: string;
  modDt: string;
  modId: string;
  delYn: string;
  delId: string;
}

interface CollectionFilter {
  collectionId: String;
  thumbYn: String;
  useYn: String;
  searchType: String;
  searchValue: String;
}

interface CategoryAddPayload {
  categoryNm: String;
  categoryEngNm: String;
  fileList: IFileInfo[];
}

interface CategoryUpdatePayload {
  categoryId: String;
  categoryNm: String;
  categoryEngNm: String;
  fileList: IFileInfo[];
}

interface CategoryRemovePayload {
  categoryId: String;
}

interface CategoryInfo {
  categoryId: string;
  categoryPid: string;
  placeId: string;
  categoryNm: string;
  categoryEngNm: string;
  collectionCnt: number;
  fileList: IFileInfo[];
  regDt: string;
  regId: string;
  modDt: string;
  modId: string;
  useYn: string;
  delYn: string;
  delId: string;
  delDt: string;
  categoryOrder: number;
}

interface CategoryFilter {
  useYnFilter: String;
  searchValue: String;
}

interface CollectionState {
  collectionList: ICollectionInfo[];
  collectionInfo: ICollectionInfo;
  collectionFilter: CollectionFilter;
  categoryList: Array<CategoryInfo>;
  categoryFilter: CategoryFilter;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const collectionInitialState: CollectionState = {
  collectionList: [],
  collectionInfo: {},
  collectionFilter: { categoryId: 'ALL', thumbYn: '', useYn: '', searchType: 'collectionNm', searchValue: '' },
  categoryList: [],
  categoryFilter: { useYnFilter: 'ALL', searchValue: '' },
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  collectionFilter: (state: CollectionState, { payload }: PayloadAction<CollectionFilter>) => {
    state.collectionFilter = payload;
  },
  categoryFilter: (state: CollectionState, { payload }: PayloadAction<CategoryFilter>) => {
    state.categoryFilter = payload;
  },
  stateClear: (state: CollectionState, { payload }: PayloadAction<CollectionState>) => {
    state.collectionList = [];
    state.collectionInfo = {};
    state.collectionFilter = { categoryId: 'ALL', thumbYn: '', useYn: '', searchType: 'collectionNm', searchValue: '' };
    state.categoryList = [];
    state.categoryFilter = { useYnFilter: 'ALL', searchValue: '' };
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },
  list: (state: CollectionState, { payload }: PayloadAction<CollectionState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: CollectionState, { payload }: PayloadAction<CollectionState>) => {
    state.collectionList = payload.collectionList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: CollectionState, { payload }: PayloadAction<CollectionState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  detail: (state: CollectionState, { payload }: PayloadAction<CollectionState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  detailSuccess: (state: CollectionState, { payload }: PayloadAction<CollectionState>) => {
    state.collectionInfo = payload.collectionInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  detailFailure: (state: CollectionState, { payload }: PayloadAction<CollectionState>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = payload.error;
  },
  add: (state: CustomerState, { payload }: PayloadAction<CsutomerState>) => {
    state.isLoading = true;
    state.actionResult = 'ADD_REQ';
    state.error = '';
  },
  addSuccess: (state: CustomerState, { payload }: PayloadAction<CustomerState>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_OK';
    state.error = '';
  },
  addFailure: (state: CustomerState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_ERR';
    state.error = action.payload;
  },
  edit: (state: CustomerState, { payload }: PayloadAction<CsutomerState>) => {
    state.isLoading = true;
    state.actionResult = 'EDIT_REQ';
    state.error = '';
  },
  editSuccess: (state: CustomerState, { payload }: PayloadAction<CustomerState>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_OK';
    state.error = '';
  },
  editFailure: (state: CustomerState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_ERR';
    state.error = action.payload;
  },
  remove: (state: CustomerState, { payload }: PayloadAction<CsutomerState>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: CustomerState, { payload }: PayloadAction<CustomerState>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: CustomerState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = action.payload.error;
  },
  categoryList: (state: CollectionState, { payload }: PayloadAction<{}>) => {
    state.actionResult = 'CATEGORY_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  categoryListSuccess: (state: CollectionState, { payload }: PayloadAction<CollectionState>) => {
    state.categoryList = payload.categoryList;
    state.actionResult = 'CATEGORY_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  categoryListFailure: (state: CollectionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CATEGORY_LIST_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  categoryAdd: (state: CollectionState, { payload }: PayloadAction<CategoryAddPayload>) => {
    state.actionResult = 'CATEGORY_ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  categoryAddSuccess: (state: CollectionState, { payload }: PayloadAction<CollectionState>) => {
    state.categoryList = payload.categoryList;
    state.actionResult = 'CATEGORY_ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  categoryAddFailure: (state: CollectionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CATEGORY_ADD_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  categoryUpdate: (state: CollectionState, { payload }: PayloadAction<CategoryUpdatePayload>) => {
    state.actionResult = 'CATEGORY_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  categoryUpdateSuccess: (state: CollectionState, { payload }: PayloadAction<CollectionState>) => {
    state.categoryList = payload.categoryList;
    state.actionResult = 'CATEGORY_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  categoryUpdateFailure: (state: CollectionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CATEGORY_UPDATE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  categoryOrderUpdate: (state: CollectionState, { payload }: PayloadAction<CategoryUpdatePayload>) => {
    state.actionResult = 'CATEGORY_ORDER_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  categoryOrderUpdateSuccess: (state: CollectionState, { payload }: PayloadAction<CollectionState>) => {
    state.categoryList = payload.categoryList;
    state.actionResult = 'CATEGORY_ORDER_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  categoryOrderUpdateFailure: (state: CollectionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CATEGORY_ORDER_UPDATE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  categoryRemove: (state: CollectionState, { payload }: PayloadAction<CategoryRemovePayload>) => {
    state.actionResult = 'CATEGORY_REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  categoryRemoveSuccess: (state: CollectionState, { payload }: PayloadAction<CollectionState>) => {
    state.categoryList = payload.categoryList;
    state.actionResult = 'CATEGORY_REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  categoryRemoveFailure: (state: CollectionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CATEGORY_REMOVE_ERR';
    state.isLoading = false;
    state.error = payload.error;
  },
  curationIdAdd: (state: CollectionState, { payload }: PayloadAction<CategoryRemovePayload>) => {
    state.actionResult = 'CURATIONID_ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  curationIdAddSuccess: (state: CollectionState, { payload }: PayloadAction<CollectionState>) => {
    state.collectionList = payload.collectionList;
    state.actionResult = 'CURATIONID_ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  curationIdAddFailure: (state: CollectionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CURATIONID_ADD_ERR';
    state.isLoading = false;
    state.error = payload.error;
  },
  curationIdRemove: (state: CollectionState, { payload }: PayloadAction<CategoryRemovePayload>) => {
    state.actionResult = 'CURATIONID_REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  curationIdRemoveSuccess: (state: CollectionState, { payload }: PayloadAction<CollectionState>) => {
    state.collectionList = payload.collectionList;
    state.actionResult = 'CURATIONID_REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  curationIdRemoveFailure: (state: CollectionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CURATIONID_REMOVE_ERR';
    state.isLoading = false;
    state.error = payload.error;
  },
  actionResultClear: (state: CollectionState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'collection',
  initialState: collectionInitialState,
  reducers: reducers,
});

const selectCollectionList = createDraftSafeSelector(
  (state: CollectionState) => state.collectionList,
  collectionList => collectionList,
);

const selectCollectionFilterList = createDraftSafeSelector(
  (state: CollectionState) => state.collectionList,
  (state: CollectionState) => state.collectionFilter,
  (collectionList, collectionFilter) => {
    const filterCollectionList = collectionList.filter(collection => {
      let isCategoryIdMatch = true;
      let isThumbYnMatch = true;
      let isUseYnMatch = true;
      let isSearchMatch = true;
      if (collectionFilter.categoryId !== null && collectionFilter.categoryId !== 'ALL') {
        if (collectionFilter.categoryId === 'NONE') {
          isCategoryIdMatch = collection.categoryId === '' || collection.categoryId === null;
        } else {
          isCategoryIdMatch = collection.categoryId === collectionFilter.categoryId;
        }
      }
      if (collectionFilter.thumbYn !== null && collectionFilter.thumbYn !== '') {
        isThumbYnMatch =
          collection.fileList.length > 0 ? collectionFilter.thumbYn === 'Y' : collectionFilter.thumbYn === 'N';
      }
      if (collectionFilter.useYn !== null && collectionFilter.useYn !== '') {
        isUseYnMatch = collection.useYn === collectionFilter.useYn;
      }
      if (collectionFilter.searchValue !== null && collectionFilter.searchValue !== '') {
        if (collectionFilter.searchType === 'collectionNm') {
          isSearchMatch =
            collection.collectionNm?.toLowerCase().indexOf(collectionFilter.searchValue.toLowerCase()) >= 0;
        } else if (collectionFilter.searchType === 'authorNm') {
          isSearchMatch = collection.authorNm?.toLowerCase().indexOf(collectionFilter.searchValue.toLowerCase()) >= 0;
        }
      }
      return isCategoryIdMatch && isThumbYnMatch && isUseYnMatch && isSearchMatch;
    });

    return filterCollectionList;
  },
);

const selectCollectionInfo = createDraftSafeSelector(
  (state: CollectionState) => state.collectionInfo,
  collectionInfo => collectionInfo,
);

const selectCategoryList = createDraftSafeSelector(
  (state: CollectionState) => state.categoryList,
  categoryList => categoryList,
);

const selectCategoryCnt = createDraftSafeSelector(
  (state: CollectionState) => state.categoryList,
  categoryList => {
    return categoryList.length;
  },
);

const selectCategoryFilterList = createDraftSafeSelector(
  (state: CollectionState) => state.categoryList,
  (state: CollectionState) => state.categoryFilter,
  (categoryList, categoryFilter) => {
    let filterCategoryList = categoryList.filter(category => {
      if (
        (categoryFilter?.useYnFilter === 'ALL' || categoryFilter.useYnFilter === category.useYn) &&
        (categoryFilter.searchValue === '' ||
          category.categoryNm?.toLowerCase().indexOf(categoryFilter.searchValue.toLowerCase()) >= 0 ||
          category.categoryEngNm?.toLowerCase().indexOf(categoryFilter.searchValue.toLowerCase()) >= 0)
      ) {
        return true;
      } else {
        return false;
      }
    });

    return filterCategoryList;
  },
);

const selectStatus = createDraftSafeSelector(
  (state: CollectionState) => state.isLogin,
  (state: CollectionState) => state.actionResult,
  (state: CollectionState) => state.isLoading,
  (state: CollectionState) => state.error,
  (isLogin, actionResult, isLoading, error) => ({
    isLogin,
    actionResult,
    isLoading,
    error,
  }),
);

export const collectionSelector = {
  status: state => selectStatus(state[COLLECTION]),
  collectionList: state => selectCollectionList(state[COLLECTION]),
  collectionFilterList: state => selectCollectionFilterList(state[COLLECTION]),
  collectionInfo: state => selectCollectionInfo(state[COLLECTION]),
  categoryList: state => selectCategoryList(state[COLLECTION]),
  categoryCnt: state => selectCategoryCnt(state[COLLECTION]),
  categoryFilterList: state => selectCategoryFilterList(state[COLLECTION]),
};

export const COLLECTION = slice.name;
export const collectionReducer = slice.reducer;
export const collectionAction = slice.actions;
