import * as apiLib from './api';

export const list = async payload => {
  try {
    let photoList = [];

    let searchFlag = true;

    let data = {
      placeId: payload.placeId,
      limit: 100,
      LastEvaluatedKey: null,
    };

    while (searchFlag) {
      const result = await apiLib.fetchArchiveApi('/photo/limit-list', data);
      photoList = photoList.concat(result.photoList);
      if (result.LastEvaluatedKey === undefined) {
        searchFlag = false;
      }
      data.LastEvaluatedKey = result.LastEvaluatedKey;
    }

    // const sortPhotoGroupList = photoGroupList.sort((lhs, rhs) => lhs.regDt - rhs.regDt);
    // return sortPhotoGroupList;

    return photoList;
  } catch (err) {
    throw err;
  }
};

export const detail = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/photo/detail', data);

    if (result.resultFlag) {
      return result.photoInfo;
    } else {
      throw Object.assign(new Error('photo detail Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/photo/add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('photo Add Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const update = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/photo/update', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('photo update Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = {
      ...payload,
    };

    const result = await apiLib.fetchArchiveApi('/photo/remove', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('photo Remove Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};

export const unLinkPhotoGroup = async ({ groupId, relationPhotoIdList }) => {
  try {
    const data = {
      groupId,
      relationPhotoIdList,
    };

    const result = await apiLib.fetchArchiveApi('/photo/unlink-photoGroup', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Collection Link Curation Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};
