import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { facilityAction } from './facilitySlice';
import * as storageApiLib from '../lib/storageApi';
import * as historyCategoryApi from '../lib/historyCategoryApi';
import * as facilityApiLib from '../lib/facilityApi';

function* list({ payload }) {
  try {
    const facilityList = yield call(facilityApiLib.list, payload);
    yield put(facilityAction.listSuccess({ facilityList }));
  } catch (error) {
    yield put(facilityAction.listFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    const placeId = payload.placeId;
    const fileList = payload.fileList;
    const facilityId = payload.facilityId;
    const formValue = payload.formValue;
    const handleUploadProgress = payload.handleUploadProgress;
    const resultMediaFileList = yield call(storageApiLib.upload, { fileList, placeId, handleUploadProgress });
    const categoryId = payload.formValue.categoryId;

    //resultMediaFileList.map(item => (item['collectionId'] = collectionId));
    // let relationIdList = [];
    // resultMediaFileList.forEach(file => relationIdList.push(file.fileId));
    yield call(historyCategoryApi.linkCategory, { relationIdList: [facilityId], categoryId: categoryId });
    yield call(facilityApiLib.add, {
      ...formValue,
      facilityId,
      placeId,
      fileList: resultMediaFileList,
    });
    yield put(facilityAction.addSuccess());
  } catch (error) {
    yield put(facilityAction.addFailure({ error }));
  }
}
function* detail({ payload }) {
  try {
    const facilityInfo = yield call(facilityApiLib.detail, payload);
    yield put(facilityAction.detailSuccess({ facilityInfo }));
  } catch (error) {
    yield put(facilityAction.detailFailure({ error }));
  }
}

function* edit({ payload }) {
  try {
    const placeId = payload.placeId;
    const facilityId = payload.facilityId;
    const fileListInfo = payload?.fileListInfo || { addFileList: [], removeFileList: [], fileList: [], placeId: placeId };
    const handleUploadProgress = payload.handleUploadProgress;
    const resultFileList = yield call(storageApiLib.uploadAndRemove, { ...fileListInfo, handleUploadProgress });
    const linkId = payload.linkId;
    const unLinkId = payload.unLinkId;

    if (linkId !== unLinkId) {
      yield call(historyCategoryApi.linkCategory, { relationIdList: [facilityId], categoryId: linkId });
      yield call(historyCategoryApi.unLinkCategory, { relationIdList: [facilityId], categoryId: unLinkId });
    }
    const callList = payload.updateList.map(update => {
      const updateInfo = { ...update.updateInfo, fileList: resultFileList };
      const callApi = call(facilityApiLib.edit, { facilityId: update.facilityId, placeId, updateInfo: updateInfo });
      return callApi;
    });

    yield all([...callList]);

    yield put(facilityAction.editSuccess());
  } catch (error) {
    yield put(facilityAction.editFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    const removeList = payload.removeList;
    let unLinkCategoryInfo = {};
    let removeCallList = [];
    let facilityIdList = [];

    for (const facility of removeList) {
      const facilityId = facility.facilityId;
      const categoryId = facility.categoryId;
      facilityIdList.push(facilityId);
      if (categoryId) {
        if (unLinkCategoryInfo[categoryId]) {
          unLinkCategoryInfo[categoryId].push(facilityId);
        } else {
          unLinkCategoryInfo[categoryId] = [facilityId];
        }
      }
    }

    for (const categoryId in unLinkCategoryInfo) {
      removeCallList.push(
        call(historyCategoryApi.unLinkCategory, { relationIdList: unLinkCategoryInfo[categoryId], categoryId: categoryId }),
      );
    }
    yield all([...removeCallList]);
    yield call(storageApiLib.remove, { filePathList: payload.removeFilePathList });
    yield call(facilityApiLib.remove, payload);
    yield put(facilityAction.removeSuccess());
  } catch (error) {
    yield put(facilityAction.removeFailure({ error }));
  }
}

export function* watchList() {
  yield takeLatest(facilityAction.list, list);
}

export function* watchAdd() {
  yield takeLatest(facilityAction.add, add);
}

export function* watchDetail() {
  yield takeLatest(facilityAction.detail, detail);
}

export function* watchEdit() {
  yield takeLatest(facilityAction.edit, edit);
}

export function* watchRemove() {
  yield takeLatest(facilityAction.remove, remove);
}

function* rootSaga() {
  yield all([fork(watchList), fork(watchAdd), fork(watchDetail), fork(watchEdit), fork(watchRemove)]);
}

export default rootSaga;
