import React from 'react';
import ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './app/App';
import createStore from './modules/store';
// import reportWebVitals from './reportWebVitals';

const store = createStore();
const rootElement = document.getElementById('root');
const root = ReactDOMClient.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// 사용하지 않음 : SSB 20200607
// reportWebVitals(console.log);