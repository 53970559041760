import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import HashLoader from 'react-spinners/HashLoader';

const loading = () => (
  <HashLoader
    css={{
      display: 'block',
      width: '100%',
      height: '100%',
      margin: '0 auto',
    }}
    color={'#41a1ea'}
    loading={true}
    size={20}
  />
);

const Auth = () => {
  return (
    <Suspense fallback={loading()}>
      <Outlet />
    </Suspense>
  );
};

export default Auth;
